import styles from './Table.less';

import React from "react";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";
import { useLocation } from 'react-router-dom';

import { Component, ComponentTypeEnum, EfficiencyStatusEnum, EfficiencyTrackerReductionPlan, IEfficiencyTrackerProject, resourceTypeUnitDictionary } from "../../../../models/EfficiencyTracker";
import EfficiencyStatus from "../Tools/EfficiencyStatus";
import { formatValue, getComponent } from "../Tools/ExportFunction";
import ExpandTableItem from "../Tools/ExpandTableItem";

import { useGotoPage } from '../../../../hooks/useGotoPage';


const newColumns: IColumn[] = [
    {
        key: 'ProjectId', name: 'Project ID', minWidth: 30, maxWidth: 65, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'Id', name: 'Plan ID', minWidth: 30, maxWidth: 65, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'Owner', name: 'Owner', minWidth: 20, maxWidth: 150, isResizable: true, className: styles.nomarlColumn,
    },
    {
        key: 'ResourceType', name: 'Resource', minWidth: 20, maxWidth: 60, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'ResourceTypeUOM', name: 'Resource UOM', minWidth: 20, maxWidth: 100, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'CreateTime', name: 'Submission date', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'Status', name: 'Submission Status', minWidth: 60, maxWidth: 120, isResizable: false, className: styles.nomarlColumn
    },
    //{
    //    key: 'QualityForMap', name: 'Qualitiy for MAP', minWidth: 30, maxWidth: 120, isResizable: true, className: styles.nomarlColumn
    //},
    {
        key: 'Components', name: 'Components', minWidth: 500, isResizable: true, className: styles.nomarlColumn
    },
];

interface IPlanDetailsTable {
    data: IEfficiencyTrackerProject;
    currentPlanId: number;
}

const PlanDetailsTable: React.FC<IPlanDetailsTable> = (props) => {
    const gotoPage = useGotoPage();
    const location = useLocation();

    function _onItemInvoked(item: EfficiencyTrackerReductionPlan): void {
        const basePath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
        gotoPage(`${basePath}/${item.Id}`);
    }

    function _renderItemColumn(item: EfficiencyTrackerReductionPlan, index?: number, column?: IColumn): React.ReactNode {
        const fieldContent = item[column?.key as keyof EfficiencyTrackerReductionPlan];

        const boldStyle = item.Id === props.currentPlanId ? { fontWeight: 'bold', cursor: 'pointer' } : { cursor: 'pointer' };

        switch (column?.key) {
            case "Status":
                return <EfficiencyStatus Status={item.Status as EfficiencyStatusEnum} />;
            case "ConfidenceLevel":
                return <EfficiencyStatus Status={item.ConfidenceLevel as EfficiencyStatusEnum} />;
            case "ResourceTypeUOM":
                return <span style={boldStyle}>{formatValue(resourceTypeUnitDictionary[item.ResourceType])}</span>
            case "Components":
                const components: Component[] = fieldContent as unknown as Component[];
                const Process = getComponent(components, ComponentTypeEnum.Process);
                const RestApp = getComponent(components, ComponentTypeEnum.RestApp);
                const Shard = getComponent(components, ComponentTypeEnum.Shard);
                const Forest = getComponent(components, ComponentTypeEnum.Forest);
                const Dag = getComponent(components, ComponentTypeEnum.Dag);
                const DagConfiguration = getComponent(components, ComponentTypeEnum.DagConfiguration);
                const Sku = getComponent(components, ComponentTypeEnum.Sku);
                const ServiceId = getComponent(components, ComponentTypeEnum.ServiceId);
                const SubscriptionId = getComponent(components, ComponentTypeEnum.SubscriptionId);
                return <ExpandTableItem
                    Process={Process}
                    RestApp={RestApp}
                    Shard={Shard}
                    Forest={Forest}
                    Dag={Dag}
                    DagConfiguration={DagConfiguration}
                    Sku={Sku}
                    SerivceId={ServiceId}
                    SubscriptionId={SubscriptionId}
                />
            default:
                return <span style={boldStyle} onClick={() => _onItemInvoked(item)}>{formatValue(fieldContent)}</span>;
        }
    }

    return (
        <div>
            <DetailsList
                items={props.data.EfficiencyTrackerReductionPlans}
                setKey="set"
                compact={true}
                columns={newColumns}
                onRenderItemColumn={_renderItemColumn}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                onItemInvoked={_onItemInvoked}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionZoneProps={{
                    disableAutoSelectOnInputElements: true,
                    isSelectedOnFocus: false
                }}
            />
        </div>
    );
}



export default PlanDetailsTable;