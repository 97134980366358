/** Renders the form for creating a new efficiency project, including:
 * - Category
 * - Project name
 * - DRI
 * - Confidence
 * - ExecutionStatus
 * - Notes
 * - Link to Ticket
 */
import React from 'react';
import styles from "./Forms.less";

import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Updater } from 'use-immer';
import { Label } from '@fluentui/react/lib/Label';
import { IconButton, TooltipHost } from '@fluentui/react';

import { AddProjectTypeEnum, EfficiencyStatusEnum, IErrorDict } from '../../../../../../models/EfficiencyTracker';
import { EFTrackerBigBetsProjectForm } from '../../../../../../models/EfficiencyTrackerBigBets';

interface IProps {
    formData: EFTrackerBigBetsProjectForm;
    updateFormData: Updater<EFTrackerBigBetsProjectForm>;
    errorDict: IErrorDict;
    updateErrorDict: Updater<IErrorDict>;
    mode: AddProjectTypeEnum;
}

const titleDictionary: Partial<Record<AddProjectTypeEnum, string>> = {
    [AddProjectTypeEnum.AddProject]: "New Big Bets Project",
    [AddProjectTypeEnum.EditProject]: "Edit Big Bets Project",
};


const BigBetsProjectInfo = ({ formData, updateFormData, errorDict, updateErrorDict, mode }: IProps) => {
    return (
        <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 554 } }} >

            <div className={styles.formTitle}>{titleDictionary[mode]}</div>

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Category</Label>
                            <TooltipHost
                                content={"Category for your Big Bets project (e.g., Compute, Storage, CosmosDB)"}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="Category for your Big Bets project"
                value={formData.Category}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("CategoryError" in errorDict) {
                            delete errorDict.CategoryError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Category = newValue ? newValue : "";
                    });
                }}
                errorMessage={("CategoryError" in errorDict) ? errorDict.CategoryError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Workload</Label>
                            <TooltipHost
                                content={"Workload for your Big Bets project"}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="Workload for your Big Bets project"
                value={formData.Workload}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("WorkloadError" in errorDict) {
                            delete errorDict.WorkloadError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Workload = newValue ? newValue : "";
                    });
                }}
                errorMessage={("WorkloadError" in errorDict) ? errorDict.WorkloadError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Name</Label>
                            <TooltipHost
                                content={"Name for your Big Bets project"}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="New project"
                value={formData.Name}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("ProjectNameError" in errorDict) {
                            delete errorDict.ProjectNameError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Name = newValue ? newValue : "";
                    });
                }}
                errorMessage={("ProjectNameError" in errorDict) ? errorDict.ProjectNameError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>DRI Name</Label>
                            <TooltipHost
                                content={'DRI Name for your Big Bets project, sprit with";"'}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="Alice;Bob"
                value={formData.DriName}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("DriNameError" in errorDict) {
                            delete errorDict.DriNameError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.DriName = newValue ? newValue : "";
                    });
                }}
                errorMessage={("DriNameError" in errorDict) ? errorDict.DriNameError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>DRI Contact</Label>
                            <TooltipHost
                                content={`DRI alias with email for your Big Bets project, DRI can edit the project.\nSprit with ";", format(Alias@microsoft.com): Alice@microsoft.com;Bob@microsoft.com`}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="Alice@microsoft.com;Bob@microsoft.com(Alias@microsoft.com)"
                value={formData.DriContact}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("DriEmailError" in errorDict) {
                            delete errorDict.DriEmailError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.DriContact = newValue ? newValue : "";
                    });
                }}
                errorMessage={("DriEmailError" in errorDict) ? errorDict.DriEmailError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>Description</Label>
                            <TooltipHost
                                content={"Add the description for the project."}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="Enter a description for your new Big Bets project"
                multiline rows={4}
                value={formData.Notes}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Notes = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("NotesError" in errorDict) {
                            delete errorDict.NotesError;
                        }
                    });
                }}
                errorMessage={("NotesError" in errorDict) ? errorDict.NotesError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>CVP Sponsor</Label>
                            <TooltipHost
                                content={'Add the CVP Sponsor for the project, sprit with";"'}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="Enter the CVP Sponsor: Alice;Bob"
                value={formData.CVPSponsor}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.CVPSponsor = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("CVPSponsorError" in errorDict) {
                            delete errorDict.CVPSponsorError;
                        }
                    });
                }}
                errorMessage={("CVPSponsorError" in errorDict) ? errorDict.CVPSponsorError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>GEM/GPM</Label>
                            <TooltipHost
                                content={'Add the GEM/GPM for the project, sprit with";"'}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="Enter the GEM/GPM: Alice;Bob"
                value={formData.GEMGPM}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.GEMGPM = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("GEMGPMError" in errorDict) {
                            delete errorDict.GEMGPMError;
                        }
                    });
                }}
                errorMessage={("GEMGPMError" in errorDict) ? errorDict.GEMGPMError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>Link to Ticket</Label>
                            <TooltipHost
                                content={'Add Link to Ticket for the project. Temporary hiding.'}>
                                <IconButton
                                    iconProps={{ iconName: 'Info' }}
                                    className={styles.ExplainIcon}
                                />
                            </TooltipHost>
                        </Stack>
                    );
                }}
                placeholder="https://ticketing.example.com/ticket/12345"
                value={formData.LinkToTicket}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.LinkToTicket = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("TicketError" in errorDict) {
                            delete errorDict.TicketError;
                        }
                    });
                }}
                errorMessage={("TicketError" in errorDict) ? errorDict.TicketError : ""}
            />
        </Stack>
    );
}

export default BigBetsProjectInfo;