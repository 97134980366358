import * as React from "react";

import { HoverCard, HoverCardType, Label } from "@fluentui/react";

import { Icon } from "@fluentui/react";
import styles from "./less/EFForLabel.less";

export interface EFFormLabelProps {
    flowing?: boolean;
    label: string | JSX.Element;
    link?: string;
    required?: boolean;
    readOnly?: boolean;
    fieldDescription?: string | JSX.Element;
    forceShowFieldDescription?: boolean;
    showLinkButton?: boolean;
    linkButtonName?: string;
    linkButtonLink?: string;
    className?: string;
    labelClassName?: string;
    popUpClassName?: string;
    hoverable?: boolean;
}

export class EFFormLabel extends React.Component<EFFormLabelProps> {
    render() {
        const {
            flowing,
            label,
            link,
            required,
            fieldDescription,
            readOnly,
            showLinkButton,
            linkButtonLink,
            linkButtonName,
            className,
            forceShowFieldDescription,
            labelClassName,
            popUpClassName,
            hoverable,
        } = this.props;

        return (
            <div style={{ display: "flex", alignItems: "center", width: "350px", }}>
                <Label className={ styles.Label }>{label}</Label>
                {required && <span className={styles.required}>*</span>}
                {fieldDescription && (!readOnly || forceShowFieldDescription) && (
                    <HoverCard
                        type={HoverCardType.plain}
                        plainCardProps={{
                            onRenderPlainCard: () => (
                                <div className={styles.Description}>{fieldDescription}</div>
                            ),
                        }}
                        cardOpenDelay={hoverable ? 1000 : 0}
                    >
                        <div className={styles.iconStyle}>
                            <Icon iconName="Help" style={{ color: "#519DDB", fontWeight: "bold", fontSize: "10px" }} />
                        </div>
                    </HoverCard>
                )}
            </div>
        );
    }
}
