import { Flight } from "../models/Flights";
import { postJson } from "../utils/apiServiceBase";

const PROJECT_NAME = "JawsTeam";

const DefaultFlight: Flight = {
    enableBudgets: false,
    enableSubstrateV1: true,
    enableSubstrateV2: false,
    enableSubstrateV2Carbon: false,
    enableES365CostTransparency: false,
    enableES365CostCloudBuild: false,
    enableES365CostArtifacts: false,
    enableES365CostAzure: false,
    enableMycroftData: false,
    enableV3: false,
    enableWeeklyView: false,
    enableUserFeedback: false,
    enableStoreIOInsights: false,
    enableDateRange: false,
    enableRemapFilter: false,
    enableLandingPage: false,
    enableShareView: false,
    enableCreateView: false,
    enableJawsCopilot: false,
    enableIOAnomalyFeedback: false,
    enableMemoryRedundancy: false,
    enableBillingCost: false,
    enablePlatformCogsCalculator: false,
    enableSubstratePlatform: true
};

export async function getFlights({queryKey}: {queryKey: [string, string, string]}): Promise<Flight> {
    const [, userId, appId] = queryKey;
    const url = `api/settings/flights?projectName=${PROJECT_NAME}`;

    try {
        const records = await postJson<Record<string, Flight>>(url, {
            Environment: ENVIRONMENT,
            appId: appId,
            userId: userId
        });

        return { ...DefaultFlight, ...records[PROJECT_NAME] };
    } catch (e) {
        return DefaultFlight;
    }
}