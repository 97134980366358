import { CategoryDivision, ServiceTreeCategories } from "../models/CategoryDivision";
import { FiltersAction, FiltersView } from "../reducers/filterReducer";
import { LogComponent, LogElement, LogTarget } from "../models/LogModel";
import { isEmpty, isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionTypes } from "../actions/ActionTypes";
import { IAppState } from "../store";
import { IFilters } from "../models/FilterView";
import { IServiceTreeData } from "../reducers/serviceTreeReducer";
import { SearchKey } from "../models/SearchKey";
import moment from "moment";
import { trackEventCallback } from "../utils/AppInsights";
import { useSearchParams } from "react-router-dom";

export function useCategoryFilters() {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const serviceTree = useSelector<IAppState, IServiceTreeData>(app => app.serviceTree);

    const currentFilters: IFilters = useMemo(() => {
        let filtersContent: Partial<Record<CategoryDivision, string[]>>;

        const filtersStr = searchParams.get(SearchKey.Filters);
        if (filtersStr !== null && filtersStr !== "") {
            filtersContent = JSON.parse(decodeURIComponent(filtersStr));
        } else {
            filtersContent = {};
        }

        for (const categoryStr in filtersContent) {
            const category = categoryStr as CategoryDivision;
            if (ServiceTreeCategories.has(category) && filtersContent[category]) {
                filtersContent[category] = filtersContent[category]!.map(name => {
                    if (serviceTree.serviceNodeMapByName.has(name)) {
                        return serviceTree.serviceNodeMapByName.get(name)!.id;
                    } else {
                        return name;
                    }
                });
            }
        }
        
        const filtersView = searchParams.get(SearchKey.FiltersView) as FiltersView | undefined;

        return {filters: filtersContent, view: filtersView || FiltersView.AddableList};
    }, [searchParams, serviceTree.serviceNodeMapByName]);

    const clearAllFiltersAndResetDateRange = useCallback(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(SearchKey.Filters);
        newSearchParams.delete(SearchKey.FiltersView);
        newSearchParams.delete(SearchKey.ViewId);
        const endDateParam = moment().subtract(7, "day");
        const startDateParam = moment(endDateParam).subtract(30, "day");
        newSearchParams.set(SearchKey.StartDate, startDateParam.format("YYYY-MM-DD"));
        newSearchParams.set(SearchKey.EndDate, endDateParam.format("YYYY-MM-DD"));
        setSearchParams(newSearchParams);
        return;
    }, [searchParams, setSearchParams])

    const clearAllFiltersOnly = useCallback(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(SearchKey.Filters);
        newSearchParams.delete(SearchKey.FiltersView);
        newSearchParams.delete(SearchKey.ViewId);
        setSearchParams(newSearchParams);
        return;
    }, [searchParams, setSearchParams])

    const updateFilters = useCallback((action: FiltersAction, filters?: IFilters) => {
        if (!filters) {
            if (action === FiltersAction.Add) {
                dispatch({type: ActionTypes.UpdateSearchFiltersNextAction, nextAction: FiltersAction.Replace});
                return;
            } else {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.delete(SearchKey.Filters);
                newSearchParams.delete(SearchKey.FiltersView);
                newSearchParams.delete(SearchKey.ViewId);
                setSearchParams(newSearchParams);
                return;
            }
        }

        const {filters: filtersContent, view: filtersView} = filters;
        if ((action === FiltersAction.Replace && filtersView === currentFilters.view && !searchParams.has(SearchKey.ViewId) && isEqual(currentFilters.filters, filtersContent)) ||
            (action === FiltersAction.Add && filtersView === currentFilters.view && isEmpty(filtersContent))) return;

        trackEventCallback(LogComponent.UpdateFilters, LogElement.Filters, "Update filters", LogTarget.Other, {filtersView: currentFilters.view, originFilters: currentFilters.filters, nextFilters: filtersContent, nextAction: action});

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(SearchKey.FiltersView, filtersView);

        if (action === FiltersAction.Add) {
            newSearchParams.set(SearchKey.Filters, encodeURIComponent(JSON.stringify(mergeFilters(currentFilters.filters, filtersContent))));
        } else {
            newSearchParams.set(SearchKey.Filters, encodeURIComponent(JSON.stringify(filtersContent)));
        }

        newSearchParams.delete(SearchKey.ViewId);
        setSearchParams(newSearchParams);
        dispatch({type: ActionTypes.UpdateSearchFiltersNextAction, nextAction: FiltersAction.Replace});

        return newSearchParams;
    }, [currentFilters.filters, currentFilters.view, dispatch, searchParams, setSearchParams]);

    return {
        filters: currentFilters,
        updateFilters,
        clearAllFiltersAndResetDateRange,
        clearAllFiltersOnly,
    };
}

function mergeFilters(originalFilters: Partial<Record<CategoryDivision, string[]>>, updatedFilters: Partial<Record<CategoryDivision,string[]>>) {
    const mergedFilters: Partial<Record<CategoryDivision, string[]>> = {};
    for (const [key, value] of Object.entries(originalFilters)) {
        const category = key  as CategoryDivision;
        mergedFilters[category] = [...value, ...(updatedFilters[category] || [])];
    }
    return {...updatedFilters, ...mergedFilters};
}