import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from './less/ProjectTable.less';
import { SectionHeader } from "../../common/SectionHeader";
import { FilterList, FilterType, IFilterItem } from "../../filter/filterList/FilterList";
import StickyTable, { IStickyTableCellOption } from "../../common/table/StickyTable";
import { Cell, Column, TableInstance, TableOptions, UseSortByInstanceProps, UseSortByOptions, useSortBy, useTable } from "react-table";
import { getHeaderOption } from "../../azure/detail/common/DetailTableUtils";
import { EfficiencyTrackerProject, TrackerTableProjectKeys } from "../../../models/EfficiencyTracker";
import { useIEfficiencyTrackerProject } from "../../../hooks/useEfficiencyTrackerProject";
import { formatValue } from "./DetailProjectTable";


const getCellOptions = (cell: Cell): IStickyTableCellOption => {
    return {
        props: {
            key: cell.column.id,
            className: TrackerTableProjectKeys.find((item) => item === cell.column.id) ? '' : styles.textAlignRight,
        }
    }
};

const ProjectTable: React.FC = () => {
    const { project: project, isLoading } = useIEfficiencyTrackerProject();
    const [projectData, setProjectData] = useState<EfficiencyTrackerProject[]>(project || []);

    useEffect(() => {
        if (!project) return;
        setProjectData(project);
    }, [isLoading]);

    const renderCell = useCallback((cell: Cell) => {
        const key = cell.column.id as keyof EfficiencyTrackerProject;

        if (key === 'CreateTime') {
            return cell.value ? formatValue(cell.value) : '-';
        } else {
            return formatValue(cell.value)
        }
    }, []);

    const columns: Column<EfficiencyTrackerProject>[] = useMemo(() => {
        return TrackerTableProjectKeys.map((key) => ({
            id: key,
            accessor: key,
            sortType: 'basic',
            Header: key,
            Cell: renderCell,
            sortDescFirst: true,
        }));
    }, [renderCell]);

    const getRowId = useCallback((data: EfficiencyTrackerProject) => {
        const keyParts = TrackerTableProjectKeys.map(key => data[key]).join('_');
        return `${keyParts}`;
    }, []);

    const tableInstance = useTable({
        columns,
        data: projectData,
        getRowId,
        initialState: {
            sortBy: [{
                id: 'Id',
                desc: false,
            }],
        },
        disableSortRemove: true,
    } as TableOptions<EfficiencyTrackerProject> &
        UseSortByOptions<EfficiencyTrackerProject>, useSortBy) as (TableInstance<EfficiencyTrackerProject> &
        UseSortByInstanceProps<EfficiencyTrackerProject>);

    return (
        <div className={styles.costTableView}>
            <SectionHeader
                className={styles.sectionHeader}
                title="All projects data"
            />
            <StickyTable
                styles={{
                    container: styles.tableContainer,
                    bodyContainer: styles.tableBodyContainer,
                    footerContainer: styles.tableSectionContainer,
                    headerContainer: styles.tableSectionContainer,
                    stickyFooterContainer: styles.tableStickyFooterContainer,
                }}
                cellOption={getCellOptions}
                headerOption={getHeaderOption}
                loading={isLoading}
                loadMore={false}
                emptyText="You don't have any project."
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: -40 }, footer: { offsetBottom: 0 } }}
            />
        </div>
    );
};

export default ProjectTable;