import { Component, ComponentTypeEnum, CopsResourceTypeEnum, EfficiencyTargetCategoryEnum, EfficiencyTrackerProject, EfficiencyTrackerReductionPlan, EfficiencyTrackerReductionPlanRegionConfig, IEfficiencyTrackerProject, PlanRegionConfigTable, PlatformTypeEnum, resourceTypeUnitDictionary } from '../../../../models/EfficiencyTracker';
import { MultiValue, SingleValue } from 'react-select';
import { OptionType, getLoadOptions } from './SelectUtils';
import React, { useEffect, useState } from 'react';

import AddRegionTable from '../../Components/AddRegionTable';
import { AsyncPaginate } from 'react-select-async-paginate';
import {EFFormLabel} from '../../Components/EFFormLabel';
import PageWrapper from '../PageWrapper';
import { PrimaryButton } from '@fluentui/react';
import { addMonths } from 'date-fns';
import { postMethod } from '../../../../utils/apiServiceBase';
import styles from "../Page.less";
import {useComponents,} from "../../../../hooks/useEfficiencyTrackerProject";
import { useMsal } from "@azure/msal-react";

function mapToEfficiencyTrackerConfig(planConfigTable: PlanRegionConfigTable[], baseLineTime: Date, effectivePeriod: Date): EfficiencyTrackerReductionPlanRegionConfig[] {
    return planConfigTable.map((planConfig) => {
        const config: EfficiencyTrackerReductionPlanRegionConfig = {
            Region: planConfig.Region,
            Targets: JSON.stringify(planConfig.Targets),
            BaseLineTime: baseLineTime,
            BaseLineValue: planConfig.Current.toString(),
            EffectivePeriod: effectivePeriod,
        };
        return config;
    });
}

export interface CustomStyles {
    multiValue: (base: Record<string, any>) => Record<string, any>;
    control: (base: Record<string, any>) => Record<string, any>;
    menuList: (base: Record<string, any>) => Record<string, any>;
    valueContainer: (base: Record<string, any>) => Record<string, any>;
    container: (base: Record<string, any>) => Record<string, any>;
}

export const customAsyncPaginateStyles: CustomStyles = {
    multiValue: (base) => ({ ...base, maxWidth: 150 }),
    control: (base) => ({ ...base, minWidth: 400 }),
    menuList: (base) => ({ ...base, maxHeight: 300 }),
    valueContainer: (base) => ({ ...base, maxHeight: 60, overflow: 'auto' }),
    container: (base) => ({ ...base, flex: 1 }),
};


const ETAddProjectView: React.FC = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [createTime, setCreateTime] = useState<Date>(new Date());
    const [selectedValue, setSelectedValue] = useState<CopsResourceTypeEnum>(CopsResourceTypeEnum.IO);
    const [storageCategory, setStorageCategory] = useState<string | null>(String(EfficiencyTargetCategoryEnum.Capacity));
    const [restAppSelectedOption, setRestAppSelectedOption] = useState<Component[]>();
    const [dagSelectedOption, setDagSelectedOption] = useState<Component[]>();
    const [skuSelectedOption, setSkuSelectedOption] = useState<Component[]>();
    const [forestSelectedOption, setForestSelectedOption] = useState<Component[]>();
    const [procSelectedOption, setProcSelectedOption] = useState<Component[]>();
    const [shardSelectedOption, setShardSelectedOption] = useState<Component[]>();
    const [effectivePeriod, setEffectivePeriod] = useState<string>("6");
    const [confidenceLevel, setConfidenceLevel] = useState<string>();
    const [trackingGap, setTrackingGap] = useState<string>("No");
    const [projectName, setProjectName] = useState('');
    const [regiondata, setRegionData] = useState<PlanRegionConfigTable[]>([]);
    const [dagConfigurationSelectedOption, setDagConfigurationSelectedOption] = useState<Component[]>();

    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectName(e.target.value);
    };

    const DistinctAndMapToSelectOptions = React.useCallback(function(items: string[]) {
        const distinctItems = items;
        return distinctItems.map((item) => {
            return { value: item, label: item };
        });
    }, []);

    const restAppDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.RestApp));
    const skuDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Sku));
    const forestDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Forest));
    const procDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Process));
    const dagDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Dag));
    const shardDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Shard));
    const dagConfigurationDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.DagConfiguration));
    const periodDropDownList = Array.from([1, 3, 6, 9, 12, 15, 18, 21, 24 ], (value) => {
        const month = value;
        return {
            value: `${month}`, label: `${month} Month${month !== 1 ? 's' : ''}`
        };
    });
    const confidenceLevelDropDownList = [
        { value: "High", label: "High" },
        { value: "Medium", label: "Medium" },
        { value: "Low", label: "Low" },
    ];

    const trackingGapDropDownList = [
        { value: "No", label: "No" },
        { value: "Yes", label: "Yes" },
    ];

    const [selectedComponents, setSelectedComponents] = useState<Component[]>([]);
    useEffect(() => {
        // if each selected option of dropdown box changed add it to selectedComponents write the function:
        let selectComponets: Component[] = [];
        if (restAppSelectedOption) {
            selectComponets = selectComponets.concat(restAppSelectedOption);
        }
        if (procSelectedOption) {
            selectComponets = selectComponets.concat(procSelectedOption);
        }
        if (skuSelectedOption) {
            selectComponets = selectComponets.concat(skuSelectedOption);
        }
        if (forestSelectedOption) {
            selectComponets = selectComponets.concat(forestSelectedOption);
        }
        if (dagSelectedOption) {
            selectComponets = selectComponets.concat(dagSelectedOption);
        }

        if (shardSelectedOption) {
            selectComponets = selectComponets.concat(shardSelectedOption);
        }

        if (dagConfigurationSelectedOption) {
            selectComponets = selectComponets.concat(dagConfigurationSelectedOption);
        }
        
        setSelectedComponents(selectComponets);
    }, [restAppSelectedOption, procSelectedOption, skuSelectedOption, forestSelectedOption, dagSelectedOption, shardSelectedOption, dagConfigurationSelectedOption]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value as CopsResourceTypeEnum);
    };

    const handleStorageCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStorageCategory(event.target.value);
    };


    const handleRestAppDropdownChange = (newValue: MultiValue<OptionType>) => {
        console.log(newValue);
        const multivalue: Component[] = newValue.map(option => ({
            Type: ComponentTypeEnum.RestApp,
            Value: option.value,
        }))
        setRestAppSelectedOption(multivalue);
    };
    const handleDagDropdownChange = (newValue: MultiValue<OptionType>) => {
        console.log(newValue);
        const multivalue: Component[] = newValue.map(option => ({
            Type: ComponentTypeEnum.Dag,
            Value: option.value,
        }))
        setDagSelectedOption(multivalue);
    };
    const handleDagConfigurationDropdownChange = (newValue: MultiValue<OptionType>) => {
        console.log(newValue);
        const multivalue: Component[] = newValue.map(option => ({
            Type: ComponentTypeEnum.DagConfiguration,
            Value: option.value,
        }))
        setDagConfigurationSelectedOption(multivalue);
    };
    const handleSkuDropdownChange = (newValue: MultiValue<OptionType>) => {
        console.log(newValue);
        const multivalue: Component[] = newValue.map(option => ({
            Type: ComponentTypeEnum.Sku,
            Value: option.value,
        }))
        setSkuSelectedOption(multivalue);
    };
    const handleForestDropdownChange = (newValue: MultiValue<OptionType>) => {
        console.log(newValue);
        const multivalue: Component[] = newValue.map(option => ({
            Type: ComponentTypeEnum.Forest,
            Value: option.value,
        }))
        setForestSelectedOption(multivalue);
    };
    const handleProcDropdownChange = (newValue: MultiValue<OptionType>) => {
        console.log(newValue);
        const multivalue: Component[] = newValue.map(option => ({
            Type: ComponentTypeEnum.Process,
            Value: option.value,
        }))
        setProcSelectedOption(multivalue);
    };
    const handleShardDropdownChange = (newValue: MultiValue<OptionType>) => {
        console.log(newValue);
        const multivalue: Component[] = newValue.map(option => ({
            Type: ComponentTypeEnum.Shard,
            Value: option.value,
        }))
        setShardSelectedOption(multivalue);
    };

    const handleEffectPeriodDropdownChange = (newValue: SingleValue<OptionType>) => {
        console.log(newValue);
        if (newValue) {
            setEffectivePeriod(newValue.value);
        }
        else {
            setEffectivePeriod("6");
        }
    };

    const handleConfidenceLevelDropdownChange = (newValue: SingleValue<OptionType>) => {
        console.log(newValue);
        if (newValue) {
            setConfidenceLevel(newValue.value);
        }
        else {
            setConfidenceLevel(undefined);
        }
    };

    const handletrackingGapDropdownChange = (newValue: SingleValue<OptionType>) => {
        console.log(newValue);
        if (newValue) {
            setTrackingGap(newValue.value);
        }
        else {
            setTrackingGap("No");
        }
    };

    const handleSubmit = () => {
        // input validation
        if (!account) {
            alert('Please login!');
            return;
        }
        if (projectName === '') {
            alert('Project Name required!');
            return;
        }
        let category = storageCategory;
        let gap = trackingGap === "Yes" ? 1 : 0;
        if (selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) {
            if (selectedComponents.find((index) => index.Type === ComponentTypeEnum.Shard) === undefined) {
                alert('Please select one or more Shards!');
                return;
            }
            if (selectedComponents.find((index) => index.Type === ComponentTypeEnum.Dag) === undefined &&
                selectedComponents.find((index) => index.Type === ComponentTypeEnum.Sku) === undefined &&
                selectedComponents.find((index) => index.Type === ComponentTypeEnum.Forest) === undefined &&
                selectedComponents.find((index) => index.Type === ComponentTypeEnum.DagConfiguration) === undefined) {
                alert('Please At least select one component in DAG or SKU or Forest!');
                return;
            }
        } else if (selectedValue === CopsResourceTypeEnum.CPU || selectedValue === CopsResourceTypeEnum.Memory) {
            if (selectedComponents.find((index) => index.Type === ComponentTypeEnum.RestApp) === undefined  &&
                selectedComponents.find((index) => index.Type === ComponentTypeEnum.Process) === undefined) {
                alert('Please At least select one component in Process or REST Application Name!');
                return;
            }
            category = EfficiencyTargetCategoryEnum.Demands;
            gap = 0;
        } else {
            console.log("selectedComponents:", selectedComponents);
            if (selectedComponents.find((index) => index.Type === ComponentTypeEnum.RestApp) === undefined) {
                alert('Please fill all required tab!');
                return;
            }
            category = EfficiencyTargetCategoryEnum.Demands;
            gap = 0;
        }

        if (regiondata.length === 0) {
            alert('Please add Region details!');
            return;
        }

        if (confidenceLevel == undefined) {
            alert('Please choose your confidence level!');
            return;
        }

        // Set CreateTime/BaselineTime
        const currentDate = createTime; //CreateTime
        const endDate = addMonths(currentDate, parseInt(effectivePeriod)); //BaselineTime

        const efficiencyTrackerProject: EfficiencyTrackerProject = {
            Id: 0,
            Name: projectName,
            Owner:  account.username,
            CreateTime: currentDate,
            Description: "",
        };

        let flag = false;
        regiondata.forEach((planConfig) => {
            let total = 0;
            planConfig.Targets.forEach((target) => {
                total += target.Value;
            });
            total = Number(total.toFixed(4));
            if (total >= (planConfig.totalTarget * 1.0 / 100) + 0.0001 || total < (planConfig.totalTarget * 1.0 / 100)) {
                const message = `Sum of monthly target value should not exceed total target! Please adjust your target value for region ${planConfig.Region}, input total target is ${planConfig.totalTarget}% while your monthly attribution sum is ${(total * 100).toFixed(2)}`;
                alert(message);
                flag = true;
                return;
            }
        });

        if (flag) {
            return;
        }

        const EfficiencyTrackerReductionPlanRegionConfigs: EfficiencyTrackerReductionPlanRegionConfig[] = mapToEfficiencyTrackerConfig(regiondata, currentDate, endDate);

        const efficiencyTrackerReductionPlans: EfficiencyTrackerReductionPlan = {
            Id: 0,
            ProjectId: 0,
            Target: "Target",
            EfficiencyTrackerReductionPlanRegionConfigs: JSON.stringify(EfficiencyTrackerReductionPlanRegionConfigs),
            Components: JSON.stringify(selectedComponents),
            Status: "Approved",
            ConfidenceLevel: confidenceLevel,
            QualityForMap: "Yes",
            Platform: PlatformTypeEnum.Baremetal,
            Owner: account.username,
            CreateTime: currentDate,
            ResourceType: selectedValue,
            EfficiencyTargetCatagory: category || EfficiencyTargetCategoryEnum.Demands,
            TrackingGap: gap,
        };
        const iEfficiencyTrackerProject: IEfficiencyTrackerProject = {
            EfficiencyTrackerProject: efficiencyTrackerProject,
            EfficiencyTrackerReductionPlans: [efficiencyTrackerReductionPlans],
        }

        console.log("iEfficiencyTrackerProject:", iEfficiencyTrackerProject);

        postMethod<IEfficiencyTrackerProject>('api/efficiencytracker/add', iEfficiencyTrackerProject)
         .then((response) => {
             if (response.ok) {
                 alert('New request uploaded successfully!');
                 window.location.reload();
             } else {
                 alert('New request upload failed!');
             }
         })
         .catch((error) => {
             alert('Error when uploading New request!' + error);
         });
    }

    useEffect(() => {
        const inputElement = document.getElementById("month") as HTMLInputElement;
        if (inputElement) {
            const today = new Date();
            const formattedDate = new Date(today.getFullYear(), today.getMonth()); // Use the current year and month
            const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "numeric" };
            const localizedDate = formattedDate.toLocaleDateString("en-US", options);

            inputElement.value = localizedDate;
        }
    }, []);

    const handleChildData = (data: PlanRegionConfigTable[]) => {
        setRegionData(data);
    };
    const handleChildCreateTime = (createTime: Date) => {
        setCreateTime(createTime);
    };

    useEffect(() => {
        if (shardSelectedOption === undefined || shardSelectedOption.length === 0) {
            setTrackingGap("No");
        }
    }, [shardSelectedOption]);

    return (
        <PageWrapper
            title="Add Project"
            description="Add new project following the steps."
        >
            <div>
                <div className={styles.labelInputContainer}>
                    <EFFormLabel
                        label={"Project Name"}
                        required
                        fieldDescription={"The Name of Your Efficiency Project"}
                    />
                    <input
                        className={`${styles.fullWidthInput}`}
                        type="text"
                        value={projectName}
                        onChange={handleInputChange}
                        placeholder="Please provide the name of your project"
                    />
                </div>
                <div className={styles.divBlank}></div>
                <div className={styles.labelInputContainer}>
                    <EFFormLabel
                        label={"Resource Type"}
                        required
                        fieldDescription={"The Resource Type Your Project is Aimed To Optimize"}
                    />
                    <input
                        type="radio"
                        id="IO"
                        name="radioOptions"
                        value={CopsResourceTypeEnum.IO}
                        checked={selectedValue === CopsResourceTypeEnum.IO}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="IO" className={styles.fixedWidthInput}>IO</label>
                    <input
                        type="radio"
                        id="CPU"
                        name="radioOptions"
                        value={CopsResourceTypeEnum.CPU}
                        checked={selectedValue === CopsResourceTypeEnum.CPU}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="CPU" className={styles.fixedWidthInput}>CPU</label>
                    <input
                        type="radio"
                        id="Memory"
                        name="radioOptions"
                        value={CopsResourceTypeEnum.Memory}
                        checked={selectedValue === CopsResourceTypeEnum.Memory}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="Memory" className={styles.fixedWidthInput}>Memory</label>
                    <input
                        type="radio"
                        id="HDD"
                        name="radioOptions"
                        value={CopsResourceTypeEnum.HDD}
                        checked={selectedValue === "HDD"}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="HDD" className={styles.fixedWidthInput}>HDD</label>
                    <input
                        type="radio"
                        id="SSD"
                        name="radioOptions"
                        value={CopsResourceTypeEnum.SSD}
                        checked={selectedValue === CopsResourceTypeEnum.SSD}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="SSD" className={styles.fixedWidthInput}>SSD</label>
                </div>
                <div className={styles.divBlank}></div>
                <div className={styles.labelInputContainer}>
                    <EFFormLabel label={"Baseline Month"} required fieldDescription={"Current Month' Resource Usage Value, read only"} />
                    <input type="text" id="month" name="month" readOnly className={`${styles.NotfullWidthInput} ${styles.monthDisplay}`} />
                </div>
                <div className={styles.divBlank}></div>
                {(selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel
                                label={"Storage Efficiency Target Category"}
                                required
                                fieldDescription={"Choose Capacity to restrict growth rate, choose Demand to optimize based on the current value."}
                            />
                            <input
                                type="radio"
                                id="Capacity Bytes"
                                name="StorageCategoryOptions"
                                value={EfficiencyTargetCategoryEnum.Capacity}
                                checked={storageCategory === EfficiencyTargetCategoryEnum.Capacity}
                                onChange={handleStorageCategoryChange}
                            />
                            <label htmlFor="Capacity Bytes">Capacity Bytes</label>
                            <input
                                type="radio"
                                id="Demand Bytes"
                                name="StorageCategoryOptions"
                                value={EfficiencyTargetCategoryEnum.Demands}
                                checked={storageCategory === EfficiencyTargetCategoryEnum.Demands}
                                onChange={handleStorageCategoryChange}
                            />
                            <label htmlFor="Demand Bytes">Demand Bytes</label>
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel label={"Forest"} fieldDescription={"Deployed Forest"} />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                isMulti
                                name="Forest"
                                placeholder="Please select forest from the list"
                                loadOptions={getLoadOptions(forestDropDownList)}
                                closeMenuOnSelect={false}
                                onChange={handleForestDropdownChange}
                                styles={customAsyncPaginateStyles}
                            />
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel label={"Dag Name"} fieldDescription={"The DAG Name"} />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                isMulti
                                name="Dag Name"
                                placeholder="Please select Dag Names from the list"
                                loadOptions={getLoadOptions(dagDropDownList)}
                                closeMenuOnSelect={false}
                                onChange={handleDagDropdownChange}
                                styles={customAsyncPaginateStyles}
                            />
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel label={"Dag Configuration"} fieldDescription={"The DAG Copnfiguration"} />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                isMulti
                                name="Dag Configuration"
                                placeholder="Please select Dag Configuration from the list"
                                loadOptions={getLoadOptions(dagConfigurationDropDownList)}
                                closeMenuOnSelect={false}
                                onChange={handleDagConfigurationDropdownChange}
                                styles={customAsyncPaginateStyles}
                            />
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel label={"SKU"} fieldDescription={"The Machine's SKU"} />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                isMulti
                                name="SKU"
                                placeholder="Please select SKU from the list"
                                loadOptions={getLoadOptions(skuDropDownList)}
                                closeMenuOnSelect={false}
                                onChange={handleSkuDropdownChange}
                                styles={customAsyncPaginateStyles}
                            />
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel label={"Shards"} required fieldDescription={"Storage space allocated by class of shards"} />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                isMulti
                                name="Shards"
                                placeholder="Please select Shard Names from the list"
                                loadOptions={getLoadOptions(shardDropDownList)}
                                closeMenuOnSelect={false}
                                onChange={handleShardDropdownChange}
                                styles={customAsyncPaginateStyles}
                            />
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.SSD || selectedValue === CopsResourceTypeEnum.HDD) &&
                    (storageCategory === String(EfficiencyTargetCategoryEnum.Capacity)) &&
                    (shardSelectedOption !== undefined && shardSelectedOption.length > 0) &&
                    (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel
                                label={"Tracking Gap"}
                                required
                                fieldDescription={"The trend will track the gap between baseline and actual value."}
                            />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                name="Tracking gap"
                                placeholder="No"
                                loadOptions={getLoadOptions(trackingGapDropDownList)}
                                onChange={handletrackingGapDropdownChange}
                                styles={{
                                    multiValue: (base) => ({ ...base, maxWidth: 150 }),
                                    control: (base) => ({ ...base, minWidth: 180 }),
                                    menuList: (base) => ({ ...base, maxHeight: 300 }),
                                    container: (base) => ({ ...base, width: 250 }),
                                }}
                            />
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.CPU || selectedValue === CopsResourceTypeEnum.Memory) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel label={"Process Names"} required fieldDescription={"Your Target Process's Name"} />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                isMulti
                                name="Process Names"
                                placeholder="Please select Process Names from the list"
                                loadOptions={getLoadOptions(procDropDownList)}
                                closeMenuOnSelect={false}
                                onChange={handleProcDropdownChange}
                                styles={customAsyncPaginateStyles}
                            />
                        </div>
                        <div className={styles.divBlank}></div>
                    </div>
                )}
                {(selectedValue === CopsResourceTypeEnum.CPU || selectedValue === CopsResourceTypeEnum.Memory || selectedValue === CopsResourceTypeEnum.IO) && (
                    <div>
                        <div className={styles.labelInputContainer}>
                            <EFFormLabel label={"REST Application Names"} required fieldDescription={"AKA scenario tag, your application tracking in store when call Rest APIs"} />
                            <AsyncPaginate
                                isClearable
                                isSearchable
                                isMulti
                                name="Rest Application"
                                placeholder="Please provide your REST application name"
                                loadOptions={getLoadOptions(restAppDropDownList)}
                                closeMenuOnSelect={false}
                                onChange={handleRestAppDropdownChange}
                                styles={customAsyncPaginateStyles}
                            />
                        </div>
                    <div className={styles.divBlank}></div>
                    </div>
                )}
                <div className={styles.labelInputContainer}>
                    <EFFormLabel
                        label={"Effective Period"}
                        required
                        fieldDescription={"The time dedicated to this project, Default is 6 months."}
                    />
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        name="Effective Period"
                        placeholder="Select Period (6 months)"
                        loadOptions={getLoadOptions(periodDropDownList)}
                        onChange={handleEffectPeriodDropdownChange}
                        styles={{
                            multiValue: (base) => ({...base, maxWidth: 150}),
                            control: (base) => ({...base, minWidth: 180}),
                            menuList: (base) => ({ ...base, maxHeight: 300 }),
                            container: (base) => ({ ...base, width: 250 }),
                        }}
                    />
                </div>
                <div className={styles.labelInputContainer}>
                    <EFFormLabel
                        label={"Confidence Level"}
                        required
                        fieldDescription={"Your confidence level to reach the goal of the project"}
                    />
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        name="Confidence Level"
                        placeholder="Choose Confidence Level"
                        loadOptions={getLoadOptions(confidenceLevelDropDownList)}
                        onChange={handleConfidenceLevelDropdownChange}
                        styles={{
                            multiValue: (base) => ({ ...base, maxWidth: 150 }),
                            control: (base) => ({ ...base, minWidth: 180 }),
                            menuList: (base) => ({ ...base, maxHeight: 300 }),
                            container: (base) => ({ ...base, width: 250 }),
                        }}
                    />
                </div>
                <div className={styles.divBlank}></div>
                <AddRegionTable
                    selectedComponents={selectedComponents}
                    resourceType={selectedValue}
                    effectivePeriod={parseInt(effectivePeriod, 10)}
                    submit={true}
                    onData={handleChildData}
                    onCreateTime={handleChildCreateTime}
                />
                <div className={styles.divBlank}></div>
                <PrimaryButton onClick={handleSubmit} className={`${styles.submitButton}`}>Submit</PrimaryButton>
                <div className={styles.divWideBlank}></div>
            </div>
        </PageWrapper>
    );
};

export default ETAddProjectView;