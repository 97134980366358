import styles from './RegionDetails.less';

import React, { useEffect, useState } from 'react';
import { Separator } from '@fluentui/react';

import { Component, DownloadRegionPair, EfficiencyStatusEnum, EfficiencyTargetCategoryEnum, EfficiencyTrackerReductionPlanRegionConfig, IEfficiencyTrackerProject, TargetPair, resourceTypeUnitDictionary } from '../../../models/EfficiencyTracker';
import ConclusionBar from './Tools/ConclusionBar';
import LinearChart from './LinearChart';
import { currencyFormatter, formatNumber } from '../../../utils/currency';
import MonthlyRegionDetailsTable from './Table/MonthlyRegionDetailsTable';
import RegionDetailsTable from './Table/RegionDetailsTable';
import { useEfficiencyTrackerConsumptionDetails } from '../../../hooks/useEfficiencyTrackerProject';
import { mergeDownloadDataPair } from './Tools/ExportFunction';


interface Props {
    isBaremetalFlag: boolean;
    Region: string;
    data: IEfficiencyTrackerProject;

    onRegionStatus: (region: string, status: EfficiencyStatusEnum) => void;
    onRegionDataPair: (region: string, regionPair: DownloadRegionPair) => void;
}

const RegionDetails: React.FC<Props> = (props) => {
    const plan = props.data.EfficiencyTrackerReductionPlans[0];
    const configs = plan.EfficiencyTrackerReductionPlanRegionConfigs as unknown as EfficiencyTrackerReductionPlanRegionConfig[];
    const config = configs.find(item => (props.isBaremetalFlag ? item.Region : item.AzureRegion) == props.Region) as EfficiencyTrackerReductionPlanRegionConfig;

    const { data: consumptionData, isLoading: isActualDataLoading } = useEfficiencyTrackerConsumptionDetails(plan.Id, props.Region);
    const baselineValue = parseFloat(config.BaseLineValue) || 1;
    const baselineTime = config.BaseLineTime;
    const catagoryFlag: number = plan.EfficiencyTargetCatagory as EfficiencyTargetCategoryEnum === EfficiencyTargetCategoryEnum.Demands ? 1 : -1;
    const gapFlag: number = plan.TrackingGap;
    const targetTrend = config.Targets as unknown as TargetPair[]

    const [actualData, setActualData] = useState<[number, number][]>([]);
    const [targetData, setTargetData] = useState<[number, number][]>([]);
    const [gapData, setGapData] = useState<[number, number][]>([]);

    const [target, setTarget] = useState<number>(-1);
    const [current, setCurrent] = useState<number>(-1);
    const [gap, setGap] = useState<number>(0);
    const [trend, setTrend] = useState<number>(0); 
    const [reductionTarget, setReductionTarget] = useState<number>(0);

    // Get ConsumptionData - setActualData & setCurrent
    useEffect(() => {
        if (!consumptionData || isActualDataLoading || !consumptionData.length) return;
        if (!isActualDataLoading) {
            const actualTmp = consumptionData
                .filter(singleMetric => singleMetric.Month > baselineTime)
                .map(singleMetric => [singleMetric.Month?.valueOf(), (singleMetric.Value) as number - gapFlag * baselineValue]);

            const baseline = baselineTime;
            baseline.setDate(1);
            baseline.setHours(0, 0, 0, 0);
            const addBaselineData = [
                [baseline.valueOf(), baselineValue - gapFlag * baselineValue],
                ...actualTmp,
            ];
            setActualData(addBaselineData.map((pair) => {
                return [pair[0], pair[1]];
            }));
            setCurrent(addBaselineData[addBaselineData.length - 1][1]);
        }
    }, [baselineTime, baselineValue, gapFlag, isActualDataLoading]);

    // Get TargetData - setTargetData & setGapData & setTarget & setGap
    useEffect(() => {
        const cumulativeData = targetTrend.map((singleMetric, index) => {
            const cumulativeValue = targetTrend.slice(0, index + 1).reduce((total, metric) => total + metric.Value, 0);
            return [singleMetric.Month.valueOf(), Number(cumulativeValue.toFixed(4))];
        });
        setReductionTarget(Number((cumulativeData[cumulativeData.length - 1][1] * 100).toFixed(2)));
        const baseline = baselineTime;
        baseline.setDate(1);
        baseline.setHours(0, 0, 0, 0);
        const addBaselineData = [
            [baseline.valueOf(), 0],
            ...cumulativeData,
        ];
        const multipliedData: [number, number][] = addBaselineData.map((pair) => {
            return [pair[0], ((1 - gapFlag) - pair[1] * catagoryFlag) * baselineValue];
        });

        const targetDataTmp = multipliedData;
        const endTarget = multipliedData[multipliedData.length - 1];
        const targetTimes = targetData.map((i) => i[0]);
        const actualTimes = actualData.map((i) => i[0]);
        const gapDataTmp: [number, number][] = [];

        actualTimes.forEach(time => {
            let interpolatedValue;
            if (time > endTarget[0]) {
                targetDataTmp.push([time, endTarget[1]]);
                interpolatedValue = endTarget[1];
            }
            else if (!targetData.find(target => target[0] === time)) {
                const [prevTime, nextTime] = targetTimes.reduce(([prev, next], t) => {
                    if (t <= time && t > prev) return [t, next];
                    if (t >= time && t < next) return [prev, t];
                    return [prev, next];
                }, [-Infinity, Infinity]);
                const prevValue = targetDataTmp.find((i) => i[0] === prevTime)?.[1] || 0;
                const nextValue = targetDataTmp.find((i) => i[0] === nextTime)?.[1] || 0;

                interpolatedValue = prevValue + ((time - prevTime) / (nextTime - prevTime)) * (nextValue - prevValue);
                targetDataTmp.push([time, interpolatedValue]);
            }
            else {
                interpolatedValue = targetData.find(target => target[0] === time)?.[1] as number;
            }
            gapDataTmp.push([time, ((actualData.find(data => data[0] === time)?.[1] as number) || 0) - interpolatedValue]);
        });

        targetDataTmp.sort(([timeA], [timeB]) => timeA - timeB);
        gapDataTmp.sort(([timeA], [timeB]) => timeA - timeB);
        if (gapDataTmp.length) {
            const latestTime = gapDataTmp[gapDataTmp.length - 1][0];
            setTarget(targetDataTmp.find(item => item[0] === latestTime)?.[1] || 0);
            setGap(gapDataTmp[gapDataTmp.length - 1][1] || 0);
        } else {
            setTarget(targetDataTmp[targetDataTmp.length -1][1] || 0);
        }

        setTargetData(targetDataTmp);
        setGapData(gapDataTmp);
    }, [targetTrend, baselineValue, actualData, baselineTime, gapFlag, catagoryFlag]);

    // SetGap
    useEffect(() => {
        if (gapFlag) {
            setTrend(100 * gap / (target + baselineValue));
        } else {
            setTrend(100 * gap / target);
        }
    }, [gap, target]);

    // Set Status
    useEffect(() => {
        if (target > 0) {
            const state = gap > 0 ? EfficiencyStatusEnum.Delayed : EfficiencyStatusEnum.OnTrack;
            props.onRegionStatus(props.Region, state);
        } else {
            const state = EfficiencyStatusEnum.Loading;
            props.onRegionStatus(props.Region, state);
        }
    }, [gap, target]);

    // Set download data pair
    useEffect(() => {
        if (!targetData.length) return;
        const pairs = mergeDownloadDataPair(actualData, targetData, gapData);
        const regionPairs: DownloadRegionPair = {
            Region: props.Region,
            dataPairList: pairs,
        }
        props.onRegionDataPair(props.Region, regionPairs);
    }, [actualData, gapData, targetData]);

    return (
        <div>
            <div className={styles.title}>
                {plan.ResourceType} Consumption Trend ({resourceTypeUnitDictionary[plan.ResourceType]})
            </div>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '24px' }}>
                <ConclusionBar title="Consumption target" content={currencyFormatter(target, 2, "")} />
                <div style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="54" viewBox="0 0 16 54" fill="none">
                        <rect x="7" y="48" width="43" height="1" transform="rotate(-90 7 48)" fill="#C8C8C8" />
                    </svg>
                </div>
                <ConclusionBar title="Current status" content={currencyFormatter(current, 2, "")} trendFlag={gap > 0 ? true : false} />
                <div style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="54" viewBox="0 0 16 54" fill="none">
                        <rect x="7" y="48" width="43" height="1" transform="rotate(-90 7 48)" fill="#C8C8C8" />
                    </svg>
                </div>
                <ConclusionBar title="Gap" content={currencyFormatter(gap, 2, "")} trendFlag={gap > 0 ? true : false} trend={(gap > 0 ? "+" : "") + formatNumber(trend) + "%"} />
            </div>
            <LinearChart
                isLoading={isActualDataLoading}
                isDelay={gap > 0}
                targetData={targetData}
                actualData={actualData}
                gapData={gapData}
            />
            <MonthlyRegionDetailsTable
                Isloading={isActualDataLoading}
                ConsumptionData={consumptionData}
                TargetTrend={config.Targets as unknown as TargetPair[]}
                BaseLineTime={config.BaseLineTime}
                BaseLineValue={config.BaseLineValue}
                EfficiencyTargetCatagory={plan.EfficiencyTargetCatagory}
            />
            <Separator styles={{ root: styles.separator }} />
            <div className={styles.title}>
                Region Details
            </div>
            <RegionDetailsTable
                ImproveStatus={gap > 0}
                ConfidenceLevel={plan.ConfidenceLevel}
                EfficiencyTargetCatagory={plan.EfficiencyTargetCatagory}
                TrackingGap={plan.TrackingGap}
                Components={plan.Components as unknown as Component[]}
                ResourceType={plan.ResourceType}
                BaseLineValue={config.BaseLineValue}
                ReductionTarget={reductionTarget}
            />
        </div>
    );
};

export default RegionDetails;
