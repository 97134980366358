import { Moment } from "moment";
import { getJson, postJson } from "../utils/apiServiceBase";
import { ICosmicDailyMetrics, ICosmicDailyMetricsByWorkload } from "../models/CosmicModels";
import { CategoryDivision } from "../models/CategoryDivision";
import { parseFiltersToJson } from "../models/FilterView";
import { ServiceTreeItem } from "../models/serviceTree";

export async function getTopNCosmicDailyMetricsByWorkload({ queryKey } : { queryKey: [_:string, start: Moment, end: Moment, filterData: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, n: number, metricType: string]}) : Promise<ICosmicDailyMetricsByWorkload[]> {
    const [,start, end, filterData, serviceIdMap, n, metricType] = queryKey;

    const url = `api/v1.0/cosmic/topNCosmicWorkloadDailyMetric?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&metricType=${metricType}&n=${n}`;

    const data: ICosmicDailyMetricsByWorkload[] = await postJson(url, parseFiltersToJson(filterData, serviceIdMap, undefined, undefined));
    return data;
}

export async function getCosmicDailyCost({ queryKey } : { queryKey: [_:string, start: Moment, end: Moment, filterData: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>]}) : Promise<ICosmicDailyMetrics[]> {
    const [, start, end, filterData, serviceIdMap] = queryKey;

    const url = `api/v1.0/cosmic/totalCogs?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

    const data: ICosmicDailyMetrics[] = await postJson(url, parseFiltersToJson(filterData, serviceIdMap, undefined, undefined));
    return data;
}
