import { IDateRangeFilterData, parseFiltersToJson } from "../models/FilterView";
import { ISubstratePlatformBackendServiceIdPivot, ISubstratePlatformBackendTotalCostCard, SubstratePlatformResourceTypeEnum } from "../models/SubstratePlatform";

import { CategoryDivision } from "../models/CategoryDivision";
import { IDailyMetricReport } from "../models/Metric";
import { ServiceTreeItem } from "../models/serviceTree";
import moment from "moment";
import { postJson } from "../utils/apiServiceBase";

export async function getSubstratePlatformDailyReport(
    { queryKey }: { queryKey: [
        string,
        IDateRangeFilterData,
        string,
        string[],
        Partial<Record<CategoryDivision, string[]>>,
        Map<string, ServiceTreeItem>,
        boolean]}) {
    const [, dateRangeFilter, pivot, rings, filters, serviceIdMap, isCOGSCalculator] = queryKey;
    let url = "api/substrate/platform/dailymetrics?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD") + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD")
        + "&groupBy=" + pivot + "&isCOGSCalculator=" + isCOGSCalculator;

    if (rings.length) {
        url += "&rings=" + rings.join(",");
    }
    
    const result = await postJson<IDailyMetricReport[]>(url, parseFiltersToJson(filters, serviceIdMap));

    result.forEach(report => {
        report.data.forEach(singleDayMetric => {
            singleDayMetric.date = moment(singleDayMetric.date);
        });
        report.total = report.data.reduce((acc, cur) => acc + (cur.metricValue || 0), 0);
        report.metric = serviceIdMap.get(report.metric)?.n || report.metric;

        if (!report.metric) report.metric = "Others";
    });

    return result;
}

export async function getSubstratePlatformBackendTotalCost(
    { queryKey }: {
        queryKey: [
            string,
            IDateRangeFilterData,
            string[],
            Partial<Record<CategoryDivision, string[]>>,
            Map<string, ServiceTreeItem>]
    }) {
    const [, dateRangeFilter, rings, filters, serviceIdMap] = queryKey;
    const startDate = dateRangeFilter.startDate;
    const endDate = dateRangeFilter.endDate;
    let url = "api/substrate/platform/backend/totalcost?startDate=" + startDate.format("YYYY-MM-DD") + "&endDate=" + endDate.format("YYYY-MM-DD");

    //Pre n days
    const n = endDate.diff(startDate, 'days');
    const startDatePre = startDate.clone().subtract(n + 1, 'days');
    const endDatePre = endDate.clone().subtract(n + 1, 'days');
    let urlPre = "api/substrate/platform/backend/totalcost?startDate=" + startDatePre.format("YYYY-MM-DD") + "&endDate=" + endDatePre.format("YYYY-MM-DD");

    if (rings.length) {
        url += "&rings=" + rings.join(",");
        urlPre += "&rings=" + rings.join(",");
    }

    const totalCost = await postJson<IDailyMetricReport[]>(url, parseFiltersToJson(filters, serviceIdMap));
    const totalCostPre = await postJson<IDailyMetricReport[]>(urlPre, parseFiltersToJson(filters, serviceIdMap));

    const result: ISubstratePlatformBackendTotalCostCard[] = totalCost.map((item: IDailyMetricReport) => ({
        type: item.metric as SubstratePlatformResourceTypeEnum,
        cost: item.total || 0,
        costPre: totalCostPre.find(itemPre => itemPre.metric === item.metric)?.total || 0,
    }))

    return result;
}
export async function getSubstratePlatformBackendPivotCostTable(
    { queryKey }: {
        queryKey: [
            string,
            IDateRangeFilterData,
            string,
            string[],
            Partial<Record<CategoryDivision, string[]>>,
            Map<string, ServiceTreeItem>]
    }) {
    const [, dateRangeFilter, pivotBy, rings, filters, serviceIdMap] = queryKey;
    const startDate = dateRangeFilter.startDate;
    const endDate = dateRangeFilter.endDate;
    let url = "api/substrate/platform/backend/pivotcosttable?startDate=" + startDate.format("YYYY-MM-DD") + "&endDate=" + endDate.format("YYYY-MM-DD")
        + "&pivotBy=" + pivotBy;

    if (rings.length) {
        url += "&rings=" + rings.join(",");
    }

    const query = await postJson<ISubstratePlatformBackendServiceIdPivot[]>(url, parseFiltersToJson(filters, serviceIdMap));

    query.forEach((item) => {
        item.serviceId = serviceIdMap.get(item.serviceId)?.n || item.serviceId;
    })
    return query;
}