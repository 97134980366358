import React, {useEffect, useState } from 'react';
import { Panel, PanelType, DefaultButton, PrimaryButton, Spinner, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import styles from "./FeedbackPanel.less";
import MultiLevelDropdown from './MultiLevelDropDown';
import ImageEditor from './ImageEditor';
import { postFormDataMethod } from '../../utils/apiServiceBase';
import { useBoolean } from "@fluentui/react-hooks";

const thumbnailStyle: React.CSSProperties = {
    width: 'auto',
    height: '110px',
    cursor: 'pointer',
};

interface IFeedbackPanelProps {
    isPanelOpen: boolean;
    onDismiss: () => void;
    userName: string | undefined;
    currentURL: string;
    screenshotInputURL: string;
    FeedbackTab: string | undefined;
}

const panelWidthSetting: Record<string, string> = {
    ['Init']: '550px',
    ['OpenEdit']: '1000px',
};


const FeedbackPanel: React.FC<IFeedbackPanelProps> = (props) => {
    const { onDismiss } = props;

    const [selectedItem, setSelectedItem] = useState<string | number | undefined>(undefined);
    const [description, setDescription] = useState<string>('');
    const [panelWidth, setpanelWidth] = useState<string>(panelWidthSetting.Init);
    const [screenshotURL, setScreenshotURL] = useState<string>('');
    const [isEditorVisible, setIsEditorVisible] = useState<boolean>(false);
    const [rating, setRating] = useState<number>(0);
    const [requestState, setRequestState] = useState<string>('');
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    const [selectedItemError, setSelectedItemError] = useState<boolean>(false);
    const [descriptionError, setDescriptionError] = useState<boolean>(false);

    const handleSelectionChange = (selectedOption: string | number | undefined) => {
        setSelectedItemError(false);
        setSelectedItem(selectedOption);
    };

    const handleDescriptionChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value; 

        setDescription(newValue);
        setDescriptionError(false);
    };

    const handleOpenEditor = () => {
        setIsEditorVisible(true);
        setpanelWidth(panelWidthSetting.OpenEdit);
    };

    const handleCloseEditor = () => {
        setIsEditorVisible(false);
        setpanelWidth(panelWidthSetting.Init);
    };

    const handleImageEdited = (editedImageDataURL: string) => {
        setScreenshotURL(editedImageDataURL);
        handleCloseEditor();
    };

    useEffect(() => {
        setScreenshotURL(props.screenshotInputURL);
    }, [props.screenshotInputURL]);

    useEffect(() => {
        setShowRequestModal(false);
        setRequestState('');
    }, [selectedItem, description, rating, screenshotURL, isEditorVisible]);

    const handleRatingClick = (newRating: number) => {
        setRating(newRating);
    };

    const handleClose = () => {
        setSelectedItem(undefined);
        setSelectedItemError(false);
        setDescription('');
        setDescriptionError(false);
        setpanelWidth(panelWidthSetting.Init);
        setIsEditorVisible(false);
        setRating(0);
        setRequestState('');
        setShowRequestModal(false);
        onDismiss();
    }

    const handleSubmit = () => {
        if (showRequestModal === true) {
            alert('Please do not send the same feedback.');
            return;
        }
        setShowRequestModal(false);
        if (!selectedItem) {
            setSelectedItemError(true);
        } else {
            setSelectedItemError(false);
        }

        if (description === '') {
            setDescriptionError(true);
        } else {
            setDescriptionError(false);
        }

        if (!selectedItem || description === '') {
            return;
        }

        const screenshotCheckbox = document.getElementById("screenshotCheckbox") as HTMLInputElement;
        const urlCheckbox = document.getElementById("urlCheckbox") as HTMLInputElement;
        const screenshotCheckboxValue = screenshotCheckbox?.checked;
        const urlCheckboxValue = urlCheckbox?.checked;

        const img = new Image();
        img.src = screenshotURL;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');

            ctx?.drawImage(img, 0, 0);

            canvas.toBlob((blob) => {
                const formData = new FormData();
                if (screenshotCheckboxValue && blob) {
                    formData.append('Screenshot', blob, 'screenshot.jpg');
                }
                formData.append('FeedbackCategory', String(selectedItem));
                formData.append('Description', description);
                formData.append('FeedbackTab', String(props.FeedbackTab));
                formData.append('UserEmail', String(props.userName));
                formData.append('ExperienceRate', String(rating));
                formData.append('FeedbackRate', String("0"));
                if (urlCheckboxValue) {
                    formData.append('UrlLink', props.currentURL);
                }

                submitting();
                postFormDataMethod('api/userfeedback/setnewfeedback', formData)
                    .then((response) => {
                        if (response.ok) {
                            setRequestState('Feedback uploaded successfully!');
                        } else {
                            setRequestState('Feedback upload failed!');
                        }
                    })
                    .catch((error) => {
                        setRequestState('Error when uploading feedback!');
                        console.error('Error uploading feedback:', error);
                    })
                    .finally(() => {
                        submitDone();
                        setShowRequestModal(true);
                    });
            }, 'image/jpeg', 0.9);
        };
    };

    const handleCancel = () => {
        handleClose();
    };

    const onRenderFooterContent = (): JSX.Element => (
        <div>
            {showRequestModal && !isSubmitting && (
                <MessageBar
                    styles={{
                        root: {
                            width: 500,
                        }
                    }}
                    messageBarType={requestState === "Feedback uploaded successfully!" ? MessageBarType.success : MessageBarType.error}>
                    {requestState}
                </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ margin: '10px' }}>
                <PrimaryButton className={styles.panelButton} onClick={handleSubmit} disabled={showRequestModal || isSubmitting}>Submit</PrimaryButton>
                <DefaultButton className={styles.panelButton} onClick={handleCancel}>Cancel</DefaultButton>
                {isSubmitting && <Spinner label="Submitting feedback..." labelPosition="right" />}
            </Stack>
        </div>
    )

    return (
        <Panel
            type={PanelType.custom}
            className={styles.container}
            customWidth={panelWidth}
            headerText="Feedback to JAWS"
            headerClassName={styles.title}
            isOpen={props.isPanelOpen}
            onDismiss={handleClose}
            isBlocking={false}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            isLightDismiss
            layerProps={{eventBubblingEnabled: true}}
        >
            {!screenshotURL ? (
                <div>
                <div className={styles.divBlank}></div>
                    <span>Loading...</span>
                </div>
            ) : (
                <>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.dropdownContainer}>
                            <label className={`${styles.labelWithAfter} ${selectedItemError ? styles.error : ''}`}>
                                Classify your feedback area:
                            </label>
                            <MultiLevelDropdown onSelectionChange={handleSelectionChange} />
                            </div>
                     </div>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.inputContainer}>
                            <label className={`${styles.labelWithAfter} ${descriptionError ? styles.error : ''}`}>Description:</label>
                            <textarea
                                value={description}
                                onChange={handleDescriptionChange}
                                placeholder="Give us as much as detail as you can, so we can better assist you."
                                className={styles.inputField} />
                        </div>
                    </div>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                id="screenshotCheckbox"
                                defaultChecked={true} />
                            <label htmlFor="screenshotCheckbox">Include a screenshot of the current page</label>
                        </div>
                        <div className={styles.divBlank}></div>
                        <div className={`imageContainer ${styles.imageContainer}`}>
                            {screenshotURL ? (
                                <img
                                    src={screenshotURL}
                                    alt="Screenshot"
                                    style={thumbnailStyle}
                                    onClick={() => handleOpenEditor()} />
                            ) : (
                                <div>
                                    <span>Loading...</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {isEditorVisible && screenshotURL && (
                            <ImageEditor
                                imageSrc={screenshotURL}
                                onImageEdited={handleImageEdited}
                                onClose={handleCloseEditor} />
                        )}
                    </div>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                id="urlCheckbox"
                                defaultChecked={true} />
                            <label htmlFor="urlCheckbox">Include the link of the current dashboard</label>
                        </div>
                    </div>

                    <div className={styles.ratingContainer}>
                        <div className={styles.divBlank}></div>
                        <label className={styles.label}>Rate your JAWS experience:</label>
                        <div className={styles.ratingStars}>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <span
                                    key={index}
                                    className={`${styles.star} ${index < rating ? styles.filled : ''}`}
                                    onClick={() => handleRatingClick(index + 1)}
                                >
                                    {index < rating ? '★' : '☆'}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className={styles.ratingContainer}>
                        <div className={styles.divBlank}></div>
                        <label className={styles.label}>We will get back to you through email after submitting your feedback.</label>
                        <div></div>
                        <div>Logged in as {props.userName}</div>
                    </div>
                </>
            )}
        </Panel>
    );
};

export default FeedbackPanel;