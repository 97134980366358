/** Renders the confirmation page of the project form.
 * 
 */
import React from 'react';
import styles from "./Forms.less";

import { Stack } from '@fluentui/react/lib/Stack';
import { Link, MessageBar, MessageBarType } from '@fluentui/react';
import moment from 'moment';

import { IErrorDict } from '../../../../../../models/EfficiencyTracker';
import { EFTrackerBigBetsProjectForm, bigBetsDigit } from '../../../../../../models/EfficiencyTrackerBigBets';
import { formatValue } from '../../../../Components/Tools/ExportFunction';

interface IProps {
    formData: EFTrackerBigBetsProjectForm;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    errorDict: IErrorDict;
    confirmed: boolean;
    editMode: boolean;
}


const BigBetsConfirmation = ({ formData, setActiveStep, errorDict, confirmed, editMode }: IProps) => {

    /** Format date*/
    const dateFormatter = React.useCallback((date?: Date) => {
        return moment(date).format("MM/DD/YYYY");
    }, []);

    const renderFormItem = (label: string, value: string[] | string | null | undefined) => {
        if (value == null || value == undefined || value.length == 0) {
            return null;
        }

        return (
            <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center">
                <div className={styles.formBoldText}>{label}: </div>
                <div className={styles.formText}>{Array.isArray(value) ? value.join(", ") : value}</div>
            </Stack>
        );
    };

    return (
        <>
            {confirmed ? (
                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 554 } }} >
                    <div className={styles.formTitle}>{`${editMode? "Changes" : "Project"} submitted`}</div>
                    <div className={styles.formText}>Submitting big bets project successfully. You can now view the project from the landing page.</div>
                </Stack >
                ) : (
                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 808 } }} >

                    {"SubmitError" in errorDict &&
                        (<MessageBar
                            messageBarType={MessageBarType.error}
                        >
                            {errorDict.SubmitError}
                        </MessageBar>)
                    }

                    <div className={styles.formTitle}>{`Review and finish ${editMode? "editing" : ""} ${formData.Name}`}</div>
                    <div className={styles.formText}>
                        Review the settings for your new Big Bets project.
                        The project will immediately be sent for review after you create it.
                    </div>

                    <Stack>
                        <div className={styles.formBoldText2}>Project info</div>
                            {renderFormItem("Category", formData.Category)}
                            {renderFormItem("Workload", formData.Workload)}
                            {renderFormItem("Name", formData.Name)}
                            {renderFormItem("DRI", formData.DRI)}
                            {renderFormItem("Confidence", formData.Confidence)}
                            {renderFormItem("Execution status", formData.Status)}
                            {renderFormItem("Notes", formData.Notes)}
                            {renderFormItem("Link to Ticket", formData.LinkToTicket)}
                        <Link
                            styles={{ root: { width: 150 } }}
                            onClick={() => { setActiveStep(0); }}
                            underline>
                            Edit project info
                        </Link>
                    </Stack>

                    <Stack>
                        <div className={styles.formBoldText2}>Details info</div>
                        <Stack tokens={{ childrenGap: 12 }}>
                            {renderFormItem("Delivery ETA", formatValue(formData.ETA))}
                            {renderFormItem("Impact($)", formatValue(formData.ImpactDollar, bigBetsDigit))}
                            {formData.DateValuePairs.map(pair => (
                                <div key={dateFormatter(pair.PairDate)}>
                                    {renderFormItem("Date", dateFormatter(pair.PairDate))}
                                    <Stack styles={{ root: { marginLeft: 10 } }}>
                                        {renderFormItem("Target($)", formatValue(pair.Target, bigBetsDigit))}
                                        {renderFormItem("Actual($)", formatValue(pair.Actual, bigBetsDigit))}
                                    </Stack>
                                </div>
                            ))}

                        </Stack>
                        <Link
                            styles={{ root: { width: 150 } }}
                            onClick={() => { setActiveStep(1); }}
                            underline>
                            Edit details info
                        </Link>
                    </Stack>

                </Stack >
            )}
        </>
    );
}

export default BigBetsConfirmation;