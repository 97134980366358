/** Renders the form for adding regions and periods for a project:
 * - Baseline month
 * - Effective period
 * - Regional plans
 * - Monthly percentage target
 */
import { TargetPair, IEfficiencyProjectForm, IErrorDict, IRegionTarget, ComponentTypeEnum, Component, PlatformTypeEnum, resourceTypeUnitDictionary, AddProjectTypeEnum } from '../../../../../models/EfficiencyTracker';
import React, { useEffect, useState } from 'react';
import styles from "./Forms.less";

import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Updater, useImmer } from 'use-immer';
import { Label } from '@fluentui/react/lib/Label';
import { IconButton, SpinButton, MessageBar, MessageBarType, DetailsList, SelectionMode, IColumn } from '@fluentui/react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { useAzureRegions } from '../../../../../hooks/useEfficiencyTrackerProject';
import { resourceInfoToComponent } from '../AddProjectView';
import { useComponents } from '../../../../../hooks/useEfficiencyTrackerProject';
import { EfficiencyTrackerService } from '../../../../../services/EfficiencyTrackerService';

interface IProps {
    formData: IEfficiencyProjectForm;
    updateFormData: Updater<IEfficiencyProjectForm>;
    errorDict: IErrorDict;
    updateErrorDict: Updater<IErrorDict>;
    mode: AddProjectTypeEnum;
}

const ReductionPlan = ({ formData, updateFormData, errorDict, updateErrorDict, mode }: IProps) => {

    /**List of month(s) user can select as the efficiency project period */
    const periodDropDownList = Array.from([1, 3, 6, 9, 12, 15, 18, 21, 24], (month) => {
        return {
            key: month, text: `${month} Month${month !== 1 ? 's' : ''}`
        };
    });

    /** Load regions dynamically */
    const regionDropDownList = Array.from(["All Regions"].concat(useAzureRegions()), (regionName) => {
        return {
            key: regionName, text: regionName
        };
    });

    const baremetalRegionDropDownList = Array.from(["All Regions"].concat(useComponents(ComponentTypeEnum.Region)), (regionName) => {
        return {
            key: regionName, text: regionName
        };
    });

    /** Define the columns for the regional plan list to display IRegionTarget fields */
    const regionalPlanListColumns: IColumn[] = [
        {
            styles: { cellName: { fontSize: 12, fontStyle: "normal" } },
            key: 'RegionColumn',
            fieldName: 'Region', //Match with IRegionTarget fields
            name: 'Region',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item: IRegionTarget) => (
                <div className={styles.formBoldText}>{item.Region}</div>
            ),
        },
        {
            styles: { cellName: { fontSize: 12, fontStyle: "normal" } },
            key: 'BaseLineValueColumn',
            fieldName: 'BaseLineValue', //Match with IRegionTarget fields
            name: 'Baseline value',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item: IRegionTarget) => (
                item.BaseLineValue + resourceTypeUnitDictionary[formData.ResourceType]
            ),
        },
        {
            styles: { cellName: { fontSize: 12, fontStyle: "normal" } },
            key: 'TotalTargetColumn',
            fieldName: 'TotalTarget', //Match with IRegionTarget fields
            name: 'Total Target',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item: IRegionTarget) => (
                (item.TotalTarget * 100).toFixed(2) + "%"
            ),
        },
        {
            styles: { cellName: { fontSize: 12, fontStyle: "normal" } },
            key: 'MonthlyTargetsColumn',
            fieldName: 'Targets', //Match with IRegionTarget fields
            name: 'Monthly Targets',
            minWidth: 100,
            maxWidth: 300,
            onRender: (item: IRegionTarget) => (
                item.Targets.map((target, index) => (
                    <div key={item.Region + index}>
                        <div>{`${formatDateMonthYear(target.Month)} \t ${(target.Value * 100).toFixed(2) + "%"}`}</div>
                    </div>
                ))
            ),
        },
        {
            key: 'EditMenuColumn',
            name: '',
            minWidth: 50,
            maxWidth: 100,
            onRender: (item: IRegionTarget, index) => (
                <IconButton
                    iconProps={{ iconName: 'MoreVertical' }}
                    menuProps={{
                        items: [
                            {
                                key: 'editRegionPlan',
                                text: 'Edit region',
                                iconProps: { iconName: 'Edit' },
                                onClick() {
                                    if (index !== undefined && index > -1) {
                                        // Set data in panel to curent region plan
                                        updateCurRegions([item.Region]);
                                        updateCurTotalTarget(item.TotalTarget);
                                        // Only update monthly targets if the effective period matches the number of targets
                                        // Otherwise, let monthly target evenly distribute the total target
                                        if (item.Targets.length === formData.EffectivePeriod) {
                                            updateCurMonthlyTargets(item.Targets);
                                        }
                                        // Turn on edit mode and open panel
                                        setCurEditIndex(index);
                                        openPanel();
                                    }
                                },
                            },
                            {
                                key: 'deleteRegionPlan',
                                text: 'Delete region',
                                iconProps: { iconName: 'Delete' },
                                onClick() {
                                    if (index !== undefined && index > -1) {
                                        updateFormData(formData => {
                                            formData.RegionalTargets.splice(index, 1)
                                        })
                                    }

                                },
                            },
                        ],
                    }}
                    // Menu Icon is on by default, use this to hide it
                    onRenderMenuIcon={() => (<></>)}
                />
            ),
        },
    ]


    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [curEditIndex, setCurEditIndex] = useState<number>(-1);
    const [isloadingBaseline, { setTrue: loadingBaseline, setFalse: baselineLoadDone }] = useBoolean(false);
    const [curRegions, updateCurRegions] = useImmer<string[]>([]);
    const [curTotalTarget, updateCurTotalTarget] = useState<number>(0.5);
    const [curMonthlyTargets, updateCurMonthlyTargets] = useImmer<TargetPair[]>([]);



    /**Update monthly targets list when effective period or total target changes */
    useEffect(() => {
        if (formData.EffectivePeriod) {
            const averagePercent = Number((curTotalTarget / formData.EffectivePeriod).toFixed(5));
            const newMonthlyTargets = [];

            // Set the start date to the first day of next month at 00:00:00
            const startDate = new Date(formData.BaseLineTime);
            startDate.setMonth(startDate.getMonth() + 1);
            startDate.setDate(1);
            startDate.setHours(0, 0, 0, 0);

            for (let i = 0; i < formData.EffectivePeriod; i++) {
                const month = new Date(startDate)
                month.setMonth(startDate.getMonth() + i)
                newMonthlyTargets.push({ Month: month, Value: averagePercent })
            }
            updateCurMonthlyTargets(newMonthlyTargets);
        }
    }, [formData.EffectivePeriod, curTotalTarget])


    /**When any monthly target changes, check if they still sum to total */
    useEffect(() => {
        let percentageSum = 0;
        curMonthlyTargets.forEach(target => {
            percentageSum += target.Value;
        })
        if (Math.abs(percentageSum - curTotalTarget) > 0.0001) {
            updateErrorDict(errorDict => {
                errorDict.PercentageSumError = "Monthly percentages do NOT sum to total!"
            })
        } else {
            if ("PercentageSumError" in errorDict) {
                updateErrorDict(errorDict => {
                    delete errorDict.PercentageSumError;
                });
            }
        }
    }, [curMonthlyTargets])


    /** Remove the suffix or any other text after the numbers, or return undefined if not a number */
    const getNumericPart = (value: string): number | undefined => {
        const valueRegex = /^(\d+(\.\d+)?).*/;
        if (valueRegex.test(value)) {
            const numericValue = Number(value.replace(valueRegex, '$1'));
            return isNaN(numericValue) ? undefined : numericValue;
        }
        return undefined;
    };


    /** Format date to Month/Year */
    const formatDateMonthYear = (date: Date) => {
        return (
            `${date.toLocaleDateString("en-US", { month: "long" })}/${date.toLocaleDateString("en-US", { year: "numeric" })}`
        );
    }


    const addRegionPlan = () => {
        if (formData.EffectivePeriod === undefined) {
            updateErrorDict(errorDict => {
                errorDict.EffectivePeriodError = "Period for this project is not specified!"
            })
            return;
        }
        updateErrorDict(errorDict => {
            if ("AddRegionalPlanError" in errorDict) {
                delete errorDict.AddRegionalPlanError;
            }
        });
        openPanel();
    }

    const saveRegionPlan = async () => {
        if (curRegions.length === 0) {
            updateErrorDict(errorDict => {
                errorDict.RegionSelectionError = "No region selected!"
            });
            return;
        }

        const existingRegions = new Set(formData.RegionalTargets.map(regionalTarget => (
            regionalTarget.Region
        )))
        for (const region of curRegions) {
            if (existingRegions.has(region)) {
                updateErrorDict(errorDict => {
                    errorDict.RegionSelectionError = `Region "${region}" already exists! Please select other regions.`
                });
                return;
            }
        }

        if (curTotalTarget === 0 || Object.keys(errorDict).length !== 0) {
            return;
        }

        // Disable the save button and show spinner
        loadingBaseline();
        // Load baseline value for each region
        const oneWeekAgo = new Date(formData.BaseLineTime);
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        const resourceComponents: Component[] = resourceInfoToComponent(formData);

        if (formData.Platform === PlatformTypeEnum.Baremetal) { //Baremetal
            const alertRegions: string[] = [];
            const errorRegions: string[] = [];

            await Promise.all(curRegions.map(async (region) => {
                if (region === "All Regions") return;

                const addRegionComponents = [...resourceComponents];
                addRegionComponents.push({ Type: ComponentTypeEnum.Region, Value: region });

                try {
                    const consumption = await EfficiencyTrackerService.getConsumption(oneWeekAgo, formData.ResourceType, addRegionComponents);
                    if (consumption.toFixed(4) === "0.0000") {
                        alertRegions.push(region);
                        return;
                    }

                    updateFormData((formData) => {
                        formData.RegionalTargets.push({
                            Region: region,
                            BaseLineValue: consumption.toFixed(2),
                            TotalTarget: curTotalTarget,
                            Targets: [...curMonthlyTargets],
                        });
                    });
                } catch (error) {
                    console.log("Get Consumption error:", error);
                    errorRegions.push(region);
                    return;
                }
            }));

            //Set error message
            if (alertRegions.length > 0 || errorRegions.length > 0) {
                updateErrorDict(errorDict => {
                    let errorMessage = '';
                    if (alertRegions.length > 0) {
                        errorMessage += `Regions ${alertRegions.join(', ')} don't require optimization.`;
                    }
                    if (errorRegions.length > 0) {
                        errorMessage += `Add region ${errorRegions.join(', ')} failed, please try again.`;
                    }
                    errorDict.AddRegionalPlanError = errorMessage;
                });
            }
        } else { // Virtualized
            for (const region of curRegions) {
                if (region !== "All Regions") {
                    resourceComponents.push({ Type: ComponentTypeEnum.AzureRegion, Value: region })
                }
            }

            try {
                const response = await EfficiencyTrackerService.getAzureConsumption(oneWeekAgo, formData.ResourceType, formData.Platform!, resourceComponents);
                if (response.length === 0) {
                    updateErrorDict(errorDict => {
                        errorDict.AddRegionalPlanError =
                            "No consumption data found for the selected region(s)! Please check resource identifiers in the previous step."
                    });
                }
                // Save current regional targets to formdata
                for (const consumption of response) {
                    updateFormData(formData => {
                        formData.RegionalTargets.push({
                            Region: consumption.region,
                            BaseLineValue: consumption.totalCores.toFixed(2),
                            TotalTarget: curTotalTarget,
                            Targets: [...curMonthlyTargets],
                        })
                    })
                }
            } catch (error) {
                updateErrorDict(errorDict => {
                    errorDict.AddRegionalPlanError = `Get Consumption error: ${error}`;
                });
            }
        }

        //Sort all Region
        updateFormData(formData => {
            formData.RegionalTargets = formData.RegionalTargets.sort((a, b) => {
                if (a.Region < b.Region) return -1;
                if (a.Region > b.Region) return 1;
                return 0;
            });
        })

        // Enable the save button and hide spinner
        baselineLoadDone();
        dismissPanel();
    }

    const saveRegionPlanEdit = () => {
        updateFormData(formData => {
            formData.RegionalTargets[curEditIndex].TotalTarget = curTotalTarget;
            formData.RegionalTargets[curEditIndex].Targets = curMonthlyTargets;
        })
        dismissPanelAndEditOff();
    }

    const dismissPanelAndEditOff = () => {
        dismissPanel();
        setCurEditIndex(-1);
    }


    return (
        <>
            <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 808 } }} >

                <div className={styles.formTitle}>{`${mode === AddProjectTypeEnum.EditProject ? "Edit" : "Add"} regions and periods for ${formData.ProjectName}`}</div>


                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 600 } }}>

                    <div>
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>Baseline month</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="The starting month of this project"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                        <div className={styles.formText}>{formatDateMonthYear(formData.BaseLineTime)}</div>
                        { mode === AddProjectTypeEnum.AddPlan && (
                            <MessageBar
                                messageBarType={MessageBarType.info}
                            >
                                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                                    <li>For Hdd & SSD, the date range is 1 year.</li>
                                    <li>For Process CPU & Memory, the date range is 6 months.</li>
                                    <li>For RestApp CPU, the date range is 3 months.</li>
                                </ul>
                                <p style={{ margin: '10px 0 0 0' }}>Data beyond these date ranges may be zero and return &quot;Don&apos;t require optimization&quot;.</p>
                            </MessageBar>)
                        }
                    </div>

                    <Dropdown
                        placeholder="Select a period for this plan"
                        onRenderLabel={() => {
                            return (
                                <Stack horizontal verticalAlign="center">
                                    <Label styles={{ root: { paddingRight: 0 } }} required>Effective period</Label>
                                    <IconButton
                                        iconProps={{ iconName: 'Info' }}
                                        title="The time (in month) dedicated to this project"
                                        className={styles.ExplainIcon}
                                    />
                                </Stack>
                            );
                        }}
                        selectedKey={formData.EffectivePeriod}
                        options={periodDropDownList}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("EffectivePeriodError" in errorDict) {
                                    delete errorDict.EffectivePeriodError;
                                }
                            });
                        }}
                        onChange={(_, option) => {
                            updateFormData(formData => {
                                formData.EffectivePeriod = option ? Number(option.key) : undefined
                            })
                        }}
                        errorMessage={("EffectivePeriodError" in errorDict) ? errorDict.EffectivePeriodError : ""}
                    />


                    <div>
                        <Label required>Regional Plans</Label>
                        <CommandBar
                            styles={{ root: { padding: 0 } }}
                            items={[
                                {
                                    key: 'addRegionPlan',
                                    text: 'Add plan',
                                    iconProps: { iconName: 'Add' },
                                    onClick: addRegionPlan
                                },
                            ]}
                        />
                    </div>

                </Stack>
            </Stack>

            {"AddRegionalPlanError" in errorDict &&
                <Stack styles={{ root: { width: 300 } }}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                    >
                        {errorDict.AddRegionalPlanError}
                    </MessageBar>
                </Stack>
            }

            {formData.RegionalTargets.length !== 0 && (
                <Stack styles={{ root: { width: 900 } }}>
                    <DetailsList
                        selectionMode={SelectionMode.none}
                        items={formData.RegionalTargets}
                        columns={regionalPlanListColumns}
                    />
                </Stack>
            )}


            <Panel
                type={PanelType.medium}
                isOpen={isOpen}
                onDismiss={dismissPanelAndEditOff}
                onRenderFooterContent={() => (
                    <Stack horizontal verticalAlign="center">
                        {curEditIndex > -1 ? <PrimaryButton onClick={saveRegionPlanEdit} styles={{ root: { marginRight: 8 } }} text="Save changes" /> :
                            <PrimaryButton onClick={saveRegionPlan} styles={{ root: { marginRight: 8 } }} disabled={isloadingBaseline} text="Save region" />
                        }
                        <DefaultButton onClick={dismissPanelAndEditOff} styles={{ root: { marginRight: 10 } }} disabled={isloadingBaseline} text="Cancel" />
                        {isloadingBaseline && <Spinner label="Loading baseline values..." labelPosition="right" />}
                    </Stack>
                )}
                isFooterAtBottom={true}
            >
                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 388 } }}>

                    <div className={styles.regionPanelTitle}>{curEditIndex > -1 ? "Edit" : "Add"} region</div>
                    <div className={styles.regionPanelSubTitle}>General Information</div>

                    <Dropdown
                        label="Region"
                        placeholder="Select all regions"
                        styles={{// Limit the height of the dropdown
                            dropdownItems: {
                                selectors: {
                                    "@media(min-width: 640px)": {
                                        maxHeight: 600
                                    }
                                }
                            }
                        }}
                        selectedKeys={curRegions}
                        options={formData.Platform === PlatformTypeEnum.Baremetal ? baremetalRegionDropDownList : regionDropDownList}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("RegionSelectionError" in errorDict) {
                                    delete errorDict.RegionSelectionError;
                                }
                            });
                        }}
                        onChange={(_, option) => {
                            if (option === undefined) {
                                return;
                            }
                            if (option.key === 'All Regions' && option.selected) {
                                const tmpList = formData.Platform === PlatformTypeEnum.Baremetal ? baremetalRegionDropDownList : regionDropDownList;
                                updateCurRegions(tmpList.map((region) => region.key as string));
                            } else if (option.key === 'All Regions') {
                                updateCurRegions([]);
                            } else if (option.selected) {
                                const newKeys = [option.key as string];
                                const tmpList = formData.Platform === PlatformTypeEnum.Baremetal ? baremetalRegionDropDownList : regionDropDownList;
                                if (curRegions.length === tmpList.length - 2) {
                                    newKeys.push('All Regions');
                                }
                                updateCurRegions([...curRegions, ...newKeys]);
                            } else {
                                updateCurRegions(curRegions.filter((key) => key !== option.key && key !== 'All Regions'));
                            }
                        }}
                        errorMessage={("RegionSelectionError" in errorDict) ? errorDict.RegionSelectionError : ""}
                        disabled={curEditIndex > -1}
                        multiSelect
                        required
                    />

                    <div className={styles.separator} />
                    <div className={styles.regionPanelSubTitle}>Monthly percentage target</div>

                    <div>
                        <Label required>Total percentage reduction</Label>

                        <SpinButton
                            value={(curTotalTarget * 100).toFixed(2) + "%"}
                            min={0}
                            max={100}
                            /**
                             * Clamp the value within the valid range (or return nothing to keep the previous value
                             * if there's not valid numeric input)
                             */
                            onValidate={(value) => {
                                let numericValue = getNumericPart(value);
                                if (numericValue !== undefined) {
                                    numericValue = Math.min(numericValue, 100);
                                    numericValue = Math.max(numericValue, 0);
                                    return String(numericValue) + "%";
                                }
                            }}
                            /** Increment the value (or return nothing to keep the previous value if invalid) */
                            onIncrement={(value) => {
                                const numericValue = getNumericPart(value);
                                if (numericValue !== undefined) {
                                    return String(Math.min(numericValue + 5, 100)) + "%";
                                }
                            }}
                            /** Decrement the value (or return nothing to keep the previous value if invalid) */
                            onDecrement={(value) => {
                                const numericValue = getNumericPart(value);
                                if (numericValue !== undefined) {
                                    return String(Math.max(numericValue - 5, 0)) + "%";
                                }
                            }}
                            onChange={(_, value) => {
                                const numericValue = getNumericPart(value ? value : "");
                                if (numericValue !== undefined) {
                                    updateCurTotalTarget(Number((numericValue / 100).toFixed(4)));
                                }
                            }}
                        />
                    </div>

                    {"PercentageSumError" in errorDict &&
                        (<MessageBar
                            messageBarType={MessageBarType.error}
                        >
                            {errorDict.PercentageSumError}
                        </MessageBar>)
                    }

                    <div>
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { minWidth: 243 } }}>Month</Label>
                            <Label>Percentage target</Label>
                        </Stack>

                        <Stack tokens={{ childrenGap: 12 }}>
                            {curMonthlyTargets.map((target, index) => (
                                <Stack key={index} horizontal verticalAlign="center">

                                    <Stack styles={{ root: { minWidth: 243 } }}>
                                        <div className={styles.formText}>{formatDateMonthYear(target.Month)}</div>
                                    </Stack>

                                    <SpinButton
                                        value={(curMonthlyTargets[index].Value * 100).toFixed(2) + "%"}
                                        min={0}
                                        max={curTotalTarget * 100}
                                        /**
                                         * Clamp the value within the valid range (or return nothing to keep the previous value
                                         * if there's not valid numeric input)
                                         */
                                        onValidate={(value) => {
                                            let numericValue = getNumericPart(value);
                                            if (numericValue !== undefined) {
                                                numericValue = Math.min(numericValue, curTotalTarget * 100);
                                                numericValue = Math.max(numericValue, 0);
                                                return String(numericValue) + "%";
                                            }
                                        }}
                                        /** Increment the value (or return nothing to keep the previous value if invalid) */
                                        onIncrement={(value) => {
                                            const numericValue = getNumericPart(value);
                                            if (numericValue !== undefined) {
                                                return String(Math.min(numericValue + 1, curTotalTarget * 100)) + "%";
                                            }
                                        }}
                                        /** Decrement the value (or return nothing to keep the previous value if invalid) */
                                        onDecrement={(value) => {
                                            const numericValue = getNumericPart(value);
                                            if (numericValue !== undefined) {
                                                return String(Math.max(numericValue - 1, 0)) + "%";
                                            }
                                        }}
                                        onChange={(_, value) => {
                                            const numericValue = getNumericPart(value ? value : "");
                                            if (numericValue !== undefined) {
                                                updateCurMonthlyTargets(curMonthlyTargets => {
                                                    curMonthlyTargets[index].Value = Number((numericValue / 100).toFixed(5));
                                                })
                                            }
                                        }}
                                    />
                                </Stack>
                            ))}
                        </Stack>

                    </div>
                </Stack>
            </Panel>
        </>

    );
}

export default ReductionPlan;