import * as React from "react";

import styles from "./SubstratePlatformBackend.less";

import { Callout, DefaultButton, Stack } from "@fluentui/react";
import { SubstratePlatformChart } from "./SubstratePlatformChart";
import PlatformCostCard from "./Tool/PlatformCostCard";
import { useGetSubstratePlatformBackendTotalCost } from "../../hooks/useSubstratePlatformQuery";
import { SubstratePlatformPivotEnum, getSubstratePlatformPivotString, getSubstratePlatformResourceTypeString } from "../../models/SubstratePlatform";
import { currencyFormatter } from "../../utils/currency";
import LoadingState from "../ResponseBoundary/LoadingState";
import { reduce } from "lodash";
import { FiltersBanner } from "../common/FiltersBanner/FiltersBanner";
import { CategoryDivision } from "../../models/CategoryDivision";
import { useBoolean, useId } from "@fluentui/react-hooks";
import SubstratePlatformBackendTable from "./Table/SubstratePlatformBackendTable";

const PlatformColors = [
    "#0099BC",
    "#77004D",
    "#4F6BED",
    "#AE8C00",
    "#004E8C",
    "#E43BA6"
];

const SupportedFilters = [
    CategoryDivision.Service,
    CategoryDivision.PlatformProcess,
    CategoryDivision.PlatformApp,
    CategoryDivision.PlatformSubApp,
    CategoryDivision.PlatformClient,
    CategoryDivision.PlatformClientComponent,
];

const SubstratePlatformPivotList = [
    SubstratePlatformPivotEnum.Service,
    SubstratePlatformPivotEnum.ProcessName,
    SubstratePlatformPivotEnum.AppName,
    SubstratePlatformPivotEnum.ClientComponent,
    SubstratePlatformPivotEnum.Resource,
    SubstratePlatformPivotEnum.Ring,
];

export const SubstratePlatformBackend: React.FC = () => {
    const { data: result, isLoading } = useGetSubstratePlatformBackendTotalCost();
    const totalCost = React.useMemo(() => reduce(result, (acc, cur) => acc + (cur.cost || 0), 0), [result]);
    const totalPreCost = React.useMemo(() => reduce(result, (acc, cur) => acc + (cur.costPre || 0), 0), [result]);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible, setFalse: dismissCallout }] = useBoolean(false);
    const filterButtonId = useId('filterButton');
    const [pivot, setPivot] = React.useState<SubstratePlatformPivotEnum>(SubstratePlatformPivotEnum.Resource);
    return (
        <>
            <FiltersBanner supportedFilters={SupportedFilters} />
            {result && !isLoading ?
                <Stack horizontal wrap tokens={{ childrenGap: 10 }} style={{ paddingBottom: '24px' }}>
                    <PlatformCostCard
                        key={"totalCostCard"}
                        Type={"Total Backend"}
                        Cost={currencyFormatter(totalCost, 2)}
                        Trend={currencyFormatter((totalCost - totalPreCost) * 100 / (totalPreCost === 0 ? 1 : totalPreCost), 2, totalCost > totalPreCost ? "+" : "") + "%"}
                        TrendColorFlag={totalCost > totalPreCost}
                        CardColor={totalCost > totalPreCost}
                    />
                    {
                        result.map((item, index) => (
                            <PlatformCostCard
                                key={index}
                                Type={getSubstratePlatformResourceTypeString(item.type)}
                                LeftColor={PlatformColors[index % PlatformColors.length]}
                                Cost={currencyFormatter(item.cost, 2)}
                                Trend={currencyFormatter((item.cost - item.costPre) * 100 / (item.costPre === 0 ? 1 : item.costPre), 2, item.cost > item.costPre ? "+" : "") + "%"}
                                TrendColorFlag={item.cost > item.costPre}
                            />
                        ))
                    }
                </Stack> : <LoadingState />
            }
            <div className={styles.PivotByContainer}>
                <span>Filters: </span>
                <DefaultButton
                    className={styles.pivotButton}
                    id={filterButtonId}
                    onClick={toggleIsCalloutVisible}>
                    Pivot By:&nbsp;<strong>{getSubstratePlatformPivotString(pivot)}</strong>
                </DefaultButton>
                {
                    isCalloutVisible && (
                        <Callout
                            gapSpace={5}
                            target={`#${filterButtonId}`}
                            onDismiss={dismissCallout}
                        >
                            {
                                SubstratePlatformPivotList.map(pivotEnum => (
                                    <DefaultButton
                                        className={styles.listItem}
                                        key={pivotEnum}
                                        onClick={() => {
                                            setPivot(pivotEnum);
                                            toggleIsCalloutVisible();
                                        }}
                                    >
                                        {pivot === pivotEnum ? "✔" : "\u00a0\u00a0\u00a0"}
                                        &nbsp;{getSubstratePlatformPivotString(pivotEnum)}
                                    </DefaultButton>
                                ))
                            }
                        </Callout>
                    )
                }
            </div>
            <SubstratePlatformChart pivotBy={pivot} />
            <SubstratePlatformBackendTable pivotBy={pivot}/>
        </>
    );
}