import styles from './MoreVerticals.less';

import * as React from 'react';
import { ContextualMenu, IContextualMenuItem, DirectionalHint, Icon, Modal, MessageBar, PrimaryButton, Spinner, IconButton, MessageBarType } from '@fluentui/react';
import { RefObject, useCallback, useState } from 'react';

import { useGotoPage } from '../../../../hooks/useGotoPage';
import { Pages } from '../../../../models/Nav';
import { useBoolean } from '@fluentui/react-hooks';
import { deleteItem } from '../../../../utils/apiServiceBase';
import { useLocation } from 'react-router-dom';

interface IMoreVerticals {
    ProjectId: number;
    PlanId: number;
    AllowDelete: boolean;
}

const MoreVerticals: React.FC<IMoreVerticals> = ({ ProjectId, PlanId, AllowDelete }) => {
    const gotoPage = useGotoPage();
    const location = useLocation();
    const [menuVisible, setMenuVisible] = React.useState<boolean>(false);
    const [iconPosition, setIconPosition] = React.useState<{ top: number; left: number } | null>(null);

    //Delete button status
    const [showModal, setShowModal] = useState(false);
    const [showModalRequest, setShowModalRequest] = useState(false);
    const [requestState, setRequestState] = useState<string>('');
    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    const handleModalClose = () => {
        setShowModal(false);
        setRequestState('');
        setShowModalRequest(false);
    };

    const handleDeleteProject = () => {
        submitting();
        deleteItem('api/efficiencytracker/delete?projectId=' + ProjectId)
            .then((response) => {
                if (response.ok) {
                    setRequestState('Submit successfully!');
                } else {
                    setRequestState('Submit failed!');
                }
            })
            .catch(() => {
                setRequestState('Error when Submitting');
            }).finally(() => {
                submitDone();
                setShowModalRequest(true);
            });
    };

    //Icon Click

    const iconRef: RefObject<HTMLButtonElement> = React.createRef();

    const onIconClick = () => {
        if (iconRef.current) {
            const rect = iconRef.current.getBoundingClientRect();
            const top = rect.top;
            const left = rect.left;
            setIconPosition({ top, left });
            setMenuVisible(!menuVisible);
        }
    };

    const onAddPlanClick = useCallback(() => {
        setMenuVisible(false);
        gotoPage(`${location.pathname}/${Pages.EFAddPlanPage}/${ProjectId}`)
    }, [PlanId]);

    const onEditClick = useCallback(() => {
        setMenuVisible(false);
        gotoPage(`${location.pathname}/${Pages.EFEditPage}/${PlanId}`)
    }, [PlanId]);

    const onDeleteClick = useCallback(() => {
        setShowModal(true);
        setMenuVisible(false);
    }, []);

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'add',
            text: 'Add new Efficiency plan',
            onClick: onAddPlanClick,
            iconProps: { iconName: 'Add' },
            className: styles.MenuName,
        },
        {
            key: 'edit',
            text: 'Edit Efficiency Project',
            onClick: onEditClick,
            iconProps: { iconName: 'Edit' },
            className: styles.MenuName,
        },
        {
            key: 'delete',
            text: 'Delete Efficiency Project',
            onClick: onDeleteClick,
            iconProps: { iconName: 'Delete' },
            className: styles.MenuName,
            disabled: !AllowDelete,
        },
    ];

    return (
        <div>
            <button ref={iconRef} onClick={onIconClick} className={styles.MoreVertical}>
                <Icon iconName="MoreVertical" />
            </button>

            {menuVisible && iconPosition && (
                <ContextualMenu
                    items={menuItems}
                    target={iconRef}
                    onDismiss={() => setMenuVisible(false)}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                />
            )}
            <Modal isOpen={showModal} onDismiss={handleModalClose} containerClassName={styles.modalContainerDelete}>
                    <>
                        <div className={styles.modalHeader}>
                            <span >{"Delete this project"}</span>
                            <IconButton
                                className={styles.iconButton}
                                iconProps={{ iconName: "Cancel" }}
                                ariaLabel="Close"
                                onClick={handleModalClose}
                            />
                        </div>
                        {
                            showModalRequest && !isSubmitting && (
                                <MessageBar
                                    messageBarType={requestState === 'Submit successfully!' ? MessageBarType.success : MessageBarType.error}>
                                    {requestState}
                                </MessageBar>
                            )
                        }
                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 0 }}>
                        <PrimaryButton onClick={handleDeleteProject} style={{ margin: '8px' }} disabled={isSubmitting || requestState !== ''}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={handleModalClose} style={{ margin: '8px' }}>Cancel</PrimaryButton>
                            {isSubmitting && <Spinner label="Deleting project..." labelPosition="right" />}
                        </div>
                </>
            </Modal>
        </div>
    );
};

export default MoreVerticals;
