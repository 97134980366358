// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sfo3OyMyXKNWbpGfQk_k {\n  font-size: 1.5em;\n  font-weight: bold;\n  margin-right: 20px;\n}\n.QXkbz6F_I8etvAKkth1D {\n  background-color: #484644;\n  height: 48px;\n  color: white;\n  padding: 0 24px;\n}\n.H1YZkJpxsCSSpR7wL2Tc {\n  align-items: center;\n  color: white;\n  display: flex;\n  justify-content: center;\n  overflow: hidden;\n}\n.gTTXV3n2betsUG58quRa {\n  display: grid;\n  place-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTracker/Banner/TrackerBanner.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEA;EACI,yBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAGA;EACI,mBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,mBAAA;AAFJ","sourcesContent":[".logo {\n    font-size: 1.5em;\n    font-weight: bold;\n    margin-right: 20px;\n}\n\n.banner {\n    background-color: #484644;\n    height: 48px;\n    color: white;\n    padding: 0 24px;\n}\n\n.item {\n    align-items: center;\n    color: white;\n    display: flex;\n    justify-content: center;\n    overflow: hidden;\n}\n\n.centerDiv {\n    display: grid;\n    place-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Sfo3OyMyXKNWbpGfQk_k",
	"banner": "QXkbz6F_I8etvAKkth1D",
	"item": "H1YZkJpxsCSSpR7wL2Tc",
	"centerDiv": "gTTXV3n2betsUG58quRa"
};
export default ___CSS_LOADER_EXPORT___;
