import { EfficiencyTargetCategoryEnum, EfficiencyTrackerReductionPlanExtend, EfficiencyTrackerReductionPlanExtendDisplayDetails, EfficiencyTrackerReductionPlanExtendDisplayDetailsDictionary, TargetPair, resourceTypeUnitDictionary } from "../../../models/EfficiencyTracker";
import React, { useEffect, useState } from 'react';
import TableComponent from './TableComponent';
import { useEfficiencyTrackerConsumptionDetails } from '../../../hooks/useEfficiencyTrackerProject';
import { formatNumber } from "../../../utils/currency";
import { formatValue } from "./DetailProjectTable";

interface RegionDetailsTableProps {
    PlanId: number;
    Region: string;
    Plan: EfficiencyTrackerReductionPlanExtend;
    EfficiencyTargetCatagory: string;
}

const RegionDetailsTable: React.FC<RegionDetailsTableProps> = ({ PlanId, Region, Plan, EfficiencyTargetCatagory }) => {
    const { data: actualData, isLoading: isActualDataLoading } = useEfficiencyTrackerConsumptionDetails(PlanId, Region);
    const [seriesData, setSeriesData] = useState<{ key: string; value: string }[]>([]);
    const catagoryFlag: number = EfficiencyTargetCatagory as EfficiencyTargetCategoryEnum === EfficiencyTargetCategoryEnum.Demands ? 1 : -1;
    const reductionPercentageTargetFlag = catagoryFlag > 0 ? "↓ " : "↑ ";

    useEffect(() => {
        const targets = Plan.Targets as unknown as TargetPair[];
        let reductionPercentageTarget = 0;
        targets.forEach((target) => {
            reductionPercentageTarget += target.Value;
        });
        reductionPercentageTarget = Number(reductionPercentageTarget.toFixed(4));

        const now = new Date();
        const sumOfTargetsBeforeNow = targets.reduce((sum, target) => {
            if (target.Month < now) {
                return sum + target.Value;
            } else {
                return sum;
            }
        }, 0);
        const EndGoal = Number(((catagoryFlag > 0 ? 1 - sumOfTargetsBeforeNow : sumOfTargetsBeforeNow) * Number(Plan.BaseLineValue)).toFixed(2));

        if (!actualData || isActualDataLoading || !actualData.length) {
            const efficiencyTrackerReductionPlans: EfficiencyTrackerReductionPlanExtendDisplayDetails = {
                Region: Plan.Region,
                ResourceType: Plan.ResourceType,
                BaseLineValue: formatNumber(Number(Plan.BaseLineValue)) + resourceTypeUnitDictionary[Plan.ResourceType],
                ReductionPercentageTarget: reductionPercentageTargetFlag + (reductionPercentageTarget * 100).toFixed(2) + '%',
                ReductionTarget: formatNumber(Number((reductionPercentageTarget * Number(Plan.BaseLineValue)).toFixed(2))) + resourceTypeUnitDictionary[Plan.ResourceType],
                LatestValue: "/",
                EndGoal: formatNumber(EndGoal) + resourceTypeUnitDictionary[Plan.ResourceType],
                Gap: "/",
                Status: "/",
            };
            const regionDetails = Object.entries(efficiencyTrackerReductionPlans).map(([key, value]) => {
                const displayKey = EfficiencyTrackerReductionPlanExtendDisplayDetailsDictionary[key as keyof EfficiencyTrackerReductionPlanExtendDisplayDetails] || key;

                return {
                    key: displayKey,
                    value: formatValue(value),
                };
            });
            setSeriesData(regionDetails);
        }
        else {
            const latestValueTmp = parseFloat(actualData[actualData.length - 1].Value);
            const latestValue = catagoryFlag > 0 ? latestValueTmp : (latestValueTmp - Number(Plan.BaseLineValue));
            const efficiencyTrackerReductionPlans: EfficiencyTrackerReductionPlanExtendDisplayDetails = {
                Region: Plan.Region,
                ResourceType: Plan.ResourceType,
                BaseLineValue: formatNumber(Number(Plan.BaseLineValue)) + resourceTypeUnitDictionary[Plan.ResourceType],
                ReductionPercentageTarget: reductionPercentageTargetFlag + (reductionPercentageTarget * 100).toFixed(2) + '%',
                ReductionTarget: formatNumber(Number((reductionPercentageTarget * Number(Plan.BaseLineValue)).toFixed(2))) + resourceTypeUnitDictionary[Plan.ResourceType],
                LatestValue: formatNumber(Number(latestValue.toFixed(2))) + resourceTypeUnitDictionary[Plan.ResourceType],
                EndGoal: formatNumber(EndGoal) + resourceTypeUnitDictionary[Plan.ResourceType],
                Gap: formatNumber(Number(Math.abs(latestValue - EndGoal).toFixed(2))) + resourceTypeUnitDictionary[Plan.ResourceType],
                Status: (latestValue - EndGoal) * catagoryFlag < 0 ? "Congratulations! Target Achieved" : "Target Not Achieved Yet",
            };
            const regionDetails = Object.entries(efficiencyTrackerReductionPlans).map(([key, value]) => {
                const displayKey = EfficiencyTrackerReductionPlanExtendDisplayDetailsDictionary[key as keyof EfficiencyTrackerReductionPlanExtendDisplayDetails] || key;

                return {
                    key: displayKey,
                    value: formatValue(value),
                };
            });
            setSeriesData(regionDetails);
        }
    }, [isActualDataLoading, Region]);

    return (
        <div id={`RegionDetailsTable_${PlanId}_${Region}`}>
            <TableComponent data={seriesData}></TableComponent>
        </div>
    );
};

export default RegionDetailsTable;
