import styles from "./PlatformCostCard.less";

import React from 'react';
import { useDateRange } from "../../../hooks/useDateSelector";


interface CardProps {
    Cost: string;  //Cost Number
    Type: string;  //Cost resource type
    Trend?: string;  // Trend number (%)
    TrendColorFlag?: boolean;  //Trend color: increase-true/decrease-false/stable-undefined
    LeftColor?: string; //Left side color
    CardColor?: boolean; // Card background color  increase-true/decrease-false/notTotalCard-white
    //onClick?: () => void;
}

const PlatformCostCard: React.FC<CardProps> = ({ Cost, Trend, TrendColorFlag, LeftColor, Type, CardColor }) => {
    const { startDate, endDate } = useDateRange();
    const { setDateRange } = useDateRange();
    const daysDifference = endDate.diff(startDate, 'days');
    const cardStyle = {
        borderLeftColor: LeftColor ? LeftColor : "transparent",
        backgroundColor: CardColor !== undefined ? (CardColor ? "#FEE6DD" : "#DFF6DD") : "#FFFFFF",
    };

    const trendColor = TrendColorFlag !== undefined ? (TrendColorFlag ? styles.increase : styles.decrease) : styles.stable;
    const trendTotalColor = TrendColorFlag !== undefined ? (TrendColorFlag ? styles.totalIncrease : styles.totalDecrease) : styles.totalStable;

    const onClick = () => {
        const n = endDate.diff(startDate, 'days');
        const startDatePre = startDate.subtract(n + 1, 'days');
        const endDatePre = endDate.subtract(n + 1, 'days');
        setDateRange(startDatePre, endDatePre);
    }

    return (
        <div className={styles.card} style={cardStyle}>
            {
                CardColor !== undefined && (
                    <div className={styles.verticalLine}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="59" viewBox="0 0 4 59" fill="none">
                            <rect x="2" y="0" width="2" height="59" fill={CardColor ? "#900" : "#107C10"} />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="59" viewBox="0 0 4 59" fill="none">
                            <rect x="2" y="0" width="2" height="59" fill={CardColor ? "#900" : "#107C10"} />
                        </svg>
                    </div>
                )
            }
            <div className={styles.cardCost}>
                {Cost}
                {Trend && (
                    <div className={`${styles.cardTrend} ${CardColor === undefined ? trendColor : trendTotalColor}`}>
                        {Trend}
                    </div>
                )}
            </div>
            <div className={styles.cardType}>{Type}</div>
            <div className={styles.cardCompareTime}>
                {"compared to "}
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onClick}>previous {daysDifference + 1} day{daysDifference > 0 ? "s" : ""}</span>
            </div>
        </div>
    );
};

export default PlatformCostCard;