import { ActionButton, Breadcrumb, Callout, Checkbox, DefaultButton, DirectionalHint, IBreadcrumbItem, ILinkStyles, IStackTokens, Icon, IconButton, Link, PrimaryButton, Stack, Text, TextField, TooltipDelay, TooltipHost } from "@fluentui/react";
import { CategoryDivision, CategoryDivisionDisplayName } from "../../models/CategoryDivision";
import { ErrorMessage, ViewInputValidator } from "../../validators/ViewInputValidators";
import { FiltersAction, FiltersView } from "../../reducers/filterReducer";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import React, { useCallback, useState } from "react";
import { entries, keys, values } from "lodash";
import { getFilterList, getServiceCount, getViewItemKeyFromFilterCategory, removeFilter } from "../../utils/FiltersUtils";
import { getTrackEventClickCallback, trackEventCallback } from "../../utils/AppInsights";
import { loadAllViewsIfNeeded, shareViewAction } from "../../reducers/savedViewReducer";
import { makeSaveViewFailDialogAction, makeSaveViewSuceessDialogAction } from "../../reducers/dialogsReducer";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { ActionTypes } from "../../actions/ActionTypes";
import CustomViewTeachingBubbles from "../teaching/CustomViewTeachingBubbles";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { Flight } from "../../models/Flights";
import { IActionableItemsData } from "../../reducers/actionableItemsReducer";
import { IAppState } from "../../store";
import { IPartialFilterViewContent } from "../../models/FilterView";
import { NumberPicker } from "../common/NumberPicker";
import { RingsFilter } from "../ring/RingsFilter";
import { ServiceTreeItem } from "../../models/serviceTree";
import { SingleDatePicker } from "../common/SingleDatePicker";
import { Tabs } from "../../models/Nav";
import TimeViewPicker from "../common/TimeViewPicker";
import { createView } from "../../services/viewService";
import { getCategoryByServiceTreeLevel } from "../../reducers/serviceTreeReducer";
import { getServiceString } from "../../reducers/SearchUtils";
import { parseScenarioTag } from "../../utils/PcmV2Utils";
import styles from "./FilterBar.less";
import { useBillingCost } from "../../hooks/useBillingCost";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useDateRange } from "../../hooks/useDateSelector";
import { useFlights } from "../../hooks/useSettings";
import { useGetAppIdNameMap } from "../../hooks/useSearchMetadataQuery";
import { useGetAzureFiscalYears } from "../../hooks/useAzureQuery";
import { useRemap } from "../../hooks/useRemap";

const actionStackTokens: IStackTokens = {
    childrenGap: 8
};

const linkStyles: ILinkStyles = {
    root: {
        fontSize: 14
    }
};

const FilterBar: React.FC = () => {
    const { filters: globalFiltersData, updateFilters } = useCategoryFilters();
    const filtersNextAction = useSelector((state: IAppState) => state.filtersNextAction);
    const dateRangeFilterData = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const dispatch = useDispatch();
    const [saveViewCalloutVisible, { setFalse: dismissSaveViewCallout, toggle: toggleSaveViewCallout }] = useBoolean(false);
    const saveViewButtonId = useId("save-view-button");
    const shareViewButtonId = useId("share-view-button");
    const { searchBox, serviceTreePanel } = useSelector<IAppState, IActionableItemsData>(state => state.actionableElementsRef);
    const [viewName, setViewName] = useState('');
    const [viewNameError, setViewNameError] = useState(false);
    const { singleDate, fiscalYear, setDate, setFiscalYear } = useDateRange();
    const { data: fiscalYears } = useGetAzureFiscalYears();
    const { data: flights } = useFlights();

    const onChangeViewName = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setViewName(newValue || '');
    }, []);

    const onSaveView = useCallback(() => {
        if (!ViewInputValidator.validateViewName(viewName)) {
            setViewNameError(true);
            return;
        }

        trackEventCallback(LogComponent.SaveViewDial, LogElement.Save, "Save", LogTarget.Button);
        setViewNameError(false);
        createView(viewName, buildViewFromFilters(globalFiltersData.filters)).then(async (response) => {
            if (response.ok) {
                dispatch(loadAllViewsIfNeeded(flights as Flight));
                dispatch(makeSaveViewSuceessDialogAction(true));

                return Promise.resolve();
            } else {
                const message = response.text();
                return Promise.reject(message);
            }

        }).catch((exception) => {
            exception.then((message: string | undefined) => {
                dispatch(makeSaveViewFailDialogAction(true, message));
            })
        }).finally(() => {
            setViewName('');
            setViewNameError(false);
            dismissSaveViewCallout();
        });

    }, [viewName, globalFiltersData.filters, dispatch, dismissSaveViewCallout, flights]);

    const onShareViewCallout = useCallback(() => {
        dispatch(shareViewAction(dateRangeFilterData, buildViewFromFilters(globalFiltersData.filters)));
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.ShareView, "Share", LogTarget.Button);
    }, [dateRangeFilterData, dispatch, globalFiltersData.filters])

    const onCancelView = useCallback(() => {
        setViewName('');
        setViewNameError(false);
        dismissSaveViewCallout();
        trackEventCallback(LogComponent.SaveViewDial, LogElement.Save, "Cancel", LogTarget.Button);
    }, [dismissSaveViewCallout])

    const onClearAll = useCallback(() => {
        updateFilters(FiltersAction.Replace);
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.ClearAll, "Clear all", LogTarget.Button);
    }, [updateFilters]);
    const onToggleSaveViewCallout = useCallback(() => {
        toggleSaveViewCallout();
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.SaveView, "Save views", LogTarget.Button);
    }, [toggleSaveViewCallout]);

    const [isViewAll, { setFalse: dismissViewAllCallout, toggle: toggleViewAllCallout }] = useBoolean(false);
    const viewAllButtonId = useId("filter-bar-view-all");
    const appIdNameMap = useGetAppIdNameMap();

    const renderActionButtons = React.useCallback(() => {
        const buttons: JSX.Element[] = [
            <div key="splitter" className={styles.splitter} style={{ marginLeft: 16 }} />,
            <ActionButton key="clear" iconProps={{ iconName: "ChromeClose" }} onClick={onClearAll}>Clear all</ActionButton>,
        ];
        if (!globalFiltersData.filters.PredefinedViewName?.length) {
            buttons.push(<ActionButton key="save" id={saveViewButtonId} iconProps={{ iconName: "Save" }} onClick={onToggleSaveViewCallout}><span className={styles.actionButtonLabel} id="custom-bubble-anchor-save-view">Save view</span></ActionButton>);
        }
        buttons.push(<ActionButton key="share" id={shareViewButtonId} iconProps={{ iconName: "Share" }} onClick={onShareViewCallout}><span className={styles.actionButtonLabel} id="custom-bubble-anchor-share">Share</span></ActionButton>);

        return ({
            buttons: buttons,
            callout: saveViewCalloutVisible &&
                <Callout
                    target={`#${saveViewButtonId}`}
                    className={styles.callout}
                    onDismiss={dismissSaveViewCallout}
                >
                    <Text>Save your selections as a view</Text>
                    <TextField errorMessage={viewNameError ? ErrorMessage.ViewNameWarning : ""} underlined placeholder="Add name, and No more than 50 characters" className={styles.textField} onChange={onChangeViewName} />
                    <Stack horizontal tokens={actionStackTokens} horizontalAlign="end">
                        <PrimaryButton text="Save" onClick={onSaveView} />
                        <DefaultButton text="Cancel" onClick={onCancelView} />
                    </Stack>
                </Callout>
        });
    }, [onClearAll, globalFiltersData.filters.PredefinedViewName?.length, shareViewButtonId, onShareViewCallout, saveViewCalloutVisible, saveViewButtonId, dismissSaveViewCallout, viewNameError, onChangeViewName, onSaveView, onCancelView, onToggleSaveViewCallout]);

    const onRenderFilterBox = React.useCallback((category: CategoryDivision, value: string): JSX.Element | null => {
        if (!value) {
            return null;
        }
        const id = value;
        const text = getTextById(id, category, serviceIdMap, appIdNameMap);

        const maxStringLength = 10;

        const handleRemove = () => {
            updateFilters(FiltersAction.Replace, { filters: removeFilter(globalFiltersData.filters, category, id), view: globalFiltersData.view });
        }

        return (
            <div key={id} className={styles.filterBox}>
                <TooltipHost
                    tooltipProps={{
                        onRenderContent: () => (
                            <div>
                                <Text styles={{ root: styles.tagText }}>{text}</Text>
                            </div>
                        ),
                    }}
                    delay={TooltipDelay.zero}
                    id="tooltipId"
                    directionalHint={DirectionalHint.bottomCenter}
                >
                    <Text styles={{ root: styles.tagText }}>{text.length > maxStringLength ? text.slice(0, maxStringLength) + "..." : text}</Text>
                    <Icon
                        iconName="ChromeClose"
                        className={styles.icon}
                        onClick={handleRemove}
                    />
                </TooltipHost>
            </div>
        );
    }, [globalFiltersData.filters, globalFiltersData.view, serviceIdMap, updateFilters, appIdNameMap]);

    const onToggleViewAll = useCallback(() => {
        toggleViewAllCallout();
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.ViewAll, "View all", LogTarget.Button);
    }, [toggleViewAllCallout]);

    const renderViewAll = React.useCallback((categoryFilterPairs: [CategoryDivision, string][]) => {
        const maxVisibleLength = 3;

        const visibleContent = categoryFilterPairs.slice(0, maxVisibleLength).map(pair => onRenderFilterBox(pair[0], pair[1]));

        if (categoryFilterPairs.length <= maxVisibleLength) {
            return {
                visibleContent,
                link: null,
                callout: null
            };
        } else {
            return {
                visibleContent,
                link: <Link id={viewAllButtonId} onClick={onToggleViewAll} styles={linkStyles} underline>View all ({categoryFilterPairs.length})</Link>,
                callout: isViewAll &&
                    <Callout
                        target={`#${viewAllButtonId}`}
                        onDismiss={dismissViewAllCallout}
                        calloutMaxHeight={800}
                        styles={{ calloutMain: styles.viewAllCalloutMain }}
                    >
                        <h3>Service applied</h3>
                        <div className={styles.viewAllRow}>
                            <span className={styles.viewAllName}>Name ({categoryFilterPairs.length})</span>
                            <span className={styles.viewAllType}>Type</span>
                        </div>
                        {
                            categoryFilterPairs.map(pair => (
                                <div key={pair[1]} className={styles.viewAllRow}>
                                    <span className={styles.viewAllName}>{getTextById(pair[1], pair[0], serviceIdMap, appIdNameMap)}</span>
                                    <span className={styles.viewAllType}>{CategoryDivisionDisplayName[pair[0]]}</span>
                                    <IconButton
                                        className={styles.viewAllDelete}
                                        iconProps={{ iconName: "ChromeClose" }}
                                        onClick={() => updateFilters(FiltersAction.Replace, { filters: removeFilter(globalFiltersData.filters, pair[0], pair[1]), view: globalFiltersData.view })}
                                    />
                                </div>
                            ))
                        }
                    </Callout>
            }
        }
    }, [appIdNameMap, dismissViewAllCallout, globalFiltersData.filters, globalFiltersData.view, isViewAll, onRenderFilterBox, onToggleViewAll, serviceIdMap, updateFilters, viewAllButtonId]);

    const renderListView = React.useCallback((isAddList: boolean) => {
        const categoryFilterPairs: [CategoryDivision, string][] = [];

        keys(globalFiltersData.filters).forEach(value => {
            const category = value as CategoryDivision;

            globalFiltersData.filters[category]?.forEach(filter => {
                categoryFilterPairs.push([category, filter]);
            });
        });

        const viewAllElements = renderViewAll(categoryFilterPairs);

        const actionElements = renderActionButtons();

        return (
            <Stack horizontal verticalAlign="center" tokens={actionStackTokens}>
                {viewAllElements.visibleContent}
                {viewAllElements.link}
                {actionElements.buttons}
                {
                    isAddList &&
                    <ActionButton
                        iconProps={{ iconName: "Add" }}
                        onClick={() => {
                            const searchInputElement = document.querySelector("input.ms-SearchBox-field") as HTMLInputElement;
                            searchInputElement.click();
                            searchBox?.focus();
                            dispatch({ type: ActionTypes.UpdateSearchFiltersNextAction, nextAction: FiltersAction.Add });
                            trackEventCallback(LogComponent.PivotHeadPane, LogElement.AddFromSearch, "Add from search", LogTarget.Button);
                        }}
                    >
                        Add from search
                    </ActionButton>}
                {actionElements.callout}
                {viewAllElements.callout}
            </Stack>
        )
    }, [globalFiltersData.filters, renderViewAll, renderActionButtons, searchBox, dispatch, appIdNameMap]);

    const renderBreadcrumb = React.useCallback((filters: Partial<Record<CategoryDivision, string[]>>) => {
        const serviceTreeItemChain = getFilterList(filters, serviceIdMap);

        function onBreadcrumbItemClicked(ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem): void {
            if (!item || !item.key) {
                return;
            }

            const serviceTreeItem = serviceIdMap.get(item.key);
            if (serviceTreeItem) {
                updateFilters(filtersNextAction, { filters: { [getCategoryByServiceTreeLevel(serviceTreeItem.l)]: [serviceTreeItem.id] }, view: globalFiltersData.view });
            }
            trackEventCallback(LogComponent.PivotHeadPane, LogElement.HideTree, "Bread", LogTarget.Button, { itemName: item.text, id: item.key });
        }

        const items: IBreadcrumbItem[] = serviceTreeItemChain.map((item, index) => ({
            text: item.n,
            key: item.id,
            onClick: index === serviceTreeItemChain.length - 1 ? undefined : onBreadcrumbItemClicked
        }));
        const actionElements = renderActionButtons();

        return (
            <Stack horizontal verticalAlign="center" tokens={actionStackTokens}>
                {items.length > 0 && (<Breadcrumb key={items[items.length - 1].text} items={items} styles={{ root: styles.breadcrumb, itemLink: styles.itemLink, item: styles.itemLink }} maxDisplayedItems={10} />)}
                {actionElements.buttons}
                {actionElements.callout}
            </Stack>
        );
    }, [filtersNextAction, globalFiltersData.view, renderActionButtons, serviceIdMap, updateFilters]);

    const renderSummary = React.useCallback(() => {
        if (globalFiltersData.view !== FiltersView.Summary) {
            return;
        }

        const totalCount = getServiceCount(globalFiltersData.filters, serviceIdMap);
        const actionElements = renderActionButtons();

        return (
            <Stack horizontal verticalAlign="center" tokens={actionStackTokens}>
                <span className={styles.summaryText}>{`${getServiceString(totalCount)} Selected`}</span>
                {actionElements.buttons}
                <ActionButton iconProps={{ iconName: "Add" }} onClick={() => serviceTreePanel?.open()}>Add from service tree</ActionButton>
                {actionElements.callout}
            </Stack>
        );
    }, [globalFiltersData.filters, globalFiltersData.view, renderActionButtons, serviceIdMap, serviceTreePanel]);

    const renderFiltersContent = React.useCallback(() => {
        switch (globalFiltersData.view) {
            case FiltersView.AddableList:
                return renderListView(true);
            case FiltersView.Summary:
                return renderSummary();
            case FiltersView.List:
                return renderListView(false);
            case FiltersView.Breadcrumb:
                return renderBreadcrumb(globalFiltersData.filters);
        }
    }, [globalFiltersData, renderBreadcrumb, renderListView, renderSummary]);


    const shouldRenderFiltersContent = !!values(globalFiltersData.filters).reduce((prevValue, categoryFilters) => prevValue + categoryFilters.length, 0);
    const shouldDisplayCustomViewBubble = shouldRenderFiltersContent &&
        (globalFiltersData.view === FiltersView.AddableList || globalFiltersData.view === FiltersView.List) &&
        globalFiltersData.filters.PredefinedViewName?.length === 0;
    const [showBillingCost, setUseBillingCost] = useBillingCost();

    const { tab } = useParams();
    const { pathname } = useLocation();
    const enableBillingCost = useFlights().data?.enableBillingCost;

    const [showRemap, setShowRemap] = useRemap();
    return (<>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" grow={1} className={styles.outline} style={{justifyContent: (flights?.enableBillingCost && flights?.enableCreateView && pathname.startsWith("/LandingPage")) || (flights?.enablePlatformCogsCalculator && pathname.startsWith("/CogsCalculator")) ? 'flex-end' : 'space-between'}}>
            {(!flights?.enableCreateView || !pathname.startsWith("/LandingPage")) && (!flights?.enablePlatformCogsCalculator || !pathname.startsWith("/CogsCalculator")) && !pathname.startsWith("/SubstratePlatform") && (
                shouldRenderFiltersContent ?
                    renderFiltersContent() :
                    <Text>All Services</Text>
            )

            }
            <Stack horizontal verticalAlign="center">
                { pathname.startsWith("/Azure") && flights?.enableRemapFilter &&
                    <Checkbox className={styles.checkBox} label="Show remap data" checked={showRemap} onChange={() => setShowRemap(!showRemap)}/>}
                { (pathname == "/Azure" || pathname.startsWith("/Azure/Overview") || pathname.startsWith("/LandingPage")) &&  flights?.enableBillingCost &&
                    <Checkbox className={styles.checkBox} label={pathname.startsWith("/Azure") ? "Show billing cost" : "Show billing cost for Azure"} checked={showBillingCost} onChange={() => setUseBillingCost(!showBillingCost)}/>}
                { flights?.enableWeeklyView && pathname.startsWith("/SubstrateV2") && <TimeViewPicker /> }
                {
                    pathname.startsWith("/SubstrateV2") && <RingsFilter />
                }
                {
                    tab === Tabs.BudgetDetails ?
                        <NumberPicker current={fiscalYear} list={fiscalYears || []} onselect={(value) => dispatch(setFiscalYear(value))} /> :
                        (tab === Tabs.ServiceDetails || tab === Tabs.RegionDetails || tab === Tabs.SubscriptionDetails) && flights?.enableDateRange ? <DateRangePicker maxDateOffset={4} /> :
                            tab === Tabs.ServiceDetails || tab === Tabs.RegionDetails || tab === Tabs.SubscriptionDetails ? <SingleDatePicker selectedDate={singleDate} onSelectDate={(date) => setDate(date)} maxDateOffset={4} /> :
                                pathname.startsWith("/Azure") ? <DateRangePicker maxDateOffset={4} /> : (!pathname.startsWith("/LandingPage") || !flights?.enableCreateView) && (!flights?.enablePlatformCogsCalculator || !pathname.startsWith("/CogsCalculator")) && !pathname.startsWith("/SubstratePlatform") ? <DateRangePicker /> : <></>
                }
            </Stack>
        </Stack>
        {shouldDisplayCustomViewBubble && <CustomViewTeachingBubbles />}
    </>);
}

function buildViewFromFilters(filters: Partial<Record<CategoryDivision, string[]>>): IPartialFilterViewContent {
    const filterView: IPartialFilterViewContent = {};
    entries(filters).forEach(([category, value]) => {
        if (!value || value.length === 0) {
            return;
        }

        const key: keyof IPartialFilterViewContent = getViewItemKeyFromFilterCategory(category as CategoryDivision);
        if (key === "Subscription") {
            filterView.Subscription = value.map(filter => filter.substring(0, filter.indexOf("(")));
        } else {
            filterView[key] = [...value];
        }
    })

    return filterView;
}

function getTextById(id: string, category: CategoryDivision, serviceIdMap: Map<string, ServiceTreeItem>, appIdNameMap: Map<string, string>) {
    if (category === CategoryDivision.Service
        || category === CategoryDivision.TeamGroup
        || category === CategoryDivision.ServiceGroup
        || category === CategoryDivision.Organization
        || category === CategoryDivision.Division) {
        return serviceIdMap.get(id)?.n || id;
    } else if (category === CategoryDivision.GriffinProcessorV2 || category === CategoryDivision.ScenarioTagV2) {
        const [appId, workloadName] = parseScenarioTag(id);
        const appName = appIdNameMap.get(appId);

        return `${workloadName}${appName ? `(${appName})` : ""}`;
    } else {
        return id;
    }
}

export default FilterBar;
