// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._l5UQQAWX0xf0iILxu0U {\n  padding-top: 5px;\n  width: 280px;\n  background-Color: #E9E9E9;\n  flex: 0 0 10%;\n  height: 100%;\n  box-sizing: border-box;\n  border: 1px solid #E1E1E1;\n  overflow-y: auto;\n}\n.OvFHRnOSStta71X0R4v1 {\n  background-color: #C8C8C8;\n  margin: 0 16px;\n  height: 1px;\n}\n.sjDJSSKk7CMSy9IYlk35 {\n  height: 48px;\n  line-height: 48px;\n  margin-left: 16px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTracker/TrackerNavigation.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,yBAAA;EACA,gBAAA;AACJ;AAEA;EACI,yBAAA;EACA,cAAA;EACA,WAAA;AAAJ;AAGA;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AADJ","sourcesContent":[".navigation {\n    padding-top: 5px;\n    width: 280px;\n    background-Color: #E9E9E9;\n    flex: 0 0 10%;\n    height: 100%;\n    box-sizing: border-box;\n    border: 1px solid #E1E1E1;\n    overflow-y: auto;\n}\n\n.separator {\n    background-color: #C8C8C8;\n    margin: 0 16px;\n    height: 1px;\n}\n\n.groupHeader {\n    height: 48px;\n    line-height: 48px;\n    margin-left: 16px;\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "_l5UQQAWX0xf0iILxu0U",
	"separator": "OvFHRnOSStta71X0R4v1",
	"groupHeader": "sjDJSSKk7CMSy9IYlk35"
};
export default ___CSS_LOADER_EXPORT___;
