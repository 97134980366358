import React, { useState } from 'react';
import { Dropdown, IDropdownOption, DropdownMenuItemType } from '@fluentui/react';
import styles from "./FeedbackPanel.less";
interface Option {
    label: string;
    options: { key: string; text: string }[];
}
interface MultiLevelDropdownProps {
    onSelectionChange: (selectedOption: string | number | undefined) => void;
}

const MultiLevelDropdown: React.FC<MultiLevelDropdownProps> = ({ onSelectionChange }) => {
    const [selectedOption, setSelectedOption] = useState<string | number | undefined>(undefined);

    const options: Option[] = [
        {
            label: 'Report a problem: data issue',
            options: [
                { key: 'Data Issue:CostMapping', text: 'Cost mapping-> provide service name and corrected mapping' },
                { key: 'Data Issue:Bug ', text: 'Issue/bug ' },
                { key: 'Data Issue:Other', text: 'Other' },
            ],
        },
        {
            label: 'Question on the cost model',
            options: [
                { key: 'Question on the cost model:UnitPrice', text: 'Question about the metrics unit price' },
                { key: 'Question on the cost model:Metric', text: 'Question about the metrics definition' },
                { key: 'Question on the cost model:Other', text: 'Other', },
            ],
        },
        {
            label: 'Make a suggestion',
            options: [
                { key: 'Suggestion', text: 'Suggestion' },
            ],
        },
    ];

    const flattenedOptions: IDropdownOption[] = options.flatMap((section) => [
        { key: section.label, text: section.label, itemType: DropdownMenuItemType.Header },
        ...section.options.map((option) => ({ key: option.key, text: "\u00A0\u00A0" + option.text })),
    ]);


    const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setSelectedOption(option.key);
            onSelectionChange(option.key);
        }
    };


    return (
        <div>
            <Dropdown
                options={flattenedOptions}
                selectedKey={selectedOption}
                onChange={handleDropdownChange}
                placeholder="Select your feedback area"
                className={styles.dropdownOption}
            />
        </div>
    );
};

export default MultiLevelDropdown;
