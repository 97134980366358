import React, {useEffect, useState } from 'react';
import { Panel, PanelType, DefaultButton, PrimaryButton, MessageBar, Stack, Spinner, MessageBarType } from "@fluentui/react";
import styles from "./FeedbackPanel.less";
import MultiLevelDropdown from './MultiLevelDropDown';
import { postFormDataMethod } from '../../utils/apiServiceBase';
import TableView from './TableView';
import { currencyFormatter, formatNumber } from '../../utils/currency';
import { AzureComputeDisplayNames, AzureComputeHeatmapKeys, CostCategoryDisplayNames } from '../azure/common/AzureComputeConstants';
import { IAzureComputeDetail } from '../../models/AzureComputeModels';
import { AzureComputeDetailNumericalColumns } from '../azure/detail/common/DetailTableConstants';
import html2canvas from 'html2canvas';
import { useBoolean } from "@fluentui/react-hooks";

interface IFeedbackPanelAzureTableProps<T> {
    isPanelOpen: boolean;
    onDismiss: () => void;
    userName: string | undefined;
    currentURL: string;
    FeedbackTab: string | undefined;
    data: T | undefined;
    columns: (keyof Partial<T>)[];
    defaultHiddenColumns?: string[];
}
interface CellValue {
    id: string;
    value: string;
}

const panelWidthSetting: Record<string, string> = {
    ['Init']: '550px',
    ['OpenEdit']: '1000px',
};
function getShowFeedbackData<T>(
    data: T | undefined,
    columns: (keyof Partial<T>)[],
    defaultHiddenColumns?: string[],
): CellValue[] {
    const allCellValues: CellValue[] = [];
    if (!data) {
        return allCellValues;
    }

    for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        if (column === 'feedback' || defaultHiddenColumns?.find((item) => item === column)) {
            continue;
        }

        let formatValue;
        if (AzureComputeHeatmapKeys.indexOf(column as keyof IAzureComputeDetail) >= 0) {
            if (column === "vmCost") {
                formatValue = currencyFormatter(data[column] as number);
            }
            else {
                formatValue = formatNumber(data[column] as number || 0, 0) as string;
                if ((column as string).endsWith('Percentage')) {
                    formatValue = formatValue + '%';
                }
            }
        } else if (AzureComputeDetailNumericalColumns.has(column as string)) {
            formatValue = formatNumber(data[column] as number, 0);
        } else if (column === 'costCategory') {
            formatValue = CostCategoryDisplayNames[data[column] as string] || '-';
        } else {
            formatValue = !data[column] ? '-' : data[column];
        }

        const cellValue = {
            id: AzureComputeDisplayNames[column as string],
            value: formatValue as string,
        };
        allCellValues.push(cellValue);
    }
    console.log("allCellValues: ", allCellValues);

    return allCellValues;
}
const FeedbackPanelAzureTable: <T>(props: IFeedbackPanelAzureTableProps<T>) => React.ReactElement<IFeedbackPanelAzureTableProps<T>> = (props) => {
    const { onDismiss, isPanelOpen } = props;

    const [selectedItem, setSelectedItem] = useState<string | number | undefined>(undefined);
    const [description, setDescription] = useState<string>('');
    const [panelWidth, setpanelWidth] = useState<string>(panelWidthSetting.Init);
    const [rating, setRating] = useState<number>(0);
    const [requestState, setRequestState] = useState<string>('');
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);
    const [screenshotChecked, setScreenshotChecked] = useState(true);
    const [urlChecked, setUrlChecked] = useState(true);

    const [selectedItemError, setSelectedItemError] = useState<boolean>(false);
    const [descriptionError, setDescriptionError] = useState<boolean>(false);
    const [allCellValues, setAllCellValues] = useState<CellValue[]>([]);

    const handleScreenshotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScreenshotChecked(event.target.checked);
    };

    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUrlChecked(event.target.checked);
    };

    useEffect(() => {
        if (isPanelOpen) {
            const data = getShowFeedbackData(
                props.data,
                props.columns,
                props.defaultHiddenColumns,
            );
            setAllCellValues(data);
        }
    }, [isPanelOpen, props.columns, props.data, props.defaultHiddenColumns]);

    useEffect(() => {
        setShowRequestModal(false);
        setRequestState('');
    }, [selectedItem, description, rating]);

    const handleSelectionChange = (selectedOption: string | number | undefined) => {
        setSelectedItemError(false);
        setSelectedItem(selectedOption);
    };

    const handleDescriptionChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value; 

        setDescription(newValue);
        setDescriptionError(false);
    };

    const handleRatingClick = (newRating: number) => {
        setRating(newRating);
    };

    const handleClose = () => {
        setSelectedItem(undefined);
        setSelectedItemError(false);
        setDescription('');
        setDescriptionError(false);
        setpanelWidth(panelWidthSetting.Init);
        setRating(0);
        setRequestState('');
        setShowRequestModal(false);
        onDismiss();
    }

    const handleSubmit = () => {
        if (showRequestModal === true) {
            alert('Please do not send the same feedback.');
            return;
        }
        setShowRequestModal(false);
        if (!selectedItem) {
            setSelectedItemError(true);
        } else {
            setSelectedItemError(false);
        }

        if (description === '') {
            setDescriptionError(true);
        } else {
            setDescriptionError(false);
        }

        if (!selectedItem || description === '') {
            return;
        }

        const formData = new FormData();
        if (screenshotChecked) {
            const targetElement = document.getElementById(`FeedbackSection_TableData`);
            if (targetElement) {
                html2canvas(targetElement).then((canvas) => {
                    canvas.toBlob((blob) => {
                        if (blob) {
                            formData.append('Screenshot', blob, 'screenshot.jpg');
                        }
                        const jsonData = JSON.stringify(allCellValues);
                        formData.append('JsonData', jsonData);
                        formData.append('FeedbackCategory', String(selectedItem));
                        formData.append('Description', description);
                        formData.append('FeedbackTab', String(props.FeedbackTab));
                        formData.append('UserEmail', String(props.userName));
                        formData.append('ExperienceRate', String(rating));
                        formData.append('FeedbackRate', String("0"));
                        if (urlChecked) {
                            formData.append('UrlLink', props.currentURL);
                        }

                        submitting();
                        postFormDataMethod('api/userfeedback/setnewfeedback', formData)
                            .then((response) => {
                                if (response.ok) {
                                    setRequestState('Feedback uploaded successfully!');
                                } else {
                                    setRequestState('Feedback upload failed!');
                                }
                            })
                            .catch((error) => {
                                setRequestState('Error when uploading feedback!');
                                console.error('Error uploading feedback:', error);
                            })
                            .finally(() => {
                                submitDone();
                                setShowRequestModal(true);
                            });
                    }, 'image/jpeg', 0.9);
                });
            }
        }
        else {
            formData.append('FeedbackCategory', String(selectedItem));
            formData.append('Description', description);
            formData.append('FeedbackTab', String(props.FeedbackTab));
            formData.append('UserEmail', String(props.userName));
            formData.append('ExperienceRate', String(rating));
            formData.append('FeedbackRate', String("0"));
            if (urlChecked) {
                formData.append('UrlLink', props.currentURL);
            }

            submitting();
            postFormDataMethod('api/userfeedback/setnewfeedback', formData)
                .then((response) => {
                    if (response.ok) {
                        setRequestState('Feedback uploaded successfully!');
                    } else {
                        setRequestState('Feedback upload failed!');
                    }
                })
                .catch((error) => {
                    setRequestState('Error when uploading feedback!');
                    console.error('Error uploading feedback:', error);
                })
                .finally(() => {
                    submitDone();
                    setShowRequestModal(true);
                });
        }
    };

    const handleCancel = () => {
        handleClose();
    };

    const onRenderFooterContent = (): JSX.Element => (
        <div>
            {showRequestModal && !isSubmitting && (
                <MessageBar
                    styles={{
                        root: {
                            width: 500,
                        }
                    }}
                    messageBarType={requestState === "Feedback uploaded successfully!" ? MessageBarType.success : MessageBarType.error}>
                    {requestState}
                </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ margin: '10px' }}>
                <PrimaryButton className={styles.panelButton} onClick={handleSubmit} disabled={showRequestModal || isSubmitting}>Submit</PrimaryButton>
                <DefaultButton className={styles.panelButton} onClick={handleCancel}>Cancel</DefaultButton>
                {isSubmitting && <Spinner label="Submitting feedback..." labelPosition="right" />}
            </Stack>
        </div>
    )

    return (
        <Panel
            type={PanelType.custom}
            className={styles.container}
            customWidth={panelWidth}
            headerText="Feedback to JAWS"
            headerClassName={styles.title}
            isOpen={props.isPanelOpen}
            onDismiss={handleClose}
            isBlocking={false}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            isLightDismiss
            layerProps={{eventBubblingEnabled: true}}
        >
            {!true ? (
                <div>
                <div className={styles.divBlank}></div>
                    <span>Loading...</span>
                </div>
            ) : (
                <>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.dropdownContainer}>
                            <label className={`${styles.labelWithAfter} ${selectedItemError ? styles.error : ''}`}>
                                Classify your feedback area:
                            </label>
                            <MultiLevelDropdown onSelectionChange={handleSelectionChange} />
                            </div>
                     </div>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.inputContainer}>
                            <label className={`${styles.labelWithAfter} ${descriptionError ? styles.error : ''}`}>Description:</label>
                            <textarea
                                value={description}
                                onChange={handleDescriptionChange}
                                placeholder="Give us as much as detail as you can, so we can better assist you."
                                className={styles.inputField} />
                        </div>
                    </div>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                id="dataCheckbox"
                                checked={screenshotChecked}
                                onChange={handleScreenshotChange}/>
                            <label htmlFor="dataCheckbox">Include the line of data that I clicked on</label>
                        </div>
                            <div className={styles.divBlank}></div>
                            <div id={"FeedbackSection_TableData"} >
                                {(screenshotChecked) && (
                                        <TableView allCellValues={allCellValues} title={String(props.FeedbackTab)} />
                                )}
                            </div>
                    </div>
                    <div className={styles.panelContent}>
                        <div className={styles.divBlank}></div>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                id="urlCheckbox"
                                checked={urlChecked}
                                onChange={handleUrlChange} />
                            <label htmlFor="urlCheckbox">Include the link of the current dashboard</label>
                        </div>
                    </div>

                    <div className={styles.ratingContainer}>
                        <div className={styles.divBlank}></div>
                        <label className={styles.label}>Rate your JAWS experience:</label>
                        <div className={styles.ratingStars}>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <span
                                    key={index}
                                    className={`${styles.star} ${index < rating ? styles.filled : ''}`}
                                    onClick={() => handleRatingClick(index + 1)}
                                >
                                    {index < rating ? '★' : '☆'}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className={styles.ratingContainer}>
                        <div className={styles.divBlank}></div>
                        <label className={styles.label}>We will get back to you through email after submitting your feedback.</label>
                        <div></div>
                        <div>Logged in as {props.userName}</div>
                    </div>
                </>
            )}
        </Panel>
    );
};

export default FeedbackPanelAzureTable;