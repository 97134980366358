// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mH5O2e8xz3qc_FmpPEB_ {\n  width: 100%;\n  overflow-x: auto;\n  padding-top: 20px;\n}\n.IHcK_qc5dC7I5pLMDthA {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid #ddd;\n}\n.IHcK_qc5dC7I5pLMDthA td {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n.XzQ5lzCOh2zs9fN4vODt {\n  font-weight: bold;\n  text-align: left;\n}\n.aeH3MuYD90KMoNazuCEH {\n  text-align: left;\n}\n.IHcK_qc5dC7I5pLMDthA tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n.IHcK_qc5dC7I5pLMDthA tr:nth-child(odd) {\n  background-color: #ffffff;\n}\n.aeH3MuYD90KMoNazuCEH.mqPr4qK4AlZN_Re4bMUD {\n  white-space: pre-wrap;\n  word-wrap: break-word;\n}\n.pVd73DXLtZhOD5GbOeBR {\n  color: green;\n}\n.WJIFzD9b8_bqVqLjICEy {\n  color: red;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTracker/Components/less/TableComponent.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEA;EACI,WAAA;EACA,yBAAA;EACA,sBAAA;AAAJ;AAGA;EACI,sBAAA;EACA,YAAA;AADJ;AAIA;EACI,iBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,gBAAA;AAHJ;AAMA;EACI,yBAAA;AAJJ;AAOA;EACI,yBAAA;AALJ;AAQA;EACI,qBAAA;EACA,qBAAA;AANJ;AASA;EACI,YAAA;AAPJ;AAUA;EACI,UAAA;AARJ","sourcesContent":[".tableContainer {\n    width: 100%;\n    overflow-x: auto;\n    padding-top: 20px;\n}\n\n.parameterTable {\n    width: 100%;\n    border-collapse: collapse;\n    border: 1px solid #ddd;\n}\n\n.parameterTable td {\n    border: 1px solid #ddd;\n    padding: 8px;\n}\n\n.parameterKey {\n    font-weight: bold;\n    text-align: left;\n}\n\n.parameterValue {\n    text-align: left;\n}\n\n.parameterTable tr:nth-child(even) {\n    background-color: #f2f2f2;\n}\n\n.parameterTable tr:nth-child(odd) {\n    background-color: #ffffff;\n}\n\n.parameterValue.preformatted {\n    white-space: pre-wrap;\n    word-wrap: break-word;\n}\n\n.greenArrow {\n    color: green;\n}\n\n.redArrow {\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "mH5O2e8xz3qc_FmpPEB_",
	"parameterTable": "IHcK_qc5dC7I5pLMDthA",
	"parameterKey": "XzQ5lzCOh2zs9fN4vODt",
	"parameterValue": "aeH3MuYD90KMoNazuCEH",
	"preformatted": "mqPr4qK4AlZN_Re4bMUD",
	"greenArrow": "pVd73DXLtZhOD5GbOeBR",
	"redArrow": "WJIFzD9b8_bqVqLjICEy"
};
export default ___CSS_LOADER_EXPORT___;
