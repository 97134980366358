import { Component, ComponentTypeEnum, EfficiencyTrackerReductionPlan, EfficiencyTrackerReductionPlanExtend, EfficiencyTrackerReductionPlanRegionConfig, IEfficiencyTrackerProject, TargetPair } from '../../../models/EfficiencyTracker';
import { IconButton, Modal, PrimaryButton, TextField } from '@fluentui/react';
import { OptionType, getLoadOptions } from '../Views/Project/SelectUtils';
import React, { useEffect, useState } from 'react';

import { AsyncPaginate } from 'react-select-async-paginate';
import LinearChart from './LinearChart';
import { Pages } from '../../../models/Nav';
import PlanTable from './PlanTable';
import RegionDetailsTable from './RegionDetailsTable';
import { SingleValue } from 'react-select';
import TableComponent from './TableComponent';
import { formatNumber } from '../../../utils/currency';
import pageStyles from "../Views/Page.less";
import { postMethod } from '../../../utils/apiServiceBase';
import styles from './less/ProjectRegionDetail.less';
import { useGotoPage } from '../../../hooks/useGotoPage';

export function formatValue(value: any): string {
    if (typeof value === 'string') {
        return value;
    } else if (typeof value === 'number') {
        return formatNumber(value);
    } else if (value instanceof Date) {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return value.toLocaleDateString('en-US', options);
    } else {
        return String(value);
    }
}

function DetailProjectTable({ data, id }: { data: IEfficiencyTrackerProject, id: number }) {
    const gotoPage = useGotoPage();
    const [projectData, setProjectData] = useState<{key: string, value: string}[]>();
    const [plansExtend, setPlansExtend] = useState<EfficiencyTrackerReductionPlanExtend[]>();
    const [regionSelectedOption, setRegionSelectedOption] = useState<OptionType | null>(null);
    const [targetValue, setTargetValue] = useState<TargetPair[]>([]);
    const [currentPlan, setCurrentPlan] = useState<EfficiencyTrackerReductionPlanExtend>();
    const [regionDropDownList, setRegionDropDownList] = useState<{ value: string, label: string }[]>([]);
    useEffect(() => {
        const projectDataTmp = Object.entries(data.EfficiencyTrackerProject)
            .filter(([key]) => key !== 'Id')
            .map(([key, value]) => ({
            key,
            value: formatValue(value),
        }));
        setProjectData(projectDataTmp);
        const plansExtendTmp = mapPlansToExtend(data.EfficiencyTrackerReductionPlans);
        setPlansExtend(plansExtendTmp);
    }, [data]);

    useEffect(() => {
        if (plansExtend) {
            const allRegionsTmp = plansExtend.map(plan => plan.Region);
            const regionDropDownListTmp = allRegionsTmp.map(region => ({
                value: region,
                label: region,
            }));
            setRegionDropDownList(regionDropDownListTmp);
            console.log("regionDropDownListTmp", regionDropDownListTmp);
            const cplan = plansExtend[0];
            setCurrentPlan(cplan);
            setTargetValue(cplan?.Targets as unknown as TargetPair[]);
            setRegionSelectedOption(null);
        }
    }, [plansExtend]);

    useEffect(() => {
        if (plansExtend) {
            const cplan = plansExtend?.find(item => item.Region == regionSelectedOption?.value);
            setCurrentPlan(cplan);
            setTargetValue(cplan?.Targets as unknown as TargetPair[]);
        }
    }, [plansExtend, regionSelectedOption]);

    const handleRegionDropdownChange = (newValue: SingleValue<OptionType>) => {
        console.log("regionSelectedOption:", regionSelectedOption);
        if (newValue) {
            console.log(newValue);
            setRegionSelectedOption(newValue);
        }
        else {
            setRegionSelectedOption(null);
        }
    };
    const handleEditClick = () => {
        gotoPage(`${Pages.EfficiencyTracker}/${Pages.EFEditPage}/${id}`)
    };

    // add share for project
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');

    const handleAddOwnerClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleEmailChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string | undefined) => {
        setEmail(newText || "");
    };

    const handleEmailSubmit = () => {
        postMethod<any>('api/efficiencytracker/share?projectId=' + id + "&targetUser=" + email, null)
            .then((response) => {
                if (response.ok) {
                    alert('Uploaded successfully!');
                } else {
                    alert('Uploaded failed!');
                }
            })
            .catch((error) => {
                alert('Error when uploading:' + error);
            });
        setShowModal(false);
        setEmail('');
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Project Details</h2>
                <PrimaryButton onClick={handleAddOwnerClick} className={`${styles.submitButton}`}>Add Project Owner</PrimaryButton>
            </div>
            <Modal isOpen={showModal} onDismiss={handleModalClose} containerClassName={styles.modalContainer}>
                <div className={styles.modalHeader}>
                    <span >{"Share project to other"}</span>
                    <IconButton
                        className={styles.iconButton}
                        iconProps={{ iconName: "Cancel" }}
                        ariaLabel="Close"
                        onClick={handleModalClose}
                    />
                </div>
                <div className={styles.modalBody}>
                    <TextField
                        label="MS Account Email"
                        value={email}
                        placeholder="Format:alias@microsoft.com"
                        onChange={handleEmailChange}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PrimaryButton onClick={handleEmailSubmit} style={{ marginLeft: '8px', marginRight: '8px' }}>Confirm</PrimaryButton>
                    <PrimaryButton onClick={handleModalClose}>Cancel</PrimaryButton>
                </div>

            </Modal>
            {(projectData !== undefined) && (
                <TableComponent data={projectData}></TableComponent>
            )}
            <h2>All Plans</h2>
            <div>
                {plansExtend === undefined ? (
                    <div> This project has no plans.</div>
                ) : (
                    <div>
                        <PlanTable data={plansExtend}></PlanTable>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h2>Region Details</h2>
                            <PrimaryButton onClick={handleEditClick} className={`${styles.submitButton}`}>Edit Region Targets</PrimaryButton>
                        </div>
                        <AsyncPaginate
                            key={`RegionSelect${id}`}
                            isClearable
                            isSearchable
                            name="Region"
                            placeholder="Select Region"
                            value={regionSelectedOption}
                            loadOptions={getLoadOptions(regionDropDownList)}
                            onChange={handleRegionDropdownChange}
                            styles={{
                                multiValue: (base) => ({ ...base, width: 300 }),
                                control: (base) => ({ ...base, width: 300 }),
                                menuList: (base) => ({ ...base, width: 300 }),
                                menu: (base) => ({ ...base, width: 300 }),
                            }}
                        />
                        <div>
                            {(currentPlan !== undefined) && (
                                    <div className={styles.layout}>
                                        <div className={styles.left}>
                                            <LinearChart
                                                PlanId={currentPlan.Id}
                                                Region={currentPlan.Region}
                                                TargetTrend={targetValue}
                                                BaseLineValue={currentPlan.BaseLineValue}
                                                EfficiencyTargetCatagory={currentPlan.EfficiencyTargetCategory}
                                                TrackingGap={currentPlan.TrackingGap}
                                            />
                                        </div>
                                        <div className={styles.right}>
                                            <RegionDetailsTable PlanId={currentPlan.Id} Region={currentPlan.Region} Plan={currentPlan} EfficiencyTargetCatagory={currentPlan.EfficiencyTargetCategory}></RegionDetailsTable>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={pageStyles.divWideBlank}></div>
                    </div>
                )}
            </div>
        </div>
    );
}

function getComponent(components: Component[], type: ComponentTypeEnum): string | undefined {
    const filteredValues = components
        .filter((item: Component) => item.Type === type)
        .map((item: Component) => `[ ${item.Value} ]`);

    if (filteredValues.length > 0) {
        return filteredValues.join('\n');
    } else {
        return undefined;
    }
}
export function mapPlansToExtend(plans: EfficiencyTrackerReductionPlan[]): EfficiencyTrackerReductionPlanExtend[] {
    return plans.map((plan: EfficiencyTrackerReductionPlan) => {
        const extendedConfigs: EfficiencyTrackerReductionPlanRegionConfig[] = plan.EfficiencyTrackerReductionPlanRegionConfigs as unknown as EfficiencyTrackerReductionPlanRegionConfig[];
        const components: Component[] = plan.Components as unknown as Component[];

        return extendedConfigs.map((config) => ({
            Id: plan.Id,
            ProjectId: plan.ProjectId,
            Target: plan.Target,
            Status: plan.Status,
            ConfidenceLevel: plan.ConfidenceLevel,
            QualityForMap: plan.QualityForMap,
            Platform: plan.Platform,
            Owner: plan.Owner,
            CreateTime: new Date(plan.CreateTime),
            ResourceType: plan.ResourceType,
            EfficiencyTargetCategory: (plan.EfficiencyTargetCatagory == undefined || plan.EfficiencyTargetCatagory == null || plan.EfficiencyTargetCatagory == "") ? "Demands" : plan.EfficiencyTargetCatagory,
            BaseLineTime: new Date(plan.CreateTime),
            BaseLineValue: config.BaseLineValue ? config.BaseLineValue : "1",
            Region: config.Region ? config.Region : "",
            Targets: config.Targets,
            EffectivePeriod: new Date(config.EffectivePeriod),
            Process: getComponent(components, ComponentTypeEnum.Process),
            RestApp: getComponent(components, ComponentTypeEnum.RestApp),
            Shard: getComponent(components, ComponentTypeEnum.Shard),
            Forest: getComponent(components, ComponentTypeEnum.Forest),
            Dag: getComponent(components, ComponentTypeEnum.Dag),
            DagConfiguration: getComponent(components, ComponentTypeEnum.DagConfiguration),
            Sku: getComponent(components, ComponentTypeEnum.Sku),
            TrackingGap: plan.TrackingGap ? "Yes" : "No",
        }));
    }).flat();
}

export default DetailProjectTable;