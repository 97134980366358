import * as React from "react";

import { CosmosMetricEnum, CosmosMetricName, CosmosSourceTypeEnum, CosmosSourceTypeName } from "../../models/CosmosModels";
import {
    DropdownMenuItemType,
    IDropdownOption,
    IStackTokens,
    MessageBar,
    MessageBarType,
    Stack,
} from "@fluentui/react";
import LineChart, { LineChartSeries } from "../common/LineChart";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { currencyFormatter, formatNumber } from "../../utils/currency";
import { sum, sumBy, values } from "lodash";
import { useBatchGetCosmosDailyMetrics, useGetCosmosDailyMetrics } from "../../hooks/useCosmosQuery";
import { useEffect, useState } from "react";
import { useGetAzureCostOverview, useGetAzureDailyMetricsQuery, useGetAzureNonComputeCosts } from "../../hooks/useAzureQuery";
import { useGetCosmicDailyCost, useGetCosmicDailyMetricsByWorkload } from "../../hooks/useCosmicQuery";
import { useGetPcmV2Cost, useGetPcmV2CostByCategory } from "../../hooks/useSubstrateV2Query";

import { CategoryDivision } from "../../models/CategoryDivision";
import CommonConstants from "../../models/constants/CommonConstants";
import CostCard from "../common/costCard/CostCard";
import { DataSourceEnum } from "../../models/NotificationModels";
import { Endpoints } from "../../utils/Constants";
import { FiltersBanner } from "../common/FiltersBanner/FiltersBanner";
import { IAppState } from "../../store";
import { ISavedViewData } from "../../reducers/savedViewReducer";
import LoadingState from "../ResponseBoundary/LoadingState";
import { NON_COMPUTE_CATEGORIES } from "../azure/common/AzureComputeConstants";
import PageHeader from "../common/PageHeader";
import { PcmV2SceneMetrics } from "../../models/constants/PcmV2Constants";
import { PcmV2SceneTypeEnum } from "../../models/PcmV2Models";
import ShareView from "./shareView/ShareView";
import { SubstrateCard } from "./SubstrateCard";
import { SubstratePlatformCard } from "./SubstratePlatformCard";
import { getActiveAnomalies } from "../../utils/AnomalyDetectionUtils";
import { getAverage } from "../../utils/common";
import moment from "moment";
import styles from "./LandingPage.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useBillingCost } from "../../hooks/useBillingCost";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useDateRange } from "../../hooks/useDateSelector";
import { useFlights } from "../../hooks/useSettings";
import { useGetAnomaliesByDataSourceQuery } from "../../hooks/useNotificationQuery";
import { useGotoPage } from "../../hooks/useGotoPage";
import { useSelector } from "react-redux";

const SupportedFilters = [
    CategoryDivision.Division,
    CategoryDivision.Organization,
    CategoryDivision.ServiceGroup,
    CategoryDivision.TeamGroup,
    CategoryDivision.Owner,
    CategoryDivision.DataSetV2,
    CategoryDivision.VdirV2,
    CategoryDivision.ProcessV2,
    CategoryDivision.ScenarioTagV2,
    CategoryDivision.GriffinAppV2,
    CategoryDivision.GriffinProcessorV2,
    CategoryDivision.StoreClientV2,
    CategoryDivision.StoreClientComponentV2,
    CategoryDivision.Cluster,
    CategoryDivision.Subscription,
];

export const LandingPage: React.FC = () => {
    const { data: substrateV2Cost, isLoading: substrateLoading } = useGetPcmV2Cost();
    const cardTokens: IStackTokens = { childrenGap: 24 };
    const { data: flights } = useFlights();
    const substrateAnomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const activeSubstrateAnomalies = React.useMemo(
        () => getActiveAnomalies(substrateAnomaliesQuery.data, PcmV2SceneMetrics[PcmV2SceneTypeEnum.Overview]),
        [substrateAnomaliesQuery.data]
    );
    const [showBillingCost] = useBillingCost()
    const { publicCost, azscCost } = useGetAzureCostOverview(showBillingCost && flights?.enableBillingCost);
    const { cosmicDailyMetrics: cosmicDailyAppCores, isLoading: cosmicAppCoresLoading } = useGetCosmicDailyMetricsByWorkload("AppCores", 5);
    const { cosmicDailyMetrics: cosmicDailyCpuUtilization, isLoading: cosmicCpuUtilizationLoading } = useGetCosmicDailyMetricsByWorkload("CpuUtilization", 5);
    const { cosmicDailyCost, isLoading: cosmicLoading } = useGetCosmicDailyCost();

    const { data: azureComputeDailyCost, isLoading: isAzureComputeLoading } = useGetAzureDailyMetricsQuery(
        Endpoints.GetAzureDailyMetrics,
        (showBillingCost && flights?.enableBillingCost) ? "BillingCost" : "VMCost",
        "Total",
        "Sum"
    );
    const [containsNonServiceFilters, {setFalse: setContainsNonServiceFiltersFalse, setTrue: setContainsNonServiceFiltersTrue}] = useBoolean(true);

    const { data: azureNonComputeDailyCost, isLoading: isAzureNonComputeLoading } = useGetAzureNonComputeCosts(NON_COMPUTE_CATEGORIES);
    const totalReclaimableCores = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "ReclaimableCores", "Total", "Sum");
    const totalCores = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "TotalCores", "Total", "Sum");
    const idleCores = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "IdleCores", "Total", "P99");
    const consumedCores = idleCores.data?.data.map((metric, index) => ({
        date: metric.date,
        metricValue: (totalCores.data?.data[index].metricValue || 0) - (metric.metricValue || 0),
    }));
    const nonReclaimableCores = totalReclaimableCores.data?.data.map((metric, index) => ({
        date: metric.date,
        metricValue: (totalCores.data?.data[index].metricValue || 0) - (consumedCores ? consumedCores[index].metricValue : 0) - (metric.metricValue || 0),
    }));
    const azureComputeAnomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.AzureCompute);

    const activeTotalCoresAnomalies = React.useMemo(
        () => getActiveAnomalies(azureComputeAnomaliesQuery.data, ["TotalCores"]),
        [azureComputeAnomaliesQuery.data]
    );
    const { startDate, endDate } = useDateRange();

    const dateRange = [startDate, endDate];
    const secondaryMetricType = CosmosMetricEnum.Costs;
    const secondarySourceTypes = [CosmosSourceTypeEnum.Processing, CosmosSourceTypeEnum.Storage];
    const cosmosQuery = useBatchGetCosmosDailyMetrics(secondarySourceTypes, secondaryMetricType);
    const query = useGetPcmV2CostByCategory();
    const cosmosTotalCost = useGetCosmosDailyMetrics(CosmosSourceTypeEnum.All, CosmosMetricEnum.Costs);
    const cosmosProcessingCosts = useGetCosmosDailyMetrics(CosmosSourceTypeEnum.Processing, CosmosMetricEnum.Costs);
    const cosmosStorageCosts = useGetCosmosDailyMetrics(CosmosSourceTypeEnum.Storage, CosmosMetricEnum.Costs);
    const [step, updateStep] = useState<number>(0);

    const formatData = (value: number, metric: CosmosMetricEnum) => {
        switch (metric) {
            case CosmosMetricEnum.Token:
                return formatNumber(value / 24, 0);
            case CosmosMetricEnum.PhysicalSize:
                return `${formatNumber(value / 1024 / 1024, 0)} TB`;
            default: {
                return currencyFormatter(value, 2, "$");
            }
        }
    };

    const formatAxisData = (value: number, metric: CosmosMetricEnum) => {
        switch (metric) {
            case CosmosMetricEnum.Token:
                return currencyFormatter(value / 24, 0, "");
            case CosmosMetricEnum.PhysicalSize:
                return currencyFormatter(value / 1024 / 1024, 0, "");
            default: {
                return currencyFormatter(value, 2, "$");
            }
        }
    };

    const cosmosSeries: LineChartSeries[] = secondarySourceTypes.map((source, index) => ({
        color: CommonConstants.DefaultColors[index],
        name: secondaryMetricType === CosmosMetricEnum.Costs ? CosmosSourceTypeName[source] : CosmosMetricName[secondaryMetricType],
        data: cosmosQuery.data && cosmosQuery.data[index].data.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
    }));
    const gotoPage = useGotoPage();


    const azureDailyCost = [];
    const map = new Map();
    const all = [
        ...(azureComputeDailyCost?.data || []),
        ...(azureNonComputeDailyCost?.["Storage"] || []),
        ...(azureNonComputeDailyCost?.["SQL Database"] || []),
        ...(azureNonComputeDailyCost?.["Azure Cosmos DB"] || []),
    ];

    all.forEach((item) => {
        const date = moment(item.date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");
        if (map.has(date)) {
            map.set(date, map.get(date) + item.metricValue);
        } else {
            map.set(date, item.metricValue);
        }
    });
    for (const [key, value] of map) {
        azureDailyCost.push({
            date: key,
            metricValue: value,
        });
    }

    const substrateDailyMetricMap = new Map<number, number>();
    const substrateDailyCost = [];
    if (query.data) {
        const metrics = PcmV2SceneMetrics[PcmV2SceneTypeEnum.Overview];
        for (const metric of metrics) {
            const costs = query.data[metric];
            if (costs != null && costs != undefined) {
                for (const cost of costs) {
                    const date = cost.extractionDate.valueOf();
                    substrateDailyMetricMap.set(date, cost.cost + (substrateDailyMetricMap.get(date) || 0));
                }
            }
        }
    }
    for (const [key, value] of substrateDailyMetricMap) {
        substrateDailyCost.push({
            date: key,
            metricValue: value,
        });
    }

    const { savedViews } = useSelector<IAppState, ISavedViewData>((state) => state.savedViews);

    const options: IDropdownOption[] = [
        { key: "fruitsHeader", text: "Built-in filter views", itemType: DropdownMenuItemType.Header },
        { key: "global", text: "Global (default)" },
        { key: "divider_1", text: "-", itemType: DropdownMenuItemType.Divider },
        { key: "vegetablesHeader", text: "Custom filter sets", itemType: DropdownMenuItemType.Header },
    ];

    values(savedViews).forEach((view) => {
        options.push({
            key: view.viewName,
            text: view.viewName,
        });
    });

    const { filters: globalFilters } = useCategoryFilters();
    
    const serviceFilters = globalFilters.filters.Service;
    const platformServices = useSelector<IAppState, string[]>((state) => state.serviceTree.platformServices);
    const containsPlatformServices = React.useMemo(
        () => serviceFilters !== undefined && serviceFilters.some((service) => platformServices.includes(service)),
        [platformServices, serviceFilters]);

    useEffect(() => {
        const filters = globalFilters.filters;
        if (filters.Cluster 
            || filters.DataSetV2
            || filters.GriffinApp
            || filters.GriffinAppV2
            || filters.GriffinProcessor
            || filters.GriffinProcessorV2
            || filters.Process
            || filters.ProcessV2
            || filters.SSDDataSetName
            || filters.ScenarioTag
            || filters.ScenarioTagV2
            || filters.StoreClient
            || filters.StoreClientComponent
            || filters.StoreClientComponentV2
            || filters.StoreClientV2
            || filters.Subscription
            || filters.VDir
            || filters.VdirV2) {
                setContainsNonServiceFiltersTrue()
            } else {
                setContainsNonServiceFiltersFalse()
            }
    }, [globalFilters.filters])

    return (
        <div className={styles.page}>
            <PageHeader title="Home" description="" link="" linkText="" inlineLink className={styles.pageHeader} />
            {
                flights?.enableCreateView && 
                <FiltersBanner supportedFilters={SupportedFilters} showServiceFilter />
            }
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch" }} className={styles.layoutCard}>
                <div style={{ width: "30%" }}>
                    <div className={styles.section}>
                        <div className={styles.sectionHeaderContent2}>
                            <h4 className={styles.sectionHeaderContent2}>Overview</h4>
                        </div>
                    </div>
                    {!substrateLoading && !isAzureComputeLoading && !isAzureNonComputeLoading && !cosmicLoading && !cosmosTotalCost?.isLoading ? (
                        <>
                            <div className={styles.title}>
                                <span className={styles.titleTotalCost}>
                                    {currencyFormatter(
                                        (azureComputeDailyCost?.total || 0) +
                                        sumBy(values(azureNonComputeDailyCost), (dailyCosts) =>
                                            sumBy(dailyCosts, (dailyCost) => dailyCost.metricValue || 0)
                                        ) +
                                        (substrateV2Cost?.cost || 0) +
                                        cosmicDailyCost.reduce((sum, val) => sum + (val.cost || 0), 0) +
                                        (cosmosTotalCost?.data?.total || 0),
                                        2,
                                        "$"
                                    )}
                                </span>{" "}
                                Total Cost
                            </div>
                            <div className={styles.grid}>
                                <div key="Substrate">
                                    <CostCard title="Substrate" color={CommonConstants.DefaultColors[0]} cost={substrateV2Cost?.cost || 0} />
                                </div>
                                <div key="Azure">
                                    <CostCard
                                        title="Azure"
                                        color={CommonConstants.DefaultColors[1]}
                                        cost={
                                            (azureComputeDailyCost?.total || 0) +
                                            sumBy(values(azureNonComputeDailyCost), (dailyCosts) =>
                                                sumBy(dailyCosts, (dailyCost) => dailyCost.metricValue || 0)
                                            )
                                        }
                                    />
                                </div>
                                <div key="COSMIC">
                                    <CostCard
                                        title="COSMIC"
                                        color={CommonConstants.DefaultColors[2]}
                                        cost={cosmicDailyCost.reduce((sum, val) => sum + (val.cost || 0), 0)}
                                    />
                                </div>
                                <div key="COSMOS BDP">
                                    <CostCard title="COSMOS BDP" color={CommonConstants.DefaultColors[3]} cost={cosmosTotalCost?.data?.total || 0} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <LoadingState />
                    )}
                </div>
                <div style={{ width: "70%" }}>
                    <div className={styles.section}>
                        <div className={styles.sectionHeaderContent2}>
                            <h4 className={styles.sectionHeaderContent2}>Daily Cost Trend</h4>
                        </div>
                    </div>
                    {!substrateLoading && !isAzureComputeLoading && !isAzureNonComputeLoading && !cosmicLoading && !cosmosTotalCost?.isLoading ? (
                        <LineChart
                            height={180}
                            series={[
                                {
                                    name: "Substrate",
                                    data: substrateDailyCost.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                                    color: "#0099BC",
                                    anomalies: activeSubstrateAnomalies,
                                },
                                {
                                    name: "Azure",
                                    data: azureDailyCost.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                                    anomalies: activeTotalCoresAnomalies,
                                    color: "#982570",
                                },
                                {
                                    name: "Cosmic",
                                    data:
                                        cosmicDailyCost &&
                                        cosmicDailyCost.map((singleMetric) => [
                                            moment(moment(singleMetric.extractionDate.toString().replace("Z", ""))).valueOf(),
                                            singleMetric.cost,
                                        ]),
                                    anomalies: [],
                                    color: "#627CEF",
                                },
                                {
                                    name: "COSMOS BDP",
                                    data:
                                        cosmosQuery.data &&
                                        cosmosQuery.data[0].data.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                                    color: "#C19C00",
                                    anomalies: [],
                                },
                            ]}
                            // fillMissingAbnormalData
                            anomalies={[...activeTotalCoresAnomalies, ...activeSubstrateAnomalies]}
                            totalTextPrefix="Cost"
                        />
                    ) : (
                        <LoadingState />
                    )}
                </div>
                {/* {flights?.enableShareView && (
                    <div style={{ width: "10%" }}>
                        <div>
                            <button className={styles.shareViewButton} onClick={() => updateStep(1)}>
                                Create a view
                            </button>
                        </div>
                        <div>
                            <button className={styles.shareViewButton} onClick={() => updateStep(4)}>
                                Share a view
                            </button>
                        </div>
                    </div>
                )} */}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch" }}>
                <div style={{ width: "49.5%", height: 670, boxSizing: "border-box" }} className={styles.layoutCard}>
                    {
                        containsPlatformServices ?
                        <SubstratePlatformCard /> :
                        <SubstrateCard />
                    }
                </div>
                <div style={{ width: "49.5%", boxSizing: "border-box" }} className={styles.layoutCard}>
                    <div className={styles.section}>
                        {/* <Separator styles={{ root: styles.separator }} /> */}
                        <div className={styles.sectionHeaderContent2}>
                            <h4
                                className={styles.sectionHeaderContent2}
                                onClick={() => {
                                    trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToAzure, "Navigate to Azure", LogTarget.Title);
                                    gotoPage("/Azure");
                                }}
                            >
                                Azure
                            </h4>
                        </div>
                    </div>
                    {!isAzureComputeLoading && !isAzureNonComputeLoading ? (
                        <>
                            <div className={styles.title}>
                                <span className={styles.titleTotalCost}>
                                    {currencyFormatter(
                                        (azureComputeDailyCost?.total || 0) +
                                        sumBy(values(azureNonComputeDailyCost), (dailyCosts) =>
                                            sumBy(dailyCosts, (dailyCost) => dailyCost.metricValue || 0)
                                        ),
                                        2,
                                        "$"
                                    )}
                                </span>{" "}
                                Total Cost
                            </div>
                            <div className={styles.grid}>
                                <div key="Public Compute">
                                    <CostCard title="Public Compute" color={CommonConstants.DefaultColors[0]} cost={publicCost} />
                                </div>
                                <div key="AzSC Compute">
                                    <CostCard title="AzSC Compute" color={CommonConstants.DefaultColors[1]} cost={azscCost} />
                                </div>
                                <div key="Storage">
                                    <CostCard
                                        title="Storage"
                                        color={CommonConstants.DefaultColors[2]}
                                        cost={
                                            (azureNonComputeDailyCost &&
                                                azureNonComputeDailyCost["Storage"] &&
                                                sum(azureNonComputeDailyCost["Storage"].map((singleMetric) => singleMetric.metricValue || 0))) ||
                                            0
                                        }
                                    />
                                </div>
                                <div key="SQL DB">
                                    <CostCard
                                        title="SQL DB"
                                        color={CommonConstants.DefaultColors[3]}
                                        cost={
                                            (azureNonComputeDailyCost &&
                                                azureNonComputeDailyCost["SQL Database"] &&
                                                sum(azureNonComputeDailyCost["SQL Database"].map((singleMetric) => singleMetric.metricValue || 0))) ||
                                            0
                                        }
                                    />
                                </div>
                                <div key="COSMOS DB">
                                    <CostCard
                                        title="COSMOS DB"
                                        color={CommonConstants.DefaultColors[4]}
                                        cost={
                                            (azureNonComputeDailyCost &&
                                                azureNonComputeDailyCost["Azure Cosmos DB"] &&
                                                sum(azureNonComputeDailyCost["Azure Cosmos DB"].map((singleMetric) => singleMetric.metricValue || 0))) ||
                                            0
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <LoadingState />
                    )}
                    {/* <p>Total {(azureComputeDailyCost?.total || 0) + sumBy(values(azureNonComputeDailyCost), dailyCosts => sumBy(dailyCosts, dailyCost => dailyCost.metricValue || 0))}</p> */}
                    <div className={styles.sectionHeaderContent}>
                        <h4 className={styles.sectionHeaderTitle}>Cores</h4>
                        <div />
                        {!totalReclaimableCores.isLoading && !totalCores.isLoading && !idleCores.isLoading ? (
                            <>
                                <Stack className={styles.cardSection} horizontal tokens={cardTokens}>
                                    {[
                                        {
                                            title: "Average Reclaimable Cores",
                                            cost: getAverage(totalReclaimableCores.data?.data),
                                            color: "#0099BC",
                                            noPrefix: true,
                                            showTooltip: true,
                                            date: dateRange,
                                        },
                                        {
                                            title: "Average Non-Reclaimable Cores",
                                            cost: getAverage(nonReclaimableCores),
                                            color: "#982570",
                                            noPrefix: true,
                                            showTooltip: true,
                                            date: dateRange,
                                        },
                                        {
                                            title: "Average Consumed Cores",
                                            cost: getAverage(consumedCores),
                                            color: "#6674E3",
                                            noPrefix: true,
                                            showTooltip: true,
                                            date: dateRange,
                                        },
                                    ].map((cardProp) => (
                                        <CostCard key={cardProp.title} {...cardProp} />
                                    ))}
                                </Stack>
                                <LineChart
                                    height={180}
                                    series={[
                                        {
                                            name: "Reclaimable Cores",
                                            data: totalReclaimableCores.data?.data.map((singleMetric) => [
                                                singleMetric.date.valueOf(),
                                                singleMetric.metricValue,
                                            ]),
                                        },
                                        {
                                            name: "Non-reclaimable Cores",
                                            data: nonReclaimableCores?.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                                            anomalies: activeTotalCoresAnomalies,
                                        },
                                        {
                                            name: "Consumed Cores",
                                            data: consumedCores?.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                                            anomalies: activeTotalCoresAnomalies,
                                        },
                                    ]}
                                    isNotCurrency
                                    showArea
                                    fillMissingAbnormalData
                                    anomalies={activeTotalCoresAnomalies}
                                    totalTextPrefix="Total Cores"
                                />
                            </>
                        ) : (
                            <LoadingState />
                        )}
                    </div>
                    <button
                        className={styles.navButton}
                        onClick={() => {
                            trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToAzure, "Navigate to Azure", LogTarget.Button);
                            gotoPage("/Azure");
                        }}
                    >
                        View Azure
                    </button>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch" }}>
                <div className={styles.layoutCard} style={{ width: "49.5%", height: 670, boxSizing: "border-box" }}>
                {containsNonServiceFilters ? (
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            onDismiss={() => {
                                setContainsNonServiceFiltersFalse();
                            }}
                        >
                            Currently, Jaws COSMIC only supports service tree filters(service/teamGroup/serviceGroup/organization/division).
                        </MessageBar>
                    ) : (
                        <></>
                    )}
                    <div className={styles.section}>
                        <div className={styles.sectionHeaderContent2}>
                            <h4
                                className={styles.sectionHeaderContent2}
                                onClick={() => {
                                    trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToCosmic, "Navigate to Cosmic", LogTarget.Title);
                                    window.open(
                                        "https://msit.powerbi.com/groups/b61e509d-d7b3-4375-a9ae-0f2681bc59c8/reports/262f632f-f91f-43d9-8ef1-2d808eb414a4/ReportSectionc88f9b77a61c09477456?experience=power-bi"
                                    );
                                }}
                            >
                                COSMIC
                            </h4>
                        </div>
                    </div>
                    {!cosmicLoading ? (
                        <div className={styles.title}>
                            <span className={styles.titleTotalCost}>
                                {currencyFormatter(
                                    cosmicDailyCost.reduce((sum, val) => sum + (val.cost || 0), 0),
                                    2,
                                    "$"
                                )}
                            </span>{" "}
                            Total Cost
                        </div>
                    ) : (
                        <LoadingState />
                    )}
                    <div className={styles.sectionHeaderContent}>
                        <h4 className={styles.sectionHeaderTitle}>Cosmic App Cores of Top 5 Workloads</h4>
                    </div>
                    <div style={{ height: 180 }}>
                        {!cosmicAppCoresLoading ? (
                            <LineChart
                                height={180}
                                series={[
                                    ...Object.keys(cosmicDailyAppCores).map((key) => ({
                                        name: key,
                                        data:
                                            cosmicDailyAppCores?.[key] &&
                                            cosmicDailyAppCores[key].map(
                                                (singleMetric) =>
                                                    [singleMetric.extractionDate.valueOf(), singleMetric.cpuRequestCores] as [number, number | undefined]
                                            ),
                                    })),
                                ]}
                                isNotCurrency
                                // showArea
                                fillMissingAbnormalData
                                totalTextPrefix="App Cores"
                            />
                        ) : (
                            <LoadingState />
                        )}
                    </div>

                    <div className={styles.sectionHeaderContent}>
                        <h4 className={styles.sectionHeaderTitle}>P99 CPU Utilization of Top 5 Workloads</h4>
                    </div>
                    <div style={{ height: 180 }}>
                        {!cosmicCpuUtilizationLoading ? (
                            <LineChart
                                height={180}
                                series={[
                                    ...Object.keys(cosmicDailyCpuUtilization).map((key) => ({
                                        name: key,
                                        data:
                                            cosmicDailyCpuUtilization?.[key] &&
                                            cosmicDailyCpuUtilization[key].map(
                                                (singleMetric) =>
                                                    [singleMetric.extractionDate.valueOf(), singleMetric.cpuUsage * 100] as [number, number | undefined]
                                            ),
                                    })),
                                ]}
                                suffix="%"
                                isNotCurrency
                                fillMissingAbnormalData
                                totalTextPrefix="CPU Utilization"
                            />
                        ) : (
                            <LoadingState />
                        )}
                    </div>
                    <button
                        className={styles.navButton}
                        onClick={() => {
                            trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToCosmic, "Navigate to Cosmic", LogTarget.Button);
                            window.open(
                                "https://msit.powerbi.com/groups/b61e509d-d7b3-4375-a9ae-0f2681bc59c8/reports/262f632f-f91f-43d9-8ef1-2d808eb414a4/ReportSectionc88f9b77a61c09477456?experience=power-bi"
                            );
                        }}
                    >
                        View COSMIC
                    </button>
                </div>
                <div style={{ width: "49.5%", boxSizing: "border-box" }} className={styles.layoutCard}>
                    <div className={styles.section}>
                        <div className={styles.sectionHeaderContent2}>
                            <h4
                                className={styles.sectionHeaderContent2}
                                onClick={() => {
                                    trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToCosmos, "Navigate to Cosmos", LogTarget.Title);
                                    gotoPage("/COSMOS");
                                }}
                            >
                                COSMOS BDP
                            </h4>
                        </div>
                    </div>
                    {!cosmosTotalCost.isLoading ? (
                        <>
                            <div className={styles.title}>
                                <span className={styles.titleTotalCost}>{currencyFormatter(cosmosTotalCost?.data?.total || 0, 2, "$")}</span> Total Cost
                            </div>
                            <div className={styles.grid}>
                                <div key="Processing">
                                    <CostCard title="Processing" color={CommonConstants.DefaultColors[0]} cost={cosmosProcessingCosts?.data?.total || 0} />
                                </div>
                                <div key="Storage">
                                    <CostCard title="Storage" color={CommonConstants.DefaultColors[1]} cost={cosmosStorageCosts?.data?.total || 0} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <LoadingState />
                    )}
                    <div className={styles.sectionHeaderContent}>
                        <h4 className={styles.sectionHeaderTitle}>Storage and Processing Cost Trends</h4>
                    </div>
                    {!cosmosQuery.isLoading ? (
                        <LineChart
                            {...{
                                series: cosmosSeries,
                                height: 180,
                                tooltipYFormatter: (value: number) => formatData(value, secondaryMetricType),
                                yFormatter: (value: number) => formatAxisData(value, secondaryMetricType),
                                isNotCurrency: secondaryMetricType != CosmosMetricEnum.Costs,
                            }}
                        />
                    ) : (
                        <LoadingState />
                    )}
                    <button
                        className={styles.navButton}
                        onClick={() => {
                            trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToCosmos, "Navigate to Cosmos", LogTarget.Button);
                            gotoPage("/COSMOS");
                        }}
                    >
                        View COSMOS BDP
                    </button>
                </div>
            </div>
            <ShareView step={step} updateStep={updateStep}></ShareView>
        </div>
    );
};
