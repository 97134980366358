// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UOVBYHS8WQeGbLXPDX5c {\n  z-index: 10 !important;\n}\n.jeeXa0o987nybAsLNtMp {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.rFdUduebm_5Wa4kmuQNn {\n  flex-grow: 1;\n}\n.MuiMenu-root {\n  z-index: 10000000;\n}\n", "",{"version":3,"sources":["webpack://./src/components/chat/ChatPanel.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;AAEA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;AAAJ;AAGA;EACI,YAAA;AADJ;AAIA;EACI,iBAAA;AAFJ","sourcesContent":[".panelRoot {\n    z-index: 10 !important;\n}\n\n.scrollableContent {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.panelContent {\n    flex-grow: 1;\n}\n\n:global(.MuiMenu-root) {\n    z-index: 10000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelRoot": "UOVBYHS8WQeGbLXPDX5c",
	"scrollableContent": "jeeXa0o987nybAsLNtMp",
	"panelContent": "rFdUduebm_5Wa4kmuQNn"
};
export default ___CSS_LOADER_EXPORT___;
