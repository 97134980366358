import * as React from "react";
import { Endpoints } from "../../../utils/Constants";
import { useGetAzureDailyMetricsQuery, useGetAzureHierarchicalMetricsQuery } from "../../../hooks/useAzureQuery";
import styles from "../Azure.less";
import { getAverage } from "../../../utils/common";
import { LineChartContainer } from "../common/LineChartContainer";
import { Checkbox, DefaultButton, IIconProps, IconButton, Separator, Stack } from "@fluentui/react";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { isEmpty, values } from "lodash";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import LineChart from "../../common/LineChart";
import LoadingState from "../../ResponseBoundary/LoadingState";
import { useMsal } from "@azure/msal-react";
import { useFlights } from "../../../hooks/useSettings";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import html2canvas from "html2canvas";
import FeedbackPanel from "../../feedback/FeedbackPanel";
import { trackEventCallback } from "../../../utils/AppInsights";

interface ITopNReclaimableCoreChartProps {
    updateSelectedTab: (tab: string) => void;
    setDefaultRegionDetails: (region: string) => void;
    setDefaultServiceDetails: (service: string) => void;
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const TopNReclaimableCoreChart: React.FC<ITopNReclaimableCoreChartProps> = (props) => {
    const [showSpotVM, setShowSpotVM] = React.useState(true);
    const {data: top5OrgReclaimableCore, isLoading: isTop5OrgReclaimableCoreLoading} = useGetAzureHierarchicalMetricsQuery(Endpoints.GetAzureHierarchicalMetrics, "Organization", "ReclaimableCores", 5, "Total");
    const {data: top5ServiceReclaimableCore, isLoading: isTop5ServiceReclaimableCoreLoading} = useGetAzureHierarchicalMetricsQuery(Endpoints.GetAzureHierarchicalMetrics, "Service", "ReclaimableCores", 5, "Total");
    const {data: top5ServiceTotalCore, isLoading: isTop5ServiceTotalCoreLoading} = useGetAzureHierarchicalMetricsQuery(Endpoints.GetAzureHierarchicalMetrics, "Service", "TotalCores", 5, "Total", showSpotVM);
    const totalReclaimableCores = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "ReclaimableCores", "Total", "Sum");
    const totalCores = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "TotalCores", "Total", "Sum");
    const idleCores = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "IdleCores", "Total", "P99");
    const consumedCores = idleCores.data?.data.map((metric, index) => ({date: metric.date, metricValue: (totalCores.data?.data[index].metricValue || 0) - (metric.metricValue || 0)}));
    const nonReclaimableCores = totalReclaimableCores.data?.data.map((metric, index) => ({date: metric.date, metricValue: ((totalCores.data?.data[index].metricValue || 0) - (consumedCores ? consumedCores[index].metricValue : 0) - (metric.metricValue || 0))}));
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.AzureCompute);
    const activeTotalCoresAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["TotalCores"]), [anomaliesQuery.data]);
    const isEmtpy = values(useCategoryFilters().filters.filters).every(singleFilters => isEmpty(singleFilters));
    const { startDate, endDate } = useDateRange();
    const dateRange = [startDate, endDate];

    // User Feedback
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async (title: string) => {
        trackEventCallback(LogComponent.AzureTabs, LogElement.AzureCoresTrendTopFive, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);

        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };
    return (
        <>
        <Separator alignContent="start">Core</Separator>
        <div className={styles.content}>
            <div>
                <LineChartContainer
                    title="Cores"
                    isLoading={totalReclaimableCores.isLoading || totalCores.isLoading || idleCores.isLoading}
                    cardProps={[
                        {title: "Average Reclaimable Cores", cost: getAverage(totalReclaimableCores.data?.data), color: "#0099BC", noPrefix: true, showTooltip: true, date: dateRange},
                        {title: "Average Non-Reclaimable Cores", cost: getAverage(nonReclaimableCores), color: "#982570", noPrefix: true, showTooltip: true, date: dateRange},
                        {title: "Average Consumed Cores", cost: getAverage(consumedCores), color: "#6674E3", noPrefix: true, showTooltip: true, date: dateRange},
                    ]}
                    series={[
                        {
                            name: "Reclaimable Cores",
                            data: totalReclaimableCores.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                        },
                        {
                            name: "Non-reclaimable Cores",
                            data: nonReclaimableCores?.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                            anomalies: activeTotalCoresAnomalies,
                        },
                        {
                            name: "Consumed Cores",
                            data: consumedCores?.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                            anomalies: activeTotalCoresAnomalies,
                        }
                    ]}
                    isNotCurrency
                    showArea
                    logProps={{
                        logComponent: LogComponent.AzureTabs,
                        logElement: LogElement.AzureCores,
                    }}
                    fillMissingAbnormalData
                    anomalies={activeTotalCoresAnomalies}
                    totalTextPrefix="Total Cores"
                />
            </div>
            <div>
                <LineChartContainer
                    title="Cores - Consumed VS. Idle (P99)"
                    isLoading={idleCores.isLoading || totalCores.isLoading}
                    series={[
                                {
                                    name: "Idle Cores",
                                    data: idleCores.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                                },
                                {
                                    name: "Consumed Cores",
                                    data: totalCores.data?.data.map((value, index) => ({date: value.date, metricValue: (value.metricValue || 0) -  (idleCores.data?.data[index].metricValue || 0)}))
                                    .map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                                },
                    ]}
                    anomalies={activeTotalCoresAnomalies}
                    fillMissingAbnormalData
                    isNotCurrency
                    showArea
                    logProps={{
                        logComponent: LogComponent.AzureTabs,
                        logElement: LogElement.AzureAcuConsumedVsIdle,
                    }}
                />
                <DefaultButton className={styles.linkButton} text="Drill-down by Region" onClick={() => {props.updateSelectedTab("RegionDetail"); props.setDefaultRegionDetails("idleCoresP99")}} />
            </div>
            <div hidden = {!isEmtpy}>
                <LineChartContainer
                    title="Reclaimable Cores Trends of TOP 5 Organizations"
                    isLoading={isTop5OrgReclaimableCoreLoading}
                    series={top5OrgReclaimableCore?.map(singleService => ({
                        name: singleService.name,
                        data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])
                    })) || []}
                    isNotCurrency
                    logProps={{
                        logComponent: LogComponent.AzureTabs,
                        logElement: LogElement.AzureReclaimableCoresTopFiveOrg,
                    }}
                    fillMissingAbnormalData
                    anomalies={activeTotalCoresAnomalies}
                />
            </div>
            <div>
                <LineChartContainer
                    title="Reclaimable Cores Trends of Top 5 Services"
                    isLoading={isTop5ServiceReclaimableCoreLoading}
                    series={top5ServiceReclaimableCore?.map(singleService => ({
                        name: singleService.name,
                        data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])
                    })) || []}
                    isNotCurrency
                    logProps={{
                        logComponent: LogComponent.AzureTabs,
                        logElement: LogElement.AzureReclaimableCoresTopFiveService,
                    }}
                    fillMissingAbnormalData
                    anomalies={activeTotalCoresAnomalies}
                />
                <DefaultButton className={styles.linkButton} text="View all services reclaimable Cores ranking" onClick={() => {props.updateSelectedTab("ServiceDetail"); props.setDefaultServiceDetails("reclaimableCores");}} />
            </div>
            <div className={styles.cardContainer} id={`FeedbackSection_TotalCoreTrendTop5`} >
                {flights?.enableUserFeedback &&
                    <IconButton
                        title="Send feedback"
                        className={styles.feedbackIcon}
                        iconProps={feedbackIconProps}
                        onClick={() => captureScreenshot("TotalCoreTrendTop5")}
                    />}
                <Separator styles={{root: styles.separator}} />
                <Stack horizontal horizontalAlign="space-between" styles={{root: styles.title}}>
                    <h4 className={styles.titleText}>Total Cores Trends of Top 5 Services</h4>
                    <Checkbox label="Show Spot VM" checked={showSpotVM} onChange={() => setShowSpotVM(!showSpotVM)} />
                </Stack>
                <div className={styles.chart}>
                    {
                        !isTop5ServiceTotalCoreLoading && top5ServiceTotalCore ?
                        <LineChart
                            series={top5ServiceTotalCore?.map(singleService => ({
                                name: singleService.name,
                                data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])
                            })) || []}
                            isNotCurrency
                            fillMissingAbnormalData
                            anomalies={activeTotalCoresAnomalies}
                        /> : <LoadingState />
                    }
                </div>
            </div>
        </div>
        <FeedbackPanel
            isPanelOpen={isFeedbackPanelOpen}
            onDismiss={handleDismissFeedbackPanel}
            userName={account?.username}
            currentURL={currentURL}
            screenshotInputURL={currentScreenshotURL}
            FeedbackTab={LogComponent.AzureTabs}
        />
        </>
    )
}

export default TopNReclaimableCoreChart;