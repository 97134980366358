/** Renders the confirmation page of the project form.
 * 
 */
import React from 'react';
import styles from "./Forms.less";

import { Stack } from '@fluentui/react/lib/Stack';
import { IEfficiencyProjectForm, IErrorDict, PlatformTypeEnum, resourceTypeUnitDictionary } from '../../../../../models/EfficiencyTracker';
import { Link, MessageBar, MessageBarType } from '@fluentui/react';
import { ComponentTypeEnum, CopsResourceTypeEnum } from '../../../../../models/EfficiencyTracker';
import { getComponent } from '../../../../EfficiencyTrackerV2/Components/Tools/ExportFunction';


interface IProps {
    formData: IEfficiencyProjectForm;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    errorDict: IErrorDict;
    confirmed: boolean;
    editMode: boolean;
}


const Confirmation = ({ formData, setActiveStep, errorDict, confirmed, editMode }: IProps) => {

    /** Format date to Month/Year */
    const formatDateMonthYear = (date: Date) => {
        return (
            `${date.toLocaleDateString("en-US", { month: "long" })}/${date.toLocaleDateString("en-US", { year: "numeric" })}`
        );
    }

    const renderFormItem = (label: string, value: string[] | string | null | undefined) => {
        if (value == null || value == undefined || value.length == 0) {
            return null;
        }

        return (
            <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center">
                <div className={styles.formBoldText}>{label}: </div>
                <div className={styles.formText}>{Array.isArray(value) ? value.join(", ") : value}</div>
            </Stack>
        );
    };

    const baremetalResourceInfoItem = () => {
        switch (formData.ResourceType) {
            case CopsResourceTypeEnum.IO:
                return (
                    <>
                        {renderFormItem("Resource type", formData.ResourceType)}
                        {renderFormItem("REST Application Names", getComponent(formData.RestApp, ComponentTypeEnum.RestApp))}
                    </>
                );
            case CopsResourceTypeEnum.CPU:
            case CopsResourceTypeEnum.Memory:
                return (
                    <>
                        {renderFormItem("Resource type", formData.ResourceType)}
                        {renderFormItem("REST Application Names", getComponent(formData.RestApp, ComponentTypeEnum.RestApp))}
                        {renderFormItem("Process Names", getComponent(formData.Process, ComponentTypeEnum.Process))}
                    </>
                );
            case CopsResourceTypeEnum.HDD:
            case CopsResourceTypeEnum.SSD:
                return (
                    <>
                        {renderFormItem("Resource type", formData.ResourceType)}
                        {renderFormItem("Storage Efficiency Target Category", formData.EfficiencyTargetCatagory)}
                        {renderFormItem("Tracking Gap", formData.TrackingGap > 0 ? "Yes" : "No")}
                        {renderFormItem("Shards", getComponent(formData.Shard, ComponentTypeEnum.Shard))}
                        {renderFormItem("Forest", getComponent(formData.Forest, ComponentTypeEnum.Forest))}
                        {renderFormItem("Dag Name", getComponent(formData.Dag, ComponentTypeEnum.Dag))}
                        {renderFormItem("Dag Configuration", getComponent(formData.DagConfiguration, ComponentTypeEnum.DagConfiguration))}
                        {renderFormItem("SKU", getComponent(formData.Sku, ComponentTypeEnum.Sku))}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {confirmed ? (
                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 554 } }} >
                    <div className={styles.formTitle}>{`${editMode? "Changes" : "Project"} submitted`}</div>
                    <div className={styles.formText}>Thank you for submitting your efficiency project. You can now track the targeted reductions compared to actuals</div>
                </Stack >
            ) : (
                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 808 } }} >

                    {"SubmitError" in errorDict &&
                        (<MessageBar
                            messageBarType={MessageBarType.error}
                        >
                            {errorDict.SubmitError}
                        </MessageBar>)
                    }

                    <div className={styles.formTitle}>{`Review and finish ${editMode? "editing" : ""} ${formData.ProjectName}`}</div>
                    <div className={styles.formText}>
                        Review the settings for your new efficiency track project.
                        The project will immediately be sent for review after you create it.
                    </div>

                    <Stack>
                        <div className={styles.formBoldText2}>Project Info</div>
                            {renderFormItem("Name", formData.ProjectName)}
                            {renderFormItem("Confidence level", formData.ConfidenceLevel)}
                            {renderFormItem("Description", formData.Description)}
                        <Link
                            styles={{ root: { width: 150 } }}
                            onClick={() => { setActiveStep(0); }}
                            underline>
                            Edit project info
                        </Link>
                    </Stack>

                    <Stack>
                            <div className={styles.formBoldText2}>Plan Info: Selected resource</div>
                            {formData.Platform === PlatformTypeEnum.Baremetal ? (<>
                                {baremetalResourceInfoItem()}
                            </>) : (<>
                                {renderFormItem("Resource type", formData.ResourceType)}
                                {renderFormItem("Platform", formData.Platform)}
                                {renderFormItem("ServiceId", formData.ServiceID)}
                                {renderFormItem("SubscriptionId", formData.SubscriptionId)}
                            </>)}
                        <Link
                            styles={{ root: { width: 150 } }}
                            onClick={() => { setActiveStep(1); }}
                            underline>
                            Edit resource info
                        </Link>
                    </Stack>

                    <Stack>
                        <div className={styles.formBoldText2}>Plan Info: Assigned regional plans</div>
                        <Stack tokens={{ childrenGap: 12 }}>
                            <div>
                                    {renderFormItem("Baseline month", formatDateMonthYear(formData.BaseLineTime))}
                                    {renderFormItem("Effective period", `${formData.EffectivePeriod} ${formData.EffectivePeriod === 1 ? 'month' : 'months'}`)}
                            </div>

                            {formData.RegionalTargets.map(regionTarget => (
                                <div key={regionTarget.Region}>
                                    {renderFormItem("Region", regionTarget.Region)}
                                    <Stack styles={{ root: { marginLeft: 10 } }}>
                                        {renderFormItem("Baseline value", regionTarget.BaseLineValue + resourceTypeUnitDictionary[formData.ResourceType])}
                                        {renderFormItem("Total target", regionTarget.TotalTarget != null ? ((regionTarget.TotalTarget * 100).toFixed(2) + "%") : null)}
                                        {renderFormItem("The value after optimization", regionTarget.TotalTarget != null ? ((parseFloat(regionTarget.BaseLineValue) * (1 - parseFloat(regionTarget.TotalTarget.toFixed(4)))).toFixed(2) + resourceTypeUnitDictionary[formData.ResourceType]) : null)}
                                        <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center">
                                            <div className={styles.formBoldText}>Monthly target: </div>
                                            {regionTarget.Targets.map((monthlyTarget, index) => (
                                                <div key={index} className={styles.formText}>
                                                    {`${formatDateMonthYear(monthlyTarget.Month)} (${(monthlyTarget.Value * 100).toFixed(2)}%) `}
                                                </div>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </div>
                            ))}

                        </Stack>
                        <Link
                            styles={{ root: { width: 150 } }}
                            onClick={() => { setActiveStep(2); }}
                            underline>
                            Edit regional plans
                        </Link>
                    </Stack>

                </Stack >
            )}
        </>
    );
}

export default Confirmation;