// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e2IbP2TMfxapATHEb3cQ.blue label {\n  color: #519DDB;\n}\n.e2IbP2TMfxapATHEb3cQ:not(.vvz95M072FFaMSdvl_RQ) label {\n  font-weight: 700;\n  color: #737373;\n}\n.e2IbP2TMfxapATHEb3cQ:not(.vvz95M072FFaMSdvl_RQ) label.vfZEQUXla1SotfdPlTmA {\n  font-weight: 600;\n  font-size: 14px;\n}\n.U4tjIoqZCx3toEZrOY52 {\n  font-size: 16px;\n  padding-right: 2px;\n}\n.SSX7gGoV6z8mG5d_2nWe {\n  padding: 5px;\n}\n._dVgVQwzAVkOK4nBwliW {\n  font-size: 14px;\n  font-weight: bolder;\n  padding-right: 5px;\n  color: #E30D0D;\n}\n.cb5441_8onbfVmrPCZwJ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 14px;\n  height: 14px;\n  border-radius: 50%;\n  background: transparent;\n  border: 1px solid #519DDB;\n  margin-right: 8px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTracker/Components/less/EFForLabel.less"],"names":[],"mappings":"AACC;EAEE,cAAA;AADH;AAKC;EAEE,gBAAA;EACA,cAAA;AAJH;AACC;EAOE,gBAAA;EACA,eAAA;AALH;AAUA;EACC,eAAA;EACA,kBAAA;AARD;AAWA;EACC,YAAA;AATD;AAaA;EACC,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AAXD;AAcA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;AAZD","sourcesContent":[".label {\n\t&:global(.blue) {\n\t\tlabel {\n\t\t\tcolor: #519DDB;\n\t\t}\n\t}\n\n\t&:not(.basic) {\n\t\tlabel {\n\t\t\tfont-weight: 700;\n\t\t\tcolor: #737373;\n\t\t}\n\n\t\tlabel.lightWeight {\n\t\t\tfont-weight: 600;\n\t\t\tfont-size: 14px;\n\t\t}\n\t}\n}\n\n.Label {\n\tfont-size: 16px;\n\tpadding-right: 2px;\n}\n\n.Description {\n\tpadding: 5px;\n}\n\n\n.required {\n\tfont-size: 14px;\n\tfont-weight: bolder;\n\tpadding-right: 5px;\n\tcolor: #E30D0D;\n}\n\n.iconStyle {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 14px;\n\theight: 14px;\n\tborder-radius: 50%;\n\tbackground: transparent;\n\tborder: 1px solid #519DDB;\n\tmargin-right: 8px;\n\tcursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "e2IbP2TMfxapATHEb3cQ",
	"basic": "vvz95M072FFaMSdvl_RQ",
	"lightWeight": "vfZEQUXla1SotfdPlTmA",
	"Label": "U4tjIoqZCx3toEZrOY52",
	"Description": "SSX7gGoV6z8mG5d_2nWe",
	"required": "_dVgVQwzAVkOK4nBwliW",
	"iconStyle": "cb5441_8onbfVmrPCZwJ"
};
export default ___CSS_LOADER_EXPORT___;
