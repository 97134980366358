import { Cell, Column, TableInstance, TableOptions, UseSortByInstanceProps, UseSortByOptions, useSortBy, useTable } from "react-table";
import { EfficiencyTrackerReductionPlanExtend, EfficiencyTrackerReductionPlanExtendDictionary, TrackerTablePlansKeys, resourceTypeUnitDictionary } from "../../../models/EfficiencyTracker";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import StickyTable, { IStickyTableCellOption } from "../../common/table/StickyTable";

import { SectionHeader } from "../../common/SectionHeader";
import { getHeaderOption } from "../../azure/detail/common/DetailTableUtils";
import styles from './less/ProjectTable.less';
import { formatValue } from "./DetailProjectTable";

interface Props {
    data: EfficiencyTrackerReductionPlanExtend[];
}

const getCellOptions = (cell: Cell): IStickyTableCellOption => {
    return {
        props: {
            key: cell.column.id,
            className: TrackerTablePlansKeys.find((item) => item === cell.column.id) ? '' : styles.textAlignRight,
        }
    }
};

const PlanTable: React.FC<Props> = ({ data: plans}) => {
    const [planData, setPlanData] = useState<EfficiencyTrackerReductionPlanExtend[]>(plans || []);
    useEffect(() => {
        if (!plans) return;
        setPlanData(plans);
    }, [plans]);

    const renderCell = useCallback((cell: Cell) => {
        const key = cell.column.id as keyof EfficiencyTrackerReductionPlanExtend;

        if (key === 'CreateTime' || key === 'BaseLineTime' || key === 'EffectivePeriod') {
            return cell.value ? formatValue(cell.value) : '-';
        } else if (key === 'BaseLineValue') {
            return formatValue(Number(cell.value)) + resourceTypeUnitDictionary[planData[cell.row.index].ResourceType];
        } else {
            return formatValue(cell.value)
        }
    }, [planData]);

    const columns: Column<EfficiencyTrackerReductionPlanExtend>[] = useMemo(() => {
        return TrackerTablePlansKeys.map((key, index) => ({
            id: key,
            accessor: key,
            sortType: 'basic',
            Header: EfficiencyTrackerReductionPlanExtendDictionary[key],
            Cell: renderCell,
            sortDescFirst: true,
        }));
    }, [planData]);

    const getRowId = useCallback((data: EfficiencyTrackerReductionPlanExtend) => {
        const keyParts = TrackerTablePlansKeys.map(key => data[key]).join('_');
        return `${keyParts}`;
    }, [TrackerTablePlansKeys]);

    const tableInstance = useTable({
        columns,
        data: planData,
        getRowId,
        hiddenColumns: [],
        initialState: {
            sortBy: [{
                id: 'Region',
                desc: false,
            }],
        },
        disableSortRemove: true,
    } as TableOptions<EfficiencyTrackerReductionPlanExtend> &
        UseSortByOptions<EfficiencyTrackerReductionPlanExtend>, useSortBy) as (TableInstance<EfficiencyTrackerReductionPlanExtend> &
            UseSortByInstanceProps<EfficiencyTrackerReductionPlanExtend>);

    return (
        <div className={styles.costTableView}>
            <SectionHeader
                className={styles.sectionHeader}
                title="All plans"
            />
            <StickyTable
                styles={{
                    container: styles.tableContainer,
                    bodyContainer: styles.tableBodyContainer,
                    footerContainer: styles.tableSectionContainer,
                    headerContainer: styles.tableSectionContainer,
                    stickyFooterContainer: styles.tableStickyFooterContainer,
                }}
                cellOption={getCellOptions}
                headerOption={getHeaderOption}
                loadMore={false}
                emptyText="You don't have any plans."
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: -40 }, footer: { offsetBottom: 0 } }}
            />
        </div>
    );
};

export default PlanTable;