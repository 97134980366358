import { ComponentTypeEnum } from "../models/EfficiencyTracker";
import {EfficiencyTrackerService} from "../services/EfficiencyTrackerService";
import {useCustomQuery, useCustomQueryWithRefetch, useCustomQueryWithRefetchSameQueryKey} from "./useCustomQuery";

export function useIEfficiencyTrackerProject(){
    const queryResult = useCustomQueryWithRefetch(EfficiencyTrackerService.getAllProjects);
    const plans = queryResult.data?.flatMap(response => response.EfficiencyTrackerReductionPlans) || [];
    const project = queryResult.data?.flatMap(response => response.EfficiencyTrackerProject) || [];
    return {
        ...queryResult,
        plans: plans,
        project: project,
        queryKey: queryResult.queryKey,
    };
}
export function useEfficiencyTrackerConsumptionDetails(planId: number, region: string){
    const queryResult = useCustomQuery(EfficiencyTrackerService.getTrendByPlanId, planId);
    const regionalData = queryResult.data ? queryResult.data[region] : [];
    const resultData = regionalData?.map((data: any) => {
        return {
            Month: new Date(data.month),
            Value: data.value,
        };
    });

    return {
        ...queryResult,
        data: resultData
    };
}

export function useAzureRegions() {
    const regions = useCustomQuery(EfficiencyTrackerService.getAzureRegions).data || [];
    return regions.sort() || [];
}

export function useServiceIDs() {
    return useCustomQuery(EfficiencyTrackerService.getServiceIDs).data || [];
}

export function useSubscriptionIDs() {
    return useCustomQuery(EfficiencyTrackerService.getSubscriptionIDs).data || [];
}

export function useComponents(componentType: ComponentTypeEnum) {
    return useCustomQuery(EfficiencyTrackerService.getComponents, componentType).data || [];
}

export function useGetPlanStatus(planId: number) {
    const queryResult = useCustomQuery(EfficiencyTrackerService.getPlanStatus, planId);
    const status = queryResult.data ? queryResult.data : false;
    return {
        ...queryResult,
        status: status,
    };
}
export function useEFTrackerBigBetsProject() {
    const queryResult = useCustomQueryWithRefetch(EfficiencyTrackerService.getAllBigBetsProjects);
    return {
        ...queryResult,
        projects: queryResult.data || [],
        queryKey: queryResult.queryKey,
    };
}

export function useEFTrackerBigBetsReductionResult(projectId: number) {
    const queryResult = useCustomQueryWithRefetch(EfficiencyTrackerService.getBigBetsReductionResult, projectId);
    return {
        ...queryResult,
        results: queryResult.data || [],
        queryKey: queryResult.queryKey,
    };
}
export function useEFTrackerBigBetsLatestByProjectId(projectIds: number[]) {
    const queryResult = useCustomQueryWithRefetchSameQueryKey(EfficiencyTrackerService.getBigBetsLatestByProjectID, projectIds);
    return {
        ...queryResult,
        results: queryResult.data || [],
        queryKey: queryResult.queryKey,
    };
}
export function useEFTrackerBigBetsDiscussion(projectId: number) {
    const queryResult = useCustomQueryWithRefetch(EfficiencyTrackerService.getBigBetsDiscussionByProjectId, projectId);
    return {
        ...queryResult,
        results: queryResult.data || [],
        queryKey: queryResult.queryKey,
    };
}

export function useEFTrackerBigBetsProjectHistory(projectId: number) {
    const queryResult = useCustomQueryWithRefetch(EfficiencyTrackerService.getBigBetsProjectHistory, projectId);
    return {
        ...queryResult,
        results: queryResult.data || [],
        queryKey: queryResult.queryKey,
    };
}