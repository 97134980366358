import { AnomalyTotalCostTypeEnum } from "../../../hooks/useSubstrateV2Query";
import { IAppState } from "../../../store";
import { Moment } from "moment";
import React from "react";
import { ServiceTreeItem } from "../../../models/serviceTree";
import commonStyles from "../../common/styles/Common.less";
import { currencyFormatter } from "../../../utils/currency";
import moment from "moment";
import { parseScenarioTag, sumCosts } from "../../../utils/PcmV2Utils";
import styles from "./InsightsCard.less";
import { useGetAppIdNameMap } from "../../../hooks/useSearchMetadataQuery";
import { useSelector } from "react-redux";
import { sum } from "lodash";
import { DefaultButton } from "@fluentui/react";

interface IInsightsCardProps {
    key: string,
    ring: string;
    workloadName: string;
    workloadType: string;
    serviceName: string;
    effectedPCMMetrics: string;
    startDate: Moment;
    endDate: Moment;
    onClick: () => void;
    itemReadTotalCost: number;
    itemWriteTotalCost: number;
    itemQueryTotalCost: number;
}

export function getWorkLoadNames(id: string, category: string, serviceIdMap: Map<string, ServiceTreeItem>, appIdNameMap: Map<string, string>) {
    if (category == "Service") {
        return serviceIdMap.get(id)?.n || id;
    } else if (category == "GriffinApplication") {
        return appIdNameMap.get(id);
    } else if (category == "GriffinProcessor" || category == "ScenarioTag") {
        const [appId, workloadName] = parseScenarioTag(id);
        const appName = appIdNameMap.get(appId);
        return `${workloadName}${appName ? `(${appName})` : ""}`;
    } else {
        return id;
    }
}

export const InsightsCard: React.FunctionComponent<IInsightsCardProps> = (props) => {
    const costByMetric = new Map
    ([
        ['ItemReads', props.itemReadTotalCost],
        ['ItemWrites', props.itemWriteTotalCost],
        ['ItemQueries', props.itemQueryTotalCost]
    ]);

    const sortedCostByMetric = new Map([...costByMetric.entries()].sort((a, b) => b[1] - a[1]));

    const sortedCostArray = Array.from(sortedCostByMetric, ([metric, value]) => ({ metric, value }));

    const totalIncreasedCost = sum(sortedCostArray.map(x => x.value > 0 ? x.value : 0));
    
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const appIdNameMap = useGetAppIdNameMap();
    return(
        <div className={styles.cardContainer}>
            <div className= {styles.cardWrapper}>
                <div style={{height:20, paddingTop:16, paddingLeft:16}}>
                    <button style={{backgroundColor:"#C8D1FA", color:"#242424", fontWeight:500, fontSize:9, border:"none", borderRadius:"3px", padding:"4px 3px 3px 4px", letterSpacing:1}}>INSIGHTS</button>
                    <button style={{backgroundColor:"#40A112", color:"white", fontWeight:500, fontSize:9, border:"none", borderRadius:"3px", padding:"4px 3px 3px 5px", marginLeft:16, letterSpacing:2}}>{props.ring}</button>
                </div>
                <div style={{height:257, paddingTop:4, paddingLeft:16, paddingRight:16, width:"100%"}}>
                    <span style={{height:28, fontSize:"20px", fontWeight:700}}>Cost increased by {currencyFormatter(totalIncreasedCost, 2, "$")}</span>
                    <div style={{width: "100%", paddingTop:16, wordBreak: "break-all"}}>
                            <div style={{width:"100%", height:"19px", paddingTop:0, paddingBottom:4}}>
                                <b style={{fontSize:"14px", fontWeight:600}}>Service details</b>                               
                            </div>
                            <div style={{width:"90%", height:"16px", paddingTop:0, paddingBottom:16}}>
                                <span style={{fontSize:"14px"}}>{getWorkLoadNames(props.serviceName, "Service", serviceIdMap, appIdNameMap)} - {getWorkLoadNames(props.workloadName, props.workloadType, serviceIdMap, appIdNameMap)}</span>
                            </div>
                            <div style={{width:"100%", height:"19px", paddingTop:0, paddingBottom:4}}>
                                <b style={{fontSize:"14px", fontWeight:600}}>Time frame</b>                            
                            </div>
                            <div style={{width:"100%", height:"16px", paddingTop:0, paddingBottom:16}}>
                                <span style={{fontSize:"14px"}}>{moment(props.startDate).format("MM/DD/YYYY")} - {moment(props.endDate).format("MM/DD/YYYY")}</span>
                            </div>
                            <div style={{width:"100%", height:"19px", paddingTop:0, paddingBottom:4}}>
                                <b style={{ fontSize:"14px", fontWeight:600}}>Details</b>                          
                            </div>
                            <div style={{width:"100%", height:"60px"}}>
                                <div hidden={!props.effectedPCMMetrics.includes(sortedCostArray[0].metric)} style={{fontSize:"14px"}}><span>{sortedCostArray[0].metric} monthly COGS anomaly {currencyFormatter(sortedCostArray[0].value, 2, "$")}. </span><br /></div>
                                <div hidden={!props.effectedPCMMetrics.includes(sortedCostArray[1].metric)} style={{fontSize:"14px"}}><span>{sortedCostArray[1].metric} monthly COGS anomaly {currencyFormatter(sortedCostArray[1].value, 2, "$")}. </span><br /></div>
                                <div hidden={!props.effectedPCMMetrics.includes(sortedCostArray[2].metric)} style={{fontSize:"14px"}}><span>{sortedCostArray[2].metric} monthly COGS anomaly {currencyFormatter(sortedCostArray[2].value, 2, "$")}. </span></div>
                            </div>
                    </div>
                </div>
                <div className={styles.cardFooter}>
                    <DefaultButton className={styles.viewInsight} onClick={props.onClick}>
                        View Insight
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
};