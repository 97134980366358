import { QueryableClass } from "../hooks/useCustomQuery";
import { ComponentItem, ComponentItemResponse } from "../models/serviceTree";

import { getJson } from "../utils/apiServiceBase";

@QueryableClass({ cacheTime: Number.MAX_SAFE_INTEGER })
export class SearchMetadataV2Service {
    static async getClientComponent() {
        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/clientcomponents"));
    }
    static async getScenarioTags() {
        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/scenariotags"));
    }
    static async getGriffinApps(enabled = false) {
        if (!enabled) {
            return new Map<string, ComponentItem[]>();
        }

        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/griffinapps"));
    }
    static async getGriffinProcessors() {
        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/griffinprocessors"));
    }
    static async getClients() {
        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/clients"));
    }

    static async getVdirs() {
        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/vdirs"));
    }

    static async getProcesses() {
        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/processes"));
    }

    static async getDataSets() {
        return SearchMetadataV2Service.convertToComponentItem(await getJson("api/pcm/searchprovider/v2.0/datasets"));
    }

    private static convertToComponentItem(response: ComponentItemResponse[]) {
        const result = new Map<string, ComponentItem[]>();

        response.forEach(item => {
            if (!result.has(item.n)) {
                result.set(item.n, []);
            }

            result.get(item.n)?.push({
                name: item.n,
                serviceName: item.sn,
                serviceGroupName: item.sgn,
                teamGroupName: item.tgn,
                organizationName: item.on,
                divisionName: item.dn,
            } as ComponentItem)
        })

        return result;
    }
}
