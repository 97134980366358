import {EfficiencyTargetCategoryEnum, TargetPair} from "../../../models/EfficiencyTracker";
import { LogComponent, LogElement } from '../../../models/LogModel';
import React, {useEffect, useState} from 'react';
import { LineChartContainer } from '../../azure/common/LineChartContainer';
import { useEfficiencyTrackerConsumptionDetails } from '../../../hooks/useEfficiencyTrackerProject';

interface LineChartProps {
    PlanId: number;
    Region: string;
    TargetTrend: TargetPair[];
    BaseLineValue: string;
    EfficiencyTargetCatagory: string;
    TrackingGap: string;
}

const LinearChart: React.FC<LineChartProps> = ({ PlanId, Region, TargetTrend, BaseLineValue, EfficiencyTargetCatagory, TrackingGap }) => {
    const { data: actualData, isLoading: isActualDataLoading } = useEfficiencyTrackerConsumptionDetails(PlanId, Region);
    const target = TargetTrend;
    const baselineValue = parseFloat(BaseLineValue) || 1;
    const [seriesData, setSeriesData] = useState<[number, number][]>([]);
    const [targetData, setTargetData] = useState<[number, number][]>([]);
    const catagoryFlag: number = EfficiencyTargetCatagory as EfficiencyTargetCategoryEnum === EfficiencyTargetCategoryEnum.Demands ? 1 : -1;
    const gapFlag: number = TrackingGap === 'Yes' ? 1 : 0;
    useEffect(() => {
        const cumulativeData = target.map((singleMetric, index) => {
            const cumulativeValue = target.slice(0, index + 1).reduce((total, metric) => total + metric.Value, 0);
            return [singleMetric.Month.valueOf(), cumulativeValue];
        });
        const multipliedData: [number, number][] = cumulativeData.map((pair) => {
            return [pair[0], ((1 - gapFlag) - pair[1] * catagoryFlag) * baselineValue];
        });
        setTargetData(multipliedData);
        console.log("Target:", multipliedData);
    }, [target, baselineValue]);

    useEffect(() => {
        console.log("isActualDataLoading:", isActualDataLoading);
        if (!actualData || isActualDataLoading || !actualData.length) return;
        if (!isActualDataLoading) {
            setSeriesData(actualData.map(singleMetric => [singleMetric.Month?.valueOf(), singleMetric.Value - gapFlag * baselineValue]));
            console.log("Actual:", actualData.map(singleMetric => [singleMetric.Month?.valueOf(), singleMetric.Value - gapFlag * baselineValue]));
        }
        
    }, [isActualDataLoading, PlanId, Region]);
    
    return (
        <LineChartContainer
            title="Value Trend"
            isLoading={isActualDataLoading}
            minValue={findMinSecondNumber(seriesData, targetData)}
            series={[
                {
                    name: "Actual Value",
                    data: seriesData
                },
                {
                    name: "Target",
                    data: targetData
                },
            ]}
            logProps={{
                logComponent: LogComponent.EffiencyTracker,
                logElement: LogElement.EffiencyTrackerChart,
            }}
            ignoreWeekend={false}
            isNotCurrency={true}
            dateFormatStr={"MMM Y"}
        />
    );
};

function findMinSecondNumber(data1: [number, number][], data2: [number, number][]): number {
    const secondNumbers1 = data1.map(tuple => tuple[1]);
    const secondNumbers2 = data2.map(tuple => tuple[1]);

    const allSecondNumbers = secondNumbers1.concat(secondNumbers2);

    const minSecondNumber = Math.min(...allSecondNumbers);

    return minSecondNumber;
}

export default LinearChart;
