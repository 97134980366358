// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D_O87MNR3ya0yvZ2FlLv {\n  column-count: 2;\n  column-gap: 2em;\n  display: flex;\n}\n.JN1aqk9E22HtjVm14ohT {\n  width: 500px;\n}\n.I8tuxCWqqquON2OO9R30 {\n  flex: 1;\n}\n.uCsP6BJVhb4O07efL93l {\n  display: flex;\n  width: 200px;\n  height: 200px;\n}\n.qPTbgnUohqnhRRQW05yA {\n  flex: 1 1 auto;\n  border-top: 4px solid #0078d4;\n  color: #323130;\n  display: flex;\n  align-items: center;\n  font-weight: bold;\n  padding: 12px 12px 14px 12px;\n}\n.SdlQQ944OMaV79ZUD4oR {\n  flex: 4 4 auto;\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n.kPkzvbdYt0Za_2mZ_CNA {\n  color: #323130;\n  margin-left: auto;\n  margin-top: 4px;\n  margin-right: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTracker/Components/less/ProjectRegionDetail.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;EACA,aAAA;AACJ;AACA;EACI,YAAA;AACJ;AACA;EACI,OAAA;AACJ;AAEA;EACI,aAAA;EACA,YAAA;EACA,aAAA;AAAJ;AAGA;EACI,cAAA;EACA,6BAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,4BAAA;AADJ;AAIA;EACI,cAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,cAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAHJ","sourcesContent":[".layout {\n    column-count: 2;\n    column-gap: 2em;\n    display: flex;\n}\n.right {\n    width: 500px\n}\n.left {\n    flex: 1;\n}\n\n.modalContainer {\n    display: flex;\n    width: 200px;\n    height: 200px;\n}\n\n.modalHeader {\n    flex: 1 1 auto;\n    border-top: 4px solid rgb(0, 120, 212);\n    color: rgb(50, 49, 48);\n    display: flex;\n    align-items: center;\n    font-weight: bold;\n    padding: 12px 12px 14px 12px;\n}\n\n.modalBody {\n    flex: 4 4 auto;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    overflow-y: auto;\n}\n\n.iconButton {\n    color: rgb(50, 49, 48);\n    margin-left: auto;\n    margin-top: 4px;\n    margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "D_O87MNR3ya0yvZ2FlLv",
	"right": "JN1aqk9E22HtjVm14ohT",
	"left": "I8tuxCWqqquON2OO9R30",
	"modalContainer": "uCsP6BJVhb4O07efL93l",
	"modalHeader": "qPTbgnUohqnhRRQW05yA",
	"modalBody": "SdlQQ944OMaV79ZUD4oR",
	"iconButton": "kPkzvbdYt0Za_2mZ_CNA"
};
export default ___CSS_LOADER_EXPORT___;
