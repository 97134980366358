export const enum SearchKey {
    Filters = "filters",
    FiltersView = "filtersView",
    ViewId = "viewId",
    TransactionTab = "transactionTab",
    SsdTab = "ssdTab",
    StartDate = "startDate",
    EndDate = "endDate",
    SingleDate = "singleDate",
    FiscalYear = "fiscalYear",
    SelectionMode = "selectionMode",
    Region = "region",
    Rings = "rings",
    TimeView = "timeView",
    ESOrg = "esOrg",
    ESCostUsage = "esCostUsage",
    ESCostResource = "esCostResource",
    ESOverviewTimeRange = "esOverviewTimeRange",
    ESManagers = "esManagers",
    ESUsageScenarios = "esUsageScenarios",
    ESArtifactDataType = "esArtifactDataType",
    ShowRemap = "showRemap",

    UseBillingCost = "useBillingCost",
    InsightId = "insightId",
}

export const enum TimeView {
    Daily = "Daily",
    Weekly = "Weekly",
}