import styles from './Table.less';

import React, { useEffect, useState } from "react";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";

import { EfficiencyTargetCategoryEnum, TargetPair } from "../../../../models/EfficiencyTracker";
import { formatValue } from "../Tools/ExportFunction";
import MonthTableComponent from "../Tools/MonthTableComponent";
import { currencyFormatter } from "../../../../utils/currency";



interface MonthlyDataItem {
    Year: string;
    Months: { [month: string]: MonthlyComponent };
}

interface MonthlyComponent {
    Target: number;
    Current: number;
}

const maxWidthMonth = 125;

const columns: IColumn[] = [
    {
        key: 'year', name: 'Fiscal year', minWidth: 50, maxWidth: 70, isResizable: true, className: styles.PrimaryColumn,
    },
    {
        key: '0', name: 'January', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '1', name: 'February', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '2', name: 'March', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '3', name: 'April', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '4', name: 'May', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '5', name: 'June', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '6', name: 'July', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '7', name: 'August', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '8', name: 'September', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '9', name: 'October', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '10', name: 'November', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '11', name: 'December', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
];

interface MonthlyRegionDetailsTableProps {
    Isloading: boolean;
    ConsumptionData: TargetPair[];
    TargetTrend: TargetPair[];
    BaseLineTime: Date;
    BaseLineValue: string;
    EfficiencyTargetCatagory: string;
}

const MonthlyRegionDetailsTable: React.FC<MonthlyRegionDetailsTableProps> = ({ Isloading, ConsumptionData, TargetTrend, BaseLineTime, BaseLineValue, EfficiencyTargetCatagory }) => {
    const baselineValue = parseFloat(BaseLineValue) || 1;
    const [monthItem, setMonthItem] = useState<MonthlyDataItem[]>([]);

    const catagoryFlag: number = EfficiencyTargetCatagory as EfficiencyTargetCategoryEnum === EfficiencyTargetCategoryEnum.Demands ? 1 : -1;

    useEffect(() => {
        if (Isloading) return;
        const cumulativeData:TargetPair[] = TargetTrend.map((singleMetric, index) => {
            const cumulativeValue = TargetTrend.slice(0, index + 1).reduce((total, metric) => total + metric.Value, 0);
            return {
                Month: singleMetric.Month,
                Value: (1 - cumulativeValue * catagoryFlag) * baselineValue
            }
        });

        const addBaselineData: TargetPair[] = [
            { Month: BaseLineTime, Value: baselineValue},
            ...cumulativeData,
        ];

        let addBaselineConsumptionData: TargetPair[];
        if (!ConsumptionData) {
            addBaselineConsumptionData = [{ Month: BaseLineTime, Value: baselineValue }];
        } else {
            addBaselineConsumptionData = [
                { Month: BaseLineTime, Value: baselineValue },
                ...ConsumptionData,
            ];
        }

        const monthItemTmp = calculateMonthlyDifferences(addBaselineData, addBaselineConsumptionData);
        setMonthItem(monthItemTmp);
    }, [Isloading]);

    function _renderItemColumn(item: MonthlyDataItem, index?: number, column?: IColumn): React.ReactNode {
        const year = item.Year;
        const months = item.Months;

        switch (column?.key) {
            case "year":
                return <span>{formatValue(year)}</span>;
        }
        if (months[column?.key as string]) {
            const component = months[column?.key as string];
            const gap = component.Current - component.Target;
            const gapTrend = 100 * gap / component.Target;
            return <MonthTableComponent
                trendFlag={gap > 0}
                gap={currencyFormatter(gap, 2, (gap > 0 ? "+" : ""))}
                gapTrend={currencyFormatter(gapTrend, 2, (gap > 0 ? "+" : "")) + "%"}
                target={currencyFormatter(component.Target, 2, "")}
                current={currencyFormatter(component.Current, 2, "")}
            />
        }
    }

    return (
        <div>
            <DetailsList
                items={monthItem}
                setKey="set"
                compact={true}
                columns={columns}
                onRenderItemColumn={_renderItemColumn}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionZoneProps={{
                    disableAutoSelectOnInputElements: true,
                    isSelectedOnFocus: false
                }}
            />
        </div>
    );
}

function getLastOfMonthValues(arr: TargetPair[]): Map<string, TargetPair> { //arr should be asc
    const lastOfMonthValues = new Map<string, TargetPair>();

    for (const pair of arr) {
        const monthYear = pair.Month.getFullYear() + '-' + pair.Month.getMonth();
        lastOfMonthValues.set(monthYear, pair);
    }

    return lastOfMonthValues;
}

function calculateMonthlyDifferences(targetpairs: TargetPair[], currentPairs: TargetPair[]): MonthlyDataItem[] {
    const monthlyData: MonthlyDataItem[] = [];
    const lastOfMonthValues = getLastOfMonthValues(currentPairs);

    const dataByYear: { [year: string]: MonthlyDataItem } = {};

    for (const pair1 of targetpairs) {
        const year = pair1.Month.getFullYear().toString();
        const month = pair1.Month.getMonth();
        const monthYear = year + '-' + month;

        if (!dataByYear[year]) {
            dataByYear[year] = {
                Year: year,
                Months: {},
            };
        }

        if (lastOfMonthValues.has(monthYear)) {
            const pair2 = lastOfMonthValues.get(monthYear)!;
            dataByYear[year].Months[month.toString()] = { Target: pair1.Value, Current: pair2.Value };
        } else {
            dataByYear[year].Months[month.toString()] = { Target: pair1.Value, Current: pair1.Value };
        }
    }

    const sortedYears = Object.keys(dataByYear).sort((a, b) => parseInt(b) - parseInt(a));
    for (const year of sortedYears) {
        monthlyData.push(dataByYear[year]);
    }

    return monthlyData;
}


export default MonthlyRegionDetailsTable;