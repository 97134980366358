import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { IconButton, IIconProps } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { IStackTokens, Stack } from "@fluentui/react";
import html2canvas from "html2canvas";

import CostCard, { CostCardProps } from "../../common/costCard/CostCard";
import LineChart, { LineChartProps } from "../../common/LineChart";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import useEventBus from "../../../hooks/useEventBus";
import { EventTypeEnum, OpenFeedbackPanelEvent } from "../../../models/Event";
import LoadingState from "../../ResponseBoundary/LoadingState";
import { Section } from "../../common/Section";
import styles from "../Azure.less";
import { useTrackHovering } from "../../../hooks/useTrack";
import { WarningBanner } from "../../banner/WarningBanner";
import FeedbackPanel from "../../feedback/FeedbackPanel";
import { useFlights } from "../../../hooks/useSettings";
import { trackEventCallback } from "../../../utils/AppInsights";

const cardTokens: IStackTokens = {childrenGap: 24};
const feedbackIconProps: IIconProps = { iconName: "Feedback" };

interface ILineChartContainerProps extends LineChartProps {
    isLoading: boolean;
    title: string;
    cardProps?: CostCardProps[];
    logProps: {
        logElement: LogElement;
        logComponent: LogComponent;
        displayName?: string;
        logTarget?: LogTarget;
        customProperties?: ICustomProperties;
    }
}

export const LineChartContainer: React.FC<ILineChartContainerProps> = (props) => {
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const trackChartHoveringProps = useTrackHovering(props.logProps?.logComponent, props.logProps?.logElement, props.logProps?.displayName || props.title, LogTarget.Chart, props.logProps?.customProperties);

    const captureScreenshot = async () => {
        trackEventCallback(props.logProps.logComponent, props.logProps.logElement, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${props.title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);
        }

    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    useEventBus<OpenFeedbackPanelEvent>(EventTypeEnum.OpenFeedbackPanel, openFeedbackPanel, []);

    return (
        <div className={styles.lineChartContainer} id={`FeedbackSection_${props.title}`}>
            {flights?.enableUserFeedback &&
                <IconButton
                    title="Send feedback"
                    className={styles.feedbackIcon}
                    iconProps={feedbackIconProps}
                    onClick={captureScreenshot}
                />
            }
            <Section headerProps={{ title: props.title }} loading={props.isLoading}>
                {props.banner && <WarningBanner bannerItem={props.banner} />}
                {
                    props.cardProps &&
                    <Stack className={styles.cardSection} horizontal tokens={cardTokens}>
                        {props.cardProps.map(cardProp => (
                            <CostCard key={cardProp.title} {...cardProp} />
                        ))}
                    </Stack>
                }
                <div className={styles.chart}>
                    {
                        !props.isLoading ?
                        <LineChart
                            {...props}
                            containerProps={{
                                ...trackChartHoveringProps,
                                ...props.containerProps,
                            }}
                        /> :
                        <LoadingState />
                    }
                </div>
            </Section>
            <FeedbackPanel
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                screenshotInputURL={currentScreenshotURL}
                FeedbackTab={props.logProps?.logComponent}
                />
        </div>
    );
}