import React, { useEffect, useState } from 'react';
import BigBetsProjectTable from '../../../Components/BigBets/Table/BigBetsProjectTable';
import { Pages } from '../../../../../models/Nav';
import BigBetsPageWrapper from '../../BigBetsPageWrapper';

interface ILandingPageView {
    IsMyProject: boolean;
}

const BigBetsLandingPageView: React.FC<ILandingPageView> = (props) => {
    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    const [projectRange, setProjectRange] = useState<string>("All project");
    const [projectUrl, setProjectUrl] = useState<Pages>(Pages.AllProjects);

    useEffect(() => {
        if (props.IsMyProject) {
            setProjectRange("My project");
            setProjectUrl(Pages.MyProjects);
        }
        else {
            setProjectRange("All project");
            setProjectUrl(Pages.AllProjects);
        }
    }, [props.IsMyProject]);
    
    const breadcrumbItems = [
        { key: 'projectRange', text: `${projectRange}`, link: `${Pages.EfficiencyTrackerV2}/${projectUrl}` },
    ];

    return (
        <BigBetsPageWrapper title={"Big Bets Efficiency Tracker"} breadcrumbItems={breadcrumbItems}>
            <div>
                <BigBetsProjectTable IsMyProject={props.IsMyProject} />
            </div>
        </BigBetsPageWrapper>
    );
};

export default BigBetsLandingPageView;
