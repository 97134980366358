import React, { useEffect } from 'react';
import PageWrapper from '../PageWrapper';
import ProjectTable from '../../Components/ProjectTable';

const ETAllProjectView: React.FC = () => {
    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    return (
        <PageWrapper
            title="My Projects"
            description="View all projects."
        >
            <div>
                <ProjectTable ></ProjectTable>
            </div>
        </PageWrapper>
    );
};

export default ETAllProjectView;
