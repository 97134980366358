import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DetailProjectTable from '../../Components/DetailProjectTable';
import { IEfficiencyTrackerProject } from '../../../../models/EfficiencyTracker';
import PageWrapper from '../PageWrapper';
import { useIEfficiencyTrackerProject } from '../../../../hooks/useEfficiencyTrackerProject';

const ETSubProjectView: React.FC = () => {
    const { projectId } = useParams();
    const { plans: plans, project: project, isLoading, isError } = useIEfficiencyTrackerProject();
    const [subProject, setSubProject] = useState<IEfficiencyTrackerProject>();
    const id: number = parseInt(projectId || "0", 10);

    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    useEffect(() => {
        if (!isLoading) {
            const a = project.find(item => item.Id === id) || project[0];
            const b = plans.find(item => item.ProjectId == a?.Id);
            const iEfficiencyTrackerProject: IEfficiencyTrackerProject = {
                EfficiencyTrackerProject: a,
                EfficiencyTrackerReductionPlans: b ? [b] : [],
            }
            setSubProject(iEfficiencyTrackerProject); 
        }
    }, [id, isLoading]);
   
    return (
        <PageWrapper
            title={"Project : " + (subProject?.EfficiencyTrackerProject.Name || "Sub Project")}
            description={"Show project details & plans."}
        >
            <div>
                {(subProject !== undefined) &&
                    <DetailProjectTable data={subProject} id={id}></DetailProjectTable>
                }
            </div>
        </PageWrapper>
    );
};

export default ETSubProjectView;
