import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper';
import ProjectDetailsTable from '../../Components/Table/ProjectDetailsTable';
import { PlatformTypeEnum } from '../../../../models/EfficiencyTracker';
import { Pages } from '../../../../models/Nav';

interface ILandingPageView {
    IsBaremental: boolean;
    IsMyProject: boolean;
}

const LandingPageView: React.FC<ILandingPageView> = (props) => {
    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    const [platform, setPlatform] = useState<string[]>([PlatformTypeEnum.Baremetal]);
    const [title, setTitle] = useState<string>("Baremetal Efficiency Tracker");
    const [projectRange, setProjectRange] = useState<string>("All project");
    const [projectUrl, setProjectUrl] = useState<Pages>(Pages.AllProjects);
    const [platformUrl, setPlatformUrl] = useState<Pages>(Pages.BaremetalTracker);

    useEffect(() => {
        if (props.IsBaremental) {
            setPlatform([PlatformTypeEnum.Baremetal]);
            setTitle("Baremetal Efficiency Tracker");
            setPlatformUrl(Pages.BaremetalTracker);
        }
        else {
            setPlatform([PlatformTypeEnum.AzSC, PlatformTypeEnum.COSMIC, PlatformTypeEnum.Public])
            setTitle("Virtualized Efficiency Tracker");
            setPlatformUrl(Pages.VirtualizedTracker);
        }
        if (props.IsMyProject) {
            setProjectRange("My project");
            setProjectUrl(Pages.MyProjects);
        }
        else {
            setProjectRange("All project");
            setProjectUrl(Pages.AllProjects);
        }
    }, [props.IsBaremental, props.IsMyProject]);
    
    const breadcrumbItems = [
        { key: 'projectRange', text: `${projectRange}`, link: `${Pages.EfficiencyTrackerV2}/${projectUrl}/${platformUrl}` },
    ];

    return (
        <PageWrapper title={title} breadcrumbItems={breadcrumbItems}>
            <div>
                <ProjectDetailsTable IsMyProject={props.IsMyProject} Platform={platform} />
            </div>
        </PageWrapper>
    );
};

export default LandingPageView;
