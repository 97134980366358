/** Models of interfaces and enum used by efficiency tracker Big bets */

/** The complete form used by backend */

/** Basic information for Big Bets efficiency project */
export interface EFTrackerBigBetsProject {
    ProjectID: number; //Auto add by BE when add
    Name: string;
    Owner: string; //Auto add by BE when add
    CreateTime: Date; //Auto add by BE when add
    LastModifyTime: Date; //Auto add by BE when add
    Category: string;
    Workload: string;
    DRI: string;
    ImpactDollar: number;
    ConfidenceInImpact: string;
    ExecutionStatus: string;
    DeliveryETA: Date;
    Notes: string;
    LinksToExistingTicketItem: string;
    Metrics: string; //BigBetsMetrics
}

export interface EFTrackerBigBetsProjectHistory {
    Id: number;
    ProjectID: number; //Auto add by BE when add
    Name: string;
    Owner: string; //Auto add by BE when add
    CreateTime: Date; //Auto add by BE when add
    LastModifyTime: Date; //Auto add by BE when add
    Category: string;
    Workload: string;
    DRI: string;
    ImpactDollar: number;
    ConfidenceInImpact: string;
    ExecutionStatus: string;
    DeliveryETA: Date;
    Notes: string;
    LinksToExistingTicketItem: string;
    Metrics: string; //BigBetsMetrics
}

export interface EFTrackerBigBetsReductionResult {
    Id: number; //Auto add by BE when add
    ProjectID: number;
    CreateTime: Date;
    DateValuePairs: BigBetsDatePair[];
}

export interface EFTrackerBigBetsDiscussion {
    Id: number; //Auto add by BE when add
    ProjectID: number;
    CreateTime: Date; //Auto add by BE when add
    Owner: string; //Auto add by BE when add
    Note: string;
}

export interface BigBetsDatePair {
    PairDate: Date;
    Target: number | null;
    Actual: number | null;
}

export interface EFTrackerBigBetsLatestByProject {
    ProjectID: number;
    LatestActual: number;
    LastTarget: number; //Last history target
    Discussion: EFTrackerBigBetsDiscussion;
}

export interface BigBetsMetrics {
    CVPSponsor: string;
    GEMGPM: string;
}

/** The complete form used by frontend */

/* ImpactDollar digit */ 
export const bigBetsDigit = 4;

/** Category Enum used to identify the actual Category of a Big-bets project*/
export enum CategoryEnum {
    Compute = "Compute",
    Storage = "Storage",
    CosmosDB = "CosmosDB",
    Azure = "Azure",
    Baremetal = "Baremetal",
    Bandwidth = "Bandwidth",
    MAPpipeline = "MAP pipeline",
    SecurityAzure = "Security(Azure)",
}

/** Basic information for Big Bets efficiency project Input */
export interface EFTrackerBigBetsProjectForm {
    ProjectId: number;
    Name: string;
    Owner: string;
    CreateTime: Date; //Auto add by BE when add
    LastModifyTime: Date; //Auto add by BE when add
    Category: string;
    Workload: string;
    DriName: string;
    DriContact: string;
    ImpactDollar: number;
    Confidence: string;
    Status: string;
    ETA: Date;
    Notes: string;
    LinkToTicket: string;
    CVPSponsor: string;
    GEMGPM: string;
    DateValuePairs: BigBetsDatePair[];
}
export interface AddEFTrackerBigBetsProjectRequest {
    Project: EFTrackerBigBetsProject;
    ReductionResult: EFTrackerBigBetsReductionResultForm;
}
export interface EFTrackerBigBetsReductionResultForm {
    Id: number; //Auto add by BE when add
    ProjectID: number;
    CreateTime: Date;
    DateValuePairs: string; //BigBetsDatePair[]
}