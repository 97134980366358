import * as React from 'react';
import { RichText } from '@chatui/core';
import { TableDataFrame } from './tabulate';
import Plot, { PlotParams } from 'react-plotly.js';
import { ICodeExecuteResult, ICodeResult } from './ChatModels';
import { DefaultButton, TooltipHost } from '@fluentui/react';

interface ICodeInterpreterComponentProps {
    code_md: string;
    code_html: string;
    result?: ICodeExecuteResult;
    finished: boolean;
}

function CodeInterpreterComponent(props: ICodeInterpreterComponentProps) {
    const code_md = props.code_md
    const code_html = props.code_html
    const result = props.result
    const finished = props.finished
    const status: ['success' | 'error' | 'info' | 'warning', string] = result ? (result.data ? ['success', 'Completed (click for more info)']: finished ? ['error', 'Failed (click for more info)'] : ['warning', 'ReQuerying ... (click for more info)']) : ['info', 'Running ... (click for more info)']

    return (
        <div>
            <ExecutionPanel
                status = {status[1]}
                color = {status[0]}
                code = {code_html}
                result = {code_md.includes('```python') ? undefined : (result?.data as ICodeResult)}
            />
            {
                result && code_md.includes('```python') && !code_md.includes('```python_data_analysis') && result.data &&
                (
                  result.data.constructor === Array ?
                  result.data.map((item, i) => <Plot
                      key={i}
                      data={item.data}
                      layout={{...item.layout, width: 600}}
                  />)
                  :
                  <Plot
                      data={((result.data as PlotParams).data)}
                      layout={{...(result.data as PlotParams).layout, width: 600}}
                  />
                )
            }
        </div>
    )
}

interface IExecutionPanelProps {
    status: string;
    color: 'success' | 'error' | 'info' | 'warning';
    code: string;
    result?: ICodeResult;
}

function ExecutionPanel(props: IExecutionPanelProps) {
    const status = props.status
    const color = props.color
    const code = props.code
    const result = props.result
  
    return (
      <TooltipHost
        content={<>
        <RichText content = {code} />
        {
            result && result.columns &&
            <TableDataFrame columns={result.columns} rows={result.data} total_rows={(result.data as ((string | number)[][])).length} />
        }
        </>}
      >
        <DefaultButton color={color}>{status}</DefaultButton>
      </TooltipHost>
    );
  }

export default CodeInterpreterComponent;