import { Component, ComponentTypeEnum, CopsResourceTypeEnum, EfficiencyTrackerProject, EfficiencyTrackerReductionPlan, EfficiencyTrackerReductionPlanExtend, EfficiencyTrackerReductionPlanRegionConfig, IEfficiencyTrackerProject, PlanRegionConfigTable, TargetPair, TrackerEditShowKeys, resourceTypeUnitDictionary } from '../../../../models/EfficiencyTracker';
import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper';
import { DefaultButton, Icon, PrimaryButton } from '@fluentui/react';
import { postMethod } from '../../../../utils/apiServiceBase';
import styles from "../Page.less";
import { useComponents, useIEfficiencyTrackerProject, } from "../../../../hooks/useEfficiencyTrackerProject";
import { useNavigate, useParams } from 'react-router-dom';
import { Pages } from '../../../../models/Nav';
import { formatValue, mapPlansToExtend } from '../../Components/DetailProjectTable';
import TableComponent from '../../Components/TableComponent';
import { formatNumber } from '../../../../utils/currency';
import { useMsal } from '@azure/msal-react';
import { EFFormLabel } from '../../Components/EFFormLabel';
import { AsyncPaginate } from 'react-select-async-paginate';
import { OptionType, getLoadOptionsAll } from './SelectUtils';
import { EfficiencyTrackerService } from '../../../../services/EfficiencyTrackerService';
import { generateTargetPairs } from '../../Components/AddRegionTable';
import { MultiValue } from 'react-select';

function mapEditToEfficiencyTrackerConfig(planConfigTable: PlanRegionConfigTable[], plansExtend0: EfficiencyTrackerReductionPlanExtend): EfficiencyTrackerReductionPlanRegionConfig[] {
    return planConfigTable.map((planConfig) => {
        const config: EfficiencyTrackerReductionPlanRegionConfig = {
            Region: planConfig.Region,
            Targets: JSON.stringify(planConfig.Targets),
            BaseLineTime: plansExtend0.BaseLineTime,
            BaseLineValue: planConfig.Current.toString(),
            EffectivePeriod: plansExtend0.EffectivePeriod,
        };
        return config;
    });
}

const ETEditProjectView: React.FC = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const { projectId } = useParams();
    const monthOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit' };
    const { plans: plans, project: project, isLoading } = useIEfficiencyTrackerProject();

    const [editProject, setEditProject] = useState<IEfficiencyTrackerProject>();
    const id: number = parseInt(projectId || "0", 10);
    const [projectData, setProjectData] = useState<{ key: string, value: string }[]>();
    const [plansData, setPlansData] = useState<{ key: string, value: string }[]>();
    const [planExtends, setPlanExtends] = useState<EfficiencyTrackerReductionPlanExtend[]>();
    const [reset, setReset] = useState<boolean>(false);
    const [editData, setEditData] = useState<PlanRegionConfigTable[]>([]);
    const [comsumptionUnit, setcomsumptionUnit] = useState<string>("RW Osp/Sec");
    const [resourceType, setResourceType] = useState<CopsResourceTypeEnum>(CopsResourceTypeEnum.CPU);
    const [selectedComponents, setSelectedComponents] = useState<Component[]>([]);
    const [effectivePeriod, setEffectivePeriod] = useState<number>(1);
    const [createTime, setCreateTime] = useState<Date>(new Date());

    const [newTarget, setNewTarget] = useState<number>(0);
    const [totalConsumption, setTotalConsumption] = useState<number>(0);
    const [regionSelectedOption, setRegionSelectedOption] = useState<Component[]>();

    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    const handleSubmit = () => {
        // input validation
        if (!account) {
            alert('Please login!');
            return;
        }
        if (editProject=== undefined || planExtends === undefined) {
            alert('Don\'t have this project!');
            return;
        }
        if (account.username !== editProject.EfficiencyTrackerProject.Owner) {
            alert('Please Contact Onwer To Edit the Project!');
            return;
        }
        if (editData.length === 0 ) {
            alert('At least contains one region!');
            return;
        }

        let flag = false;
        editData.forEach((planConfig) => {
            if (isNaN(planConfig.totalTarget) || planConfig.totalTarget <= 0 || planConfig.totalTarget >= 100) {
                alert('Please input a valid target between 0 and 100');
                flag = true;
                return;
            }
            let total = 0;
            planConfig.Targets.forEach((target) => {
                total += target.Value;
            });
            total = Number(total.toFixed(4));
            if (total >= (planConfig.totalTarget * 1.0 / 100) + 0.0001 || total < (planConfig.totalTarget * 1.0 / 100)) {
                const message = `Sum of monthly target value should not exceed total target! Please adjust your target value for region ${planConfig.Region}, input total target is ${planConfig.totalTarget}% while your monthly attribution sum is ${(total * 100).toFixed(2)}`;
                alert(message);
                flag = true;
                return;
            }
        });

        if (flag) {
            return;
        }

        const efficiencyTrackerProject: EfficiencyTrackerProject = {
            Id: editProject.EfficiencyTrackerProject.Id,
            Name: editProject.EfficiencyTrackerProject.Name,
            Owner: editProject.EfficiencyTrackerProject.Owner,
            CreateTime: editProject.EfficiencyTrackerProject.CreateTime,
            Description: editProject.EfficiencyTrackerProject.Description,
        };
        const EfficiencyTrackerReductionPlanRegionConfigs: EfficiencyTrackerReductionPlanRegionConfig[] = mapEditToEfficiencyTrackerConfig(editData, planExtends[0]);

        const plan0 = editProject.EfficiencyTrackerReductionPlans[0];
        const component: Component[] = plan0.Components as unknown as Component[];
        const efficiencyTrackerReductionPlans: EfficiencyTrackerReductionPlan = {
            Id: plan0.Id,
            ProjectId: plan0.ProjectId,
            Target: plan0.Target,
            EfficiencyTrackerReductionPlanRegionConfigs: JSON.stringify(EfficiencyTrackerReductionPlanRegionConfigs),
            Components: JSON.stringify(component),
            Status: plan0.Status,
            ConfidenceLevel: plan0.ConfidenceLevel,
            QualityForMap: plan0.QualityForMap,
            Platform: plan0.Platform,
            Owner: plan0.Owner,
            CreateTime: plan0.CreateTime,
            ResourceType: plan0.ResourceType,
            EfficiencyTargetCatagory: plan0.EfficiencyTargetCatagory,
            TrackingGap: plan0.TrackingGap,
        };
        const iEfficiencyTrackerProject: IEfficiencyTrackerProject = {
            EfficiencyTrackerProject: efficiencyTrackerProject,
            EfficiencyTrackerReductionPlans: [efficiencyTrackerReductionPlans],
        }

        console.log("iEfficiencyTrackerProject:", iEfficiencyTrackerProject);

        postMethod<IEfficiencyTrackerProject>('api/efficiencytracker/edit', iEfficiencyTrackerProject)
            .then((response) => {
                console.log(response.text);
                if (response.ok) {
                    alert('Edit request uploaded successfully!');
                    navigate(`${Pages.EfficiencyTracker}/${Pages.EFSubPage}/${id}`);
                    window.location.reload();
                } else {
                    alert('Edit request upload failed!');
                }
            })
            .catch((error) => {
                alert('Error when uploading Edit request!' + error);
            });
    }

    const handleCancel = () => {
        navigate(`${Pages.EfficiencyTracker}/${Pages.EFSubPage}/${id}`);
    }

    useEffect(() => {
        if (!editProject) {
            return;
        }
        const projectDataTmp = Object.entries(editProject.EfficiencyTrackerProject)
            .filter(([key]) => key !== 'Id')
            .map(([key, value]) => ({
                key,
                value: formatValue(value),
            }));
        setProjectData(projectDataTmp);
        const plansExtendTmp = mapPlansToExtend(editProject.EfficiencyTrackerReductionPlans);
        setPlanExtends(plansExtendTmp);
        if (plansExtendTmp.length) {
            const plansExtend0 = plansExtendTmp[0];
            console.log("planExtend0:", plansExtend0);
            const plansDataTmp = Object.entries(plansExtend0).map(([key, value]) => ({
                key,
                value: formatValue(value),
            })).filter(item => item.value !== 'undefined');

            setPlansData(plansDataTmp
                .filter(item => TrackerEditShowKeys.includes(item.key as keyof EfficiencyTrackerReductionPlanExtend))
                .sort((a, b) => TrackerEditShowKeys.indexOf(a.key as keyof EfficiencyTrackerReductionPlanExtend) - TrackerEditShowKeys.indexOf(b.key as keyof EfficiencyTrackerReductionPlanExtend))
            );
            setcomsumptionUnit(resourceTypeUnitDictionary[plansExtend0.ResourceType]);
            setResourceType(plansExtend0.ResourceType);
            setEffectivePeriod((plansExtend0.Targets as unknown as TargetPair[]).length);
            setCreateTime(plansExtend0.CreateTime);
            setSelectedComponents(editProject.EfficiencyTrackerReductionPlans[0].Components as unknown as Component[]);
        }
    }, [editProject]);

    useEffect(() => {
        console.log("planExtends:", planExtends);
        if (!planExtends) {
            return;
        }
        setEditData(convertRegionData(planExtends));
    }, [planExtends]);

    useEffect(() => {
        if (!isLoading) {
            const a = project.find(item => item.Id === id) || project[0];
            const b = plans.find(item => item.ProjectId == a?.Id);
            const iEfficiencyTrackerProject: IEfficiencyTrackerProject = {
                EfficiencyTrackerProject: a,
                EfficiencyTrackerReductionPlans: b ? [b] : [],
            }
            setEditProject(iEfficiencyTrackerProject);
        }
    }, [id, isLoading]);

    useEffect(() => {
        if (!regionSelectedOption || regionSelectedOption.length === 0 || selectedComponents.length === 0) {
            setTotalConsumption(0);
            return;
        }
        console.log("regionSelectedOption:", regionSelectedOption);
        const requestBody = selectedComponents.concat(regionSelectedOption);
        const utcTimestamp = createTime.getTime() - createTime.getTimezoneOffset() * 60 * 1000;
        const today = new Date(utcTimestamp);
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getDate() - 7);
        EfficiencyTrackerService.getConsumption(oneWeekAgo, resourceType, requestBody).then((res) => {
            setTotalConsumption(parseFloat(res.toFixed(2)));
        })
            .catch((error) => {
                console.log("Get Consumption error:", error);
                setTotalConsumption(0);
            });
    }, [regionSelectedOption, selectedComponents]);

    const DistinctAndMapToSelectOptions = React.useCallback(function (items: string[]) {
        const distinctItems = items;
        return distinctItems.map((item) => {
            return { value: item, label: item };
        });
    }, []);

    const regionDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Region));

    const addRow = async () => {
        if (!regionSelectedOption) {
            alert('please input vaild Region');
            return;
        }
        if (totalConsumption === 0) {
            alert('This condition doesn\'t require optimization');
            return;
        }
        if (isNaN(newTarget) || newTarget <= 0 || newTarget >= 100) {
            alert('Please input a valid target between 0 and 100');
            return;
        }
        const newRows: PlanRegionConfigTable[] = [];
        const utcTimestamp = createTime.getTime() - createTime.getTimezoneOffset() * 60 * 1000;
        const today = new Date(utcTimestamp);
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getDate() - 7);

        for (const item of regionSelectedOption) {
            const region = item.Value;

            if (editData.some(row => row.Region === region)) {
                alert(`Region ${region} already exists. Please use a different Region.`);
                continue;
            }

            const requestBody = selectedComponents.concat({ Type: ComponentTypeEnum.Region, Value: region });

            try {
                const res = await EfficiencyTrackerService.getConsumption(oneWeekAgo, resourceType, requestBody);
                if (res === 0) {
                    alert(`Region ${region} doesn\'t require optimization.`);
                    continue;
                }
                const consumption = parseFloat(res.toFixed(2));

                const newRow: PlanRegionConfigTable = {
                    Region: item.Value,
                    Current: consumption,
                    Targets: generateTargetPairs(effectivePeriod, newTarget / 100),
                    totalTarget: newTarget,
                };

                newRows.push(newRow);
            } catch (error) {
                console.log("Get Consumption error:", error);
                alert(`Add region ${region} failed, please try again.`);
                return;
            }
        }
        const sortedEditData = [...editData, ...newRows].sort((a, b) => {
            if (a.Region < b.Region) {
                return -1;
            }
            if (a.Region > b.Region) {
                return 1;
            }
            return 0;
        });
        setEditData(sortedEditData);
    };

    const deleteRow = (index: number, event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const newData = editData.filter((_, i) => i !== index);
        setEditData(newData);
    };

    const handleRegionDropdownChange = (newValue: MultiValue<OptionType>) => {
        if (newValue.find((option) => option.value === "all")) {
            const multivalue: Component[] = regionDropDownList.map(option => ({
                Type: ComponentTypeEnum.Region,
                Value: option.value,
            }))
            setRegionSelectedOption(multivalue);
        } else {
            const multivalue: Component[] = newValue.map(option => ({
                Type: ComponentTypeEnum.Region,
                Value: option.value,
            }))
            setRegionSelectedOption(multivalue);
        }
    };

    const changeMonthlyAttribution = (month: Date, target: number, row: PlanRegionConfigTable) => {
        const rowTmp = { ...row };

        rowTmp.Targets = rowTmp.Targets.map(pair => {
            if (pair.Month.toDateString() === month.toDateString()) {
                return { ...pair, Value: Number((target * 1.0 / 100).toFixed(4)) };
            }
            return pair;
        });

        console.log(rowTmp);

        setEditData(editData.map(item => (item.Region === row.Region ? rowTmp : item)));
    };

    const changeTotalAttribution = (target: number, row: PlanRegionConfigTable) => {
        const updatedRow = { ...row, totalTarget: target };
        console.log(updatedRow);

        setEditData(editData.map(item => (item.Region === row.Region ? updatedRow : item)));
    };

    const handleReset = () => {
        setReset(true);
        setEditData([]);
    }

    useEffect(() => {
        console.log("reset:", reset);
        if (!planExtends || !reset) {
            return;
        }
        setEditData(convertRegionData(planExtends));
        setReset(false);
    }, [reset]);

    return (
        <PageWrapper
            title="Edit Project"
            description="Edit existing project."
        >
            <div>
                <h2>Project Details</h2>
                {(projectData !== undefined) && (
                    <TableComponent data={projectData}></TableComponent>
                )}
                <h2>All Plans</h2>
                {plansData === undefined ? (
                    <div> This project has no plans.</div>
                ) : (
                    <TableComponent data={plansData}></TableComponent>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>Edit Region Details</h2>

                    <PrimaryButton onClick={handleReset} className={`${styles.resetButton}`}>
                        <Icon iconName="DisableUpdates" style={{ marginRight: '4px' }} />Reset
                    </PrimaryButton>
                </div>
                <div className={styles.labelInputContainer}>
                    <EFFormLabel
                        label={"Region"}
                        required
                        fieldDescription={"If multiple regions are selected, the current value is the weighted average value for reference only."}
                    />
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        isMulti
                        name="Region"
                        placeholder="Select Region"
                        loadOptions={getLoadOptionsAll(regionDropDownList)}
                        onChange={handleRegionDropdownChange}
                        styles={{
                            multiValue: (base) => ({ ...base, maxWidth: 150 }),
                            container: (base) => ({ ...base, minWidth: 180 }),
                            menuList: (base) => ({ ...base, maxHeight: 300 }),
                        }}
                    />
                </div>
                <div className={styles.labelInputContainer}>
                    <EFFormLabel
                        label={"Current Value"}
                        fieldDescription={"Resource Consumption Value for the Selected Components in the Recent Tuesday!"}
                    />
                    <input
                        type="text"
                        placeholder="0"
                        value={formatNumber(totalConsumption) + comsumptionUnit}
                        readOnly
                        className={`${styles.NotfullWidthInput}`}
                    />
                </div>
                <div className={styles.labelInputContainer}>
                    <EFFormLabel
                        label={"Reduction Percentage Target(%)"}
                        required
                        fieldDescription={"Optimization Reduction Percentage Target"}
                    />
                    <input
                        type="text"
                        placeholder="Target(%)"
                        defaultValue={newTarget}
                        onChange={(e) => setNewTarget(parseFloat(e.target.value))}
                        className={`${styles.NotfullWidthInput}`}
                    />
                    <div className={styles.AddRegionTable}>
                        <button onClick={addRow} >Add</button>
                    </div>
                </div>
                <div className={styles.divBlank}></div>
                <div className={styles.AddRegionTable}>
                    <table>
                        <thead>
                            <tr>
                                <th>Region</th>
                                <th>Baseline {comsumptionUnit}</th>
                                <th>Total Percentage Target</th>
                                <th>Monthly Percentage Target</th>
                                <th>Operation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {editData.map((row, index) => (
                                <tr key={row.Region + index}>
                                    <td>{row.Region}</td>
                                    <td>{formatNumber(row.Current)}{comsumptionUnit}</td>
                                    <td>
                                        <input className={styles.monthlyInput} defaultValue={Number(row.totalTarget).toFixed(2)} onChange={(e => changeTotalAttribution(Number(e.target.value), row))} /><span>%</span>
                                    </td>
                                    <td>
                                        <ul>
                                            {row.Targets.map((target, targetIndex) => (
                                                <li key={targetIndex}>
                                                    <>
                                                        <span className={styles.DateSpan}>{target.Month.toLocaleDateString('en-US', monthOptions)}:</span>
                                                        <input className={styles.monthlyInput} defaultValue={Number(target.Value * 100).toFixed(2)} onChange={(e => changeMonthlyAttribution(target.Month, Number(e.target.value), row))} /><span>%</span>
                                                    </>
                                                </li>
                                            ))}
                                        </ul>
                                    </td>

                                    <td>
                                        <a
                                            href="#"
                                            onClick={(e) => deleteRow(index, e)}
                                            style={{ color: "#519DDB", fontSize: "14px" }}
                                        >
                                            <Icon iconName="Delete" style={{ color: "#519DDB", fontSize: "14px" }} /> Delete
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                </div>
                <div className={styles.divBlank}></div>
                <div className={styles.divBlank}></div>
                <PrimaryButton onClick={handleSubmit} className={`${styles.submitButton}`}>Submit</PrimaryButton>
                <DefaultButton onClick={handleCancel} className={`${styles.submitButton}`}>Cancel</DefaultButton>
                <div className={styles.divWideBlank}></div>
            </div>
        </PageWrapper>
    );
};

function convertRegionData(plans: EfficiencyTrackerReductionPlanExtend[]): PlanRegionConfigTable[] {
    const data: PlanRegionConfigTable[] = [];
    plans.map((plan, index) => {
        const targets: TargetPair[] = plan.Targets as unknown as TargetPair[];
        const total = targets.reduce((accumulator, target) => {
            return accumulator + target.Value;
        }, 0);
        const tableItem: PlanRegionConfigTable = {
            Region: plan.Region,
            Current: plan.BaseLineValue as unknown as number,
            Targets: targets,
            totalTarget: parseFloat((total*100).toFixed(2)) ,
        } 
        data.push(tableItem);
        console.log("tableItem:", tableItem);
    })
    return data;
}

export default ETEditProjectView;