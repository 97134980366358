import React from "react";
import styles from "./TrackerBanner.less";
import { Stack, Icon, IStackTokens, IStackStyles } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";

const innerStackTokens: IStackTokens = { childrenGap: 5 };

const innerStackStyles: IStackStyles = {
    root: {
        alignItems: "stretch",
        height: "100%",
    }
}

const TrackerBanner: React.FC = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    return (
        <Stack horizontal horizontalAlign="space-between" className={styles.banner}>
            <Stack.Item className={styles.item}>
                <Icon iconName="LineChart" className={styles.logo} />
                <h2>Jaws Efficiency Tracker</h2>
            </Stack.Item>
            <Stack horizontal tokens={innerStackTokens} styles={innerStackStyles}>
                <div className={styles.centerDiv}>{"Hello," + account?.username}</div>
            </Stack>
        </Stack>
    );
};

export default TrackerBanner;