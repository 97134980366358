import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { CosmosMetricEnum, CosmosSourceTypeEnum, ICosmosCost } from "../models/CosmosModels";
import { IDailyMetricReport } from "../models/Metric";
import { ServiceTreeItem } from "../models/serviceTree";
import { batchGetCosmosDailyMetric, getCosmosCostByGem, getCosmosCostByServices, getCosmosDailyMetrics } from "../services/cosmosService";
import { IAppState } from "../store";
import { Endpoints } from "../utils/Constants";
import { useDateRange } from "./useDateSelector";
import { useCategoryFilters } from "./useFilters";
import { getCosmicDailyCost, getTopNCosmicDailyMetricsByWorkload } from "../services/cosmicService";
import { ICosmicDailyMetrics, ICosmicDailyMetricsByWorkload } from "../models/CosmicModels";

export function useGetCosmicDailyMetricsByWorkload(metric: string, n:number) {
    const range = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const query = useQuery<
        ICosmicDailyMetricsByWorkload[],
        Error,
        ICosmicDailyMetricsByWorkload[],
        Parameters<typeof getTopNCosmicDailyMetricsByWorkload>['0']['queryKey']
    >([Endpoints.GetCosmicDailyMetricsByWorkload, range.startDate, range.endDate, filtersData.filters, serviceIdMap, n, metric], getTopNCosmicDailyMetricsByWorkload);
    const result : { [key: string]: ICosmicDailyMetricsByWorkload[] } = {};
    if(query.data) {
        for(const item in query.data) {
            if(!result[query.data[item].workload]) {
                result[query.data[item].workload] = [];
                result[query.data[item].workload].push(query.data[item])
            } else {
                result[query.data[item].workload].push(query.data[item])
            }
        }
    }
    
    return {
        cosmicDailyMetrics: result,
        isLoading: query.isLoading
    }
}

export function useGetCosmicDailyCost() {
    const range = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const query = useQuery<
        ICosmicDailyMetrics[],
        Error,
        ICosmicDailyMetrics[],
        Parameters<typeof getCosmicDailyCost>['0']['queryKey']
    >([Endpoints.GetCosmicDailyCost, range.startDate, range.endDate, filtersData.filters, serviceIdMap], getCosmicDailyCost);
    return {
        cosmicDailyCost: query.data || [],
        isLoading: query.isLoading
    }
}