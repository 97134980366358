/** Renders the form for adding regions and periods for a project:
 * - Delivery ETA
 * - Impact In Dollar
 * - Date value pairs
 */
import React, { useEffect, useState } from 'react';
import styles from "./Forms.less";

import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Updater, useImmer } from 'use-immer';
import { Label } from '@fluentui/react/lib/Label';
import { IconButton, MessageBar, MessageBarType, DetailsList, SelectionMode, IColumn, DatePicker, TextField } from '@fluentui/react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { AddProjectTypeEnum, IErrorDict } from '../../../../../../models/EfficiencyTracker';
import { BigBetsDatePair, EFTrackerBigBetsProjectForm, bigBetsDigit } from '../../../../../../models/EfficiencyTrackerBigBets';
import moment from 'moment';
import { CompensateLocalDateToUTC, formatValue } from '../../../../Components/Tools/ExportFunction';

interface IProps {
    formData: EFTrackerBigBetsProjectForm;
    updateFormData: Updater<EFTrackerBigBetsProjectForm>;
    errorDict: IErrorDict;
    updateErrorDict: Updater<IErrorDict>;
    mode: AddProjectTypeEnum;
}

const reductionWidth = 450;

const BigBetsReductionPlan = ({ formData, updateFormData, errorDict, updateErrorDict, mode }: IProps) => {
    /** Define the columns for the reduction list */
    const DateValuePariListColumns: IColumn[] = [
        {
            styles: { cellName: { fontSize: 12, fontStyle: "normal" } },
            key: 'DateColumn',
            name: 'Date',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: BigBetsDatePair) => (
                <div className={styles.formBoldText}>{dateFormatter(item.PairDate)}</div>
            ),
            isSorted: true,
            isSortedDescending: false,
        },
        {
            styles: { cellName: { fontSize: 12, fontStyle: "normal" } },
            key: 'TargetValueColumn',
            name: 'Target($)',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: BigBetsDatePair) => (
                formatValue(item.Target, bigBetsDigit)
            ),
            isResizable: true,
        },
        {
            styles: { cellName: { fontSize: 12, fontStyle: "normal" } },
            key: 'ActualValueColumn',
            name: 'Actual($)',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: BigBetsDatePair) => (
                formatValue(item.Actual, bigBetsDigit)
            ),
            isResizable: true,
        },
        {
            key: 'EditMenuColumn',
            name: '',
            minWidth: 25,
            maxWidth: 25,
            onRender: (item: BigBetsDatePair, index) => (
                <IconButton
                    iconProps={{ iconName: 'MoreVertical' }}
                    menuProps={{
                        items: [
                            {
                                key: 'editPair',
                                text: 'Edit Pair',
                                iconProps: { iconName: 'Edit' },
                                onClick() {
                                    if (index !== undefined && index > -1) {
                                        // Set data in panel to curent region plan
                                        setCurDatePair(item);
                                        setCurTargetString(item.Target !== null ? item.Target.toString() : "");
                                        setCurActualString(item.Actual !== null ? item.Actual.toString() : "");
                                        //Turn on edit mode and open panel
                                        setCurEditIndex(index);
                                        openPanel();
                                    }
                                },
                            },
                            {
                                key: 'deletePair',
                                text: 'Delete Pair',
                                iconProps: { iconName: 'Delete' },
                                onClick() {
                                    if (index !== undefined && index > -1) {
                                        updateFormData(formData => {
                                            formData.DateValuePairs.splice(index, 1)
                                        })
                                    }

                                },
                            },
                        ],
                    }}
                    // Menu Icon is on by default, use this to hide it
                    onRenderMenuIcon={() => (<></>)}
                />
            ),
        },
    ]

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [curEditIndex, setCurEditIndex] = useState<number>(-1);
    const [curImpactDollarString, setCurImpactDollarString] = useState<string>(formatValue(formData.ImpactDollar, bigBetsDigit));
    const [curTargetString, setCurTargetString] = useState<string>("");
    const [curActualString, setCurActualString] = useState<string>("");
    const [curDatePair, setCurDatePair] = useImmer<BigBetsDatePair>({
            PairDate : new Date(),
            Target : null,
            Actual : null,
    });
    const [localDatePairs, setLocalDatePairs] = useState<BigBetsDatePair[]>(formData.DateValuePairs);

    //Get Columns
    const [newColumns, setnewColumns] = useState<IColumn[]>(DateValuePariListColumns || []);

    const [isloading, { setTrue: loading, setFalse: LoadDone }] = useBoolean(false);

    /** Remove the suffix or any other text after the numbers, or return undefined if not a number */
    const getNumericPart = (value: string): number | undefined => {
        //Delete ","
        const cleanedValue = value.replace(/,/g, '');

        const valueRegex = /^(\d+(\.\d+)?).*/;
        if (valueRegex.test(cleanedValue)) {
            const numericValue = Number(cleanedValue.replace(valueRegex, '$1'));
            return isNaN(numericValue) ? undefined : numericValue;
        }
        return undefined;
    };

    const addNewPairs = () => {
        if (formData.ETA === undefined) {
            updateErrorDict(errorDict => {
                errorDict.AddProjectDetailsError = "Delivery ETA for this project is not specified!"
            })
            return;
        }
        updateErrorDict(errorDict => {
            if ("AddProjectDetailsError" in errorDict) {
                delete errorDict.AddProjectDetailsError;
            }
        });
        openPanel();
    }

    const saveNewPairs = () => {
        if (curDatePair.Target == null && curDatePair.Actual == null) {
            updateErrorDict(errorDict => {
                errorDict.DateValuesError = `Must assign one of the values.`
            });
            return;
        }
        const existingDate = new Set(formData.DateValuePairs.map(item => (
            item.PairDate.getTime()
        )))

        if (existingDate.has(curDatePair.PairDate.getTime())) {
            updateErrorDict(errorDict => {
                errorDict.DateError = `Date "${dateFormatter(curDatePair.PairDate)}" already exists! Please select other Date.`
            });
            return;
        }

        // Disable the save button and show spinner
        loading();

        //Sort all Pairs
        updateFormData(formData => {
            const tmpPairs = formData.DateValuePairs;
            tmpPairs.push(curDatePair);

            formData.DateValuePairs = tmpPairs.sort((a, b) => {
                if (a.PairDate < b.PairDate) return -1;
                if (a.PairDate > b.PairDate) return 1;
                return 0;
            });
        })

        // Enable the save button and hide spinner
        LoadDone();
        dismissPanel();
    }

    const savePairEdit = () => {
        if (curDatePair.Target == null && curDatePair.Actual == null) {
            updateErrorDict(errorDict => {
                errorDict.DateValuesError = `Must assign one of the values.`
            });
            return;
        }
        const clonepairs = [...localDatePairs];
        clonepairs.splice(curEditIndex, 1);

        const existingDate = new Set(clonepairs.map(item => (
            item.PairDate.getTime()
        )));

        if (existingDate.has(curDatePair.PairDate.getTime())) {
            updateErrorDict(errorDict => {
                errorDict.DateError = `Date "${dateFormatter(curDatePair.PairDate)}" already exists! Please select other Date.`
            });
            return;
        }

        updateFormData(formData => {
            const tmpPairs = [...localDatePairs];
            tmpPairs[curEditIndex] = curDatePair;

            formData.DateValuePairs = tmpPairs.sort((a, b) => {
                if (a.PairDate < b.PairDate) return -1;
                if (a.PairDate > b.PairDate) return 1;
                return 0;
            });
        })
        dismissPanelAndEditOff();
    }

    const dismissPanelAndEditOff = () => {
        dismissPanel();
        setCurEditIndex(-1);
    }

    //ETA
    const dateFormatter = React.useCallback((date?: Date) => {
        return moment(date).format("MM/DD/YYYY");
    }, []);


    const onChangeETA = React.useCallback((date?: Date | null) => {
        if (date) {
            date.setHours(0, 0, 0, 0);

            updateFormData(formData => {
                formData.ETA = date;
            })
        }
    }, []);

    const onChangePairDate = React.useCallback((date?: Date | null) => {
        if (date) {
            date.setHours(0, 0, 0, 0);
            setCurDatePair(pair => {
                pair.PairDate = CompensateLocalDateToUTC(date);
            })
        }
    }, []);

    // Set List for Pairs asc
    useEffect(() => {
        if (formData.DateValuePairs) {
            const sortedPairs = [...formData.DateValuePairs].sort((a, b) =>
                new Date(a.PairDate).getTime() - new Date(b.PairDate).getTime()
            );
            setLocalDatePairs(sortedPairs);
        }
    }, [formData.DateValuePairs]);

    const _onColumnClick = (event?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        let sortedItems = [...localDatePairs];
        if (!column || !column.key || column.key !== 'DateColumn') return;
        let isSortedDescending = column.isSortedDescending;

        // If sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(sortedItems, column.key, isSortedDescending);

        setLocalDatePairs(sortedItems);
        
        setnewColumns(DateValuePariListColumns.map((col: IColumn) => {
            const newCol = { ...col };
            newCol.isSorted = newCol.key === column.key;

            if (newCol.isSorted) {
                newCol.isSortedDescending = isSortedDescending;
            }

            return newCol;
        }));
    };

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;

        return [...items].sort((a: T, b: T) => {
            return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
        });
    }

    return (
        <>
            <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: reductionWidth } }} >

                <div className={styles.formTitle}>{`${mode === AddProjectTypeEnum.EditProject ? "Edit" : "Add"} details for ${formData.Name}`}</div>


                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: reductionWidth } }}>

                    <div>
                        <Stack horizontal verticalAlign="center">
                            <Label required styles={{ root: { paddingRight: 0 } }}>Delivery ETA</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="The delivery ETA of this project, will be set to local time 00:00."
                                className={styles.ExplainIcon}
                            />
                            
                        </Stack>
                        <DatePicker
                            formatDate={dateFormatter}
                            highlightSelectedMonth
                            value={formData.ETA}
                            onSelectDate={onChangeETA}
                            minDate={new Date()}
                            styles={{ root: { width: 200 } }}
                            onFocus={
                                () => {
                                    updateErrorDict(errorDict => {
                                        if ("AddProjectDetailsError" in errorDict) {
                                            delete errorDict.AddProjectDetailsError;
                                        }
                                    });
                                }
                            }
                        />
                    </div>

                    <TextField
                        styles={{ root: { width: 200 } }}
                        onRenderLabel={() => {
                            return (
                                <Stack horizontal verticalAlign="center">
                                    <Label styles={{ root: { paddingRight: 0 } }} required>Impact($)</Label>
                                    <IconButton
                                        iconProps={{ iconName: 'Info' }}
                                        title="Total impact of your project($)"
                                        className={styles.ExplainIcon}
                                    />
                                </Stack>
                            );
                        }}
                        value={curImpactDollarString}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("ImpactDollarError" in errorDict) {
                                    delete errorDict.ImpactDollarError;
                                }
                            });
                        }}
                        onChange={(_, newValue) => {
                            setCurImpactDollarString(newValue || "");
                        }}
                        onBlur={() => {
                            const numericValue = getNumericPart(curImpactDollarString ? curImpactDollarString : "");
                            if (numericValue !== undefined) {
                                const fixedNumber = Number(numericValue.toFixed(bigBetsDigit));
                                updateFormData(formData => {
                                    formData.ImpactDollar = fixedNumber;
                                });
                                setCurImpactDollarString(formatValue(fixedNumber, bigBetsDigit));
                            }
                            else {
                                setCurImpactDollarString("");
                            }
                        }}
                        errorMessage={("ImpactDollarError" in errorDict) ? errorDict.ImpactDollarError : ""}
                    />

                    <div>
                        <Label required>Project Details</Label>
                        <CommandBar
                            styles={{ root: { padding: 0 } }}
                            items={[
                                {
                                    key: 'addProjectDetails',
                                    text: 'Add detals',
                                    iconProps: { iconName: 'Add' },
                                    onClick: addNewPairs
                                },
                            ]}
                        />
                    </div>

                </Stack>
            </Stack>

            {"AddProjectDetailsError" in errorDict &&
                <Stack styles={{ root: { width: 300 } }}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                    >
                        {errorDict.AddProjectDetailsError}
                    </MessageBar>
                </Stack>
            }

            {localDatePairs.length !== 0 && (
                <Stack styles={{ root: { width: reductionWidth } }}>
                    <DetailsList
                        selectionMode={SelectionMode.none}
                        items={localDatePairs}
                        columns={newColumns}
                        onColumnHeaderClick={_onColumnClick}
                    />
                </Stack>
            )}

            <Panel
                type={PanelType.medium}
                isOpen={isOpen}
                onDismiss={dismissPanelAndEditOff}
                onRenderFooterContent={() => (
                    <Stack horizontal verticalAlign="center">
                        {curEditIndex > -1 ? <PrimaryButton onClick={savePairEdit} styles={{ root: { marginRight: 8 } }} text="Save changes" /> :
                            <PrimaryButton onClick={saveNewPairs} styles={{ root: { marginRight: 8 } }} disabled={isloading} text="Save pair" />
                        }
                        <DefaultButton onClick={dismissPanelAndEditOff} styles={{ root: { marginRight: 10 } }} disabled={isloading} text="Cancel" />
                        {isloading && <Spinner label="Loading..." labelPosition="right" />}
                    </Stack>
                )}
                isFooterAtBottom={true}
            >
                <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 300 } }}>

                    <div className={styles.regionPanelTitle}>{curEditIndex > -1 ? "Edit" : "Add"} Pairs</div>
                    <Stack horizontal verticalAlign="center">
                        <Label required styles={{ root: { paddingRight: 0 } }}>Date</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="The Date of this pair"
                            className={styles.ExplainIcon}
                        />
                    </Stack>
                    <DatePicker
                        formatDate={dateFormatter}
                        highlightSelectedMonth
                        value={curDatePair.PairDate}
                        onSelectDate={onChangePairDate}
                        maxDate={formData.ETA}
                        onFocus={
                            () => {
                                updateErrorDict(errorDict => {
                                    if ("DateError" in errorDict) {
                                        delete errorDict.DateError;
                                    }
                                });
                            }
                        }
                    />

                    {"DateError" in errorDict && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            {errorDict.DateError}
                        </MessageBar>
                    )}

                    <div className={styles.separator} />
                    <div className={styles.regionPanelSubTitle}>Target VS Actual Value</div>

                    <div>
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { minWidth: 150 } }}>Target($)</Label>
                            <Label styles={{ root: { minWidth: 150 } }}>Actual($)</Label>
                        </Stack>

                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
                            <Stack styles={{ root: { minWidth: 150 } }}>
                                <TextField
                                    placeholder="Target value($)"
                                    value={curTargetString}
                                    onChange={(_, newValue) => {
                                        setCurTargetString(newValue || "");
                                    }}
                                    onBlur={() => {
                                        const numericValue = getNumericPart(curTargetString ? curTargetString : "");
                                        if (numericValue !== undefined) {
                                            const fixedNumber = Number(numericValue.toFixed(bigBetsDigit));
                                            setCurDatePair(pair => {
                                                pair.Target = fixedNumber;
                                            })
                                            setCurTargetString(formatValue(fixedNumber, bigBetsDigit));
                                        }
                                        else {
                                            setCurTargetString("");
                                            setCurDatePair(pair => {
                                                pair.Target = null;
                                            })
                                        }
                                    }}
                                    onFocus={
                                        () => {
                                            updateErrorDict(errorDict => {
                                                if ("DateValuesError" in errorDict) {
                                                    delete errorDict.DateValuesError;
                                                }
                                            });
                                        }
                                    }
                                />
                            </Stack>
                            <Stack styles={{ root: { minWidth: 150 } }}>
                                <TextField
                                    placeholder="Actual value($)"
                                    value={curActualString}
                                    onChange={(_, newValue) => {
                                        if (newValue !== undefined) {
                                            setCurActualString(newValue);
                                        }
                                    }}
                                    onBlur={() => {
                                        const numericValue = getNumericPart(curActualString ? curActualString : "");
                                        if (numericValue !== undefined) {
                                            const fixedNumber = Number(numericValue.toFixed(bigBetsDigit));
                                            setCurDatePair(pair => {
                                                pair.Actual = fixedNumber;
                                            })
                                            setCurActualString(formatValue(fixedNumber, bigBetsDigit));
                                        }
                                        else {
                                            setCurActualString("");
                                            setCurDatePair(pair => {
                                                pair.Actual = null;
                                            })
                                        }
                                    }}
                                    onFocus={
                                        () => {
                                            updateErrorDict(errorDict => {
                                                if ("DateValuesError" in errorDict) {
                                                    delete errorDict.DateValuesError;
                                                }
                                            });
                                        }
                                    }
                                />
                            </Stack>
                        </Stack>
                    </div>

                    {"DateValuesError" in errorDict && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            {errorDict.DateValuesError}
                        </MessageBar>
                    )}
                </Stack>
            </Panel>
        </>
    );
}

export default BigBetsReductionPlan;