/** Renders the form for creating a new efficiency project, including:
 * - Project name
 * - Confidence level
 * - Description
 */
import React from 'react';
import styles from "./Forms.less";

import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { AddProjectTypeEnum, IEfficiencyProjectForm, IErrorDict } from '../../../../../models/EfficiencyTracker';
import { Updater } from 'use-immer';
import { Label } from '@fluentui/react/lib/Label';
import { IconButton } from '@fluentui/react';

interface IProps {
    formData: IEfficiencyProjectForm;
    updateFormData: Updater<IEfficiencyProjectForm>;
    errorDict: IErrorDict;
    updateErrorDict: Updater<IErrorDict>;
    mode: AddProjectTypeEnum;
}

const titleDictionary: Record<AddProjectTypeEnum, string> = {
    [AddProjectTypeEnum.AddProject]: "New Efficiency Project",
    [AddProjectTypeEnum.AddPlan]: "New Efficiency Plan",
    [AddProjectTypeEnum.EditProject]: "Edit Efficiency Project",
};


const NewProjectInfo = ({ formData, updateFormData, errorDict, updateErrorDict, mode }: IProps) => {
    const disableEdit = mode === AddProjectTypeEnum.AddPlan;
    return (
        <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 554 } }} >

            <div className={styles.formTitle}>{titleDictionary[mode]}</div>

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Project name</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Name for your efficiency project"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="New project"
                value={formData.ProjectName}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("ProjectNameError" in errorDict) {
                            delete errorDict.ProjectNameError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.ProjectName = newValue ? newValue : "";
                    });
                }}
                errorMessage={("ProjectNameError" in errorDict) ? errorDict.ProjectNameError : ""}
                disabled={disableEdit}
            />

            <Dropdown
                placeholder="Select a confidence level"
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Confidence level</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Describe your confidence on this project"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                selectedKey={formData.ConfidenceLevel}
                options={[
                    { key: 'Low', text: 'Low', title: 'May change the plan later' },
                    { key: 'Medium', text: 'Medium', title: 'I can do it' },
                    { key: 'High', text: 'High', title: 'No problem at all!' },
                ]}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("ConfidenceLevelError" in errorDict) {
                            delete errorDict.ConfidenceLevelError;
                        }
                    });
                }}
                onChange={(_, option) => {
                    updateFormData(formData => {
                        formData.ConfidenceLevel = option ? option.key as string : "";
                    });
                }}
                errorMessage={("ConfidenceLevelError" in errorDict) ? errorDict.ConfidenceLevelError : ""}
            />


            <TextField
                label="Description"
                placeholder="Enter a description for your new efficiency track project"
                multiline rows={4}
                value={formData.Description}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Description = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("DescriptionError" in errorDict) {
                            delete errorDict.DescriptionError;
                        }
                    });
                }}
                errorMessage={("DescriptionError" in errorDict) ? errorDict.DescriptionError : ""}
                disabled={disableEdit}
            />
        </Stack>
    );
}

export default NewProjectInfo;