import * as React from "react";
import { useEffect, useState } from "react";
import Chat, { QuickReplyItemProps, useMessages } from "@chatui/core";
import { io, Socket } from "socket.io-client";
import { getSampleQueries, getToken, parseMessageContent, renderMessageContent } from "./MessageUtils";
import { ChatEndpoint } from "./Constants";

import '@chatui/core/dist/index.css';
import './App.css';
import './chatui-theme.css';
import '@chatui/core/dist/index.css';
import { MessageBar, MessageBarType, Panel, PanelType } from "@fluentui/react";
import styles from "./ChatPanel.less";

const DEFAULT_DB_SUMMARY_MESSAGE = "Show the DB summary.";
const tpromptSource = "JawsV2";

interface IChatPanelProps {
    onDismiss: () => void;
}

export default function ChatPanel(props: IChatPanelProps) {
    const { messages, appendMsg, updateMsg } = useMessages([{
      type: 'hello'
    }]);
  
    const [sampleQueries, setSampleQueries] = useState<Record<string, QuickReplyItemProps[]>>({});
    const [quickReplyVisible, setQuickReplyVisible] = useState(true);
    const socketRef = React.useRef<Socket>();
  
    const handleSend = React.useCallback((type: string, val: string) => {
        if (val.trim().length == 0){
            return
        }
    
        if (type != 'hello') {
            setQuickReplyVisible(false)
        }
    
        const userMessageId = type === 'hello' ? 0 : messages.length;
        const botMessageId = userMessageId + 1;
        appendMsg({
            type: 'text',
            content: { text: val },
            position: 'right',
            _id: userMessageId,
        });
    
        let hasResponse = false;
        const responseCallback = (data: any) => {
            try {
                if (!hasResponse) {
                    hasResponse = true;
                    appendMsg({
                        type: 'rich-text',
                        position: 'left',
                        content: parseMessageContent(data, setSampleQueries, setQuickReplyVisible),
                        _id: botMessageId,
                    });
                } else {
                    updateMsg(botMessageId, {
                        type: 'rich-text',
                        content: parseMessageContent(data, setSampleQueries, setQuickReplyVisible),
                    });
                }
            }
            catch (e) {
                console.error(e);
                console.log(messages);
            }
        }
  
        const errorCallback = (err: string) => {
          console.error(err);
          appendMsg({
            type: 'text',
            content: { text: err },
            position: 'left',
            _id: botMessageId,
          });
        };
    
        const logCallback = (info: string) => {
          console.log(info);
        };
        
        const requestData = {
          message: val,
          tpromptSource: tpromptSource
        }
    
        socketRef.current?.removeAllListeners('response');
        socketRef.current?.on('response', responseCallback);
    
        socketRef.current?.removeAllListeners('error');
        socketRef.current?.on('error', errorCallback);

        socketRef.current?.removeAllListeners('log');
        socketRef.current?.on('log', logCallback);

        socketRef.current?.emit('request', requestData);
    }, [appendMsg, messages, updateMsg]);

    useEffect(() => {
        (async function() {
          const socket = io(ChatEndpoint, {
            extraHeaders: {
              Authorization: `Bearer ${(await getToken())}`,
            }
          });
          socket.on('ping', (data) => {
            socket.emit('pong', data);
          });

          socketRef.current = socket;

          // handleSend('hello', DEFAULT_DB_SUMMARY_MESSAGE);
          const sampleQueries = await getSampleQueries();
          setSampleQueries(sampleQueries);
          setQuickReplyVisible(true);
        })();

        return () => {
            socketRef.current?.disconnect()
        }
    }, []);
  
    const handleQuickReplyClick = (reply: QuickReplyItemProps) => {
      setQuickReplyVisible(false);
      handleSend('text', reply.name);
    }
    return (
      <Panel
        type={PanelType.large}
        isOpen={true}
        onDismiss={props.onDismiss}
        headerText="Jaws Copilot"
        styles={{
          root: styles.panelRoot,
          scrollableContent: styles.scrollableContent,
          content: styles.panelContent
        }}>
          <Chat
            locale='en-US'
            placeholder="Type your message here..."
            messages={messages}
            quickReplies={sampleQueries[tpromptSource] ?? sampleQueries ?? []}
            onQuickReplyClick={handleQuickReplyClick}
            quickRepliesVisible={quickReplyVisible}
            renderMessageContent={renderMessageContent}
            onSend={handleSend}
          />
      </Panel>
    );
}