import { Component, ComponentTypeEnum, CopsResourceTypeEnum, PlanRegionConfigTable, TargetPair, resourceTypeUnitDictionary } from '../../../models/EfficiencyTracker';
import {Icon} from '@fluentui/react';
import { OptionType, getLoadOptions, getLoadOptionsAll } from '../Views/Project/SelectUtils';
import React, {useEffect, useState} from 'react';

import { AsyncPaginate } from 'react-select-async-paginate';
import {EFFormLabel} from './EFFormLabel';
import {EfficiencyTrackerService} from "../../../services/EfficiencyTrackerService";
import { MultiValue } from 'react-select';
import pageStyles from "../Views/Page.less";
import {useComponents} from "../../../hooks/useEfficiencyTrackerProject";
import { formatNumber } from '../../../utils/currency';
import { customAsyncPaginateStyles } from '../Views/Project/ETAddProjectView';

interface IRegionTableProps {
    resourceType: CopsResourceTypeEnum;
    selectedComponents: Component[];
    effectivePeriod: number;
    submit: boolean;
    onData: (data: PlanRegionConfigTable[]) => void;
    onCreateTime: (createTime: Date) => void
}

export function generateTargetPairs(months: number, percentage: number): TargetPair[] {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setHours(0, 0, 0, 0);

    const targetPairs: TargetPair[] = [];
    const averageValue = Number((percentage / months).toFixed(4));

    for (let i = 0; i < months; i++) {
        const nextMonth = new Date(currentDate);
        nextMonth.setMonth(currentDate.getMonth() + 1);

        targetPairs.push({
            Month: nextMonth,
            Value: averageValue,
        });

        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Correct the values for the last month to ensure that the sum equals the given percentage
    const totalValue = targetPairs.reduce((total, pair) => total + pair.Value, 0);
    const lastPair = targetPairs[targetPairs.length - 1];
    lastPair.Value += (percentage) - totalValue;
    lastPair.Value = Number(lastPair.Value.toFixed(4));
    return targetPairs;
}

const AddRegionTable: React.FC<IRegionTableProps> = (props) => {
    const monthOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit' };
    const { resourceType, selectedComponents, effectivePeriod, submit, onData, onCreateTime} = props;
    const [data, setData] = useState<PlanRegionConfigTable[]>([]);
    const [newTarget, setNewTarget] = useState<number>(0);
    const [totalConsumption, setTotalConsumption] = useState<number>(0);
    const [comsumptionUnit, setcomsumptionUnit] = useState<string>(" (RW Osp/Sec)");
    const [regionSelectedOption, setRegionSelectedOption] = useState<Component[]>();

    const addRow = async () => {
        if (!regionSelectedOption) {
            alert('please input vaild Region');
            return;
        }
        if (totalConsumption === 0) {
            alert('This condition doesn\'t require optimization');
            return;
        }
        if (isNaN(newTarget) || newTarget <= 0 || newTarget >= 100) {
            alert('Please input a valid target between 0 and 100');
            return;
        }
        const newRows: PlanRegionConfigTable[] = []; 
        const today = new Date(Date.now());
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getDate() - 7);

        for (const item of regionSelectedOption) {
            const region = item.Value;

            if (data.some(row => row.Region === region)) {
                alert(`Region ${region} already exists. Please use a different Region.`);
                continue;
            }

            const requestBody = selectedComponents.concat({ Type: ComponentTypeEnum.Region, Value: region });

            try {
                const res = await EfficiencyTrackerService.getConsumption(oneWeekAgo, resourceType, requestBody);
                if (res === 0) {
                    alert(`Region ${region} doesn\'t require optimization.`);
                    continue;
                }
                const consumption = parseFloat(res.toFixed(2));

                const newRow: PlanRegionConfigTable = {
                    Region: item.Value,
                    Current: consumption,
                    Targets: generateTargetPairs(effectivePeriod, newTarget / 100),
                    totalTarget: newTarget,
                };

                newRows.push(newRow);
            } catch (error) {
                console.log("Get Consumption error:", error);
                alert(`Add region ${region} failed, please try again.`);
                return;
            }
        }
        const sortedEditData = [...data, ...newRows].sort((a, b) => {
            if (a.Region < b.Region) {
                return -1;
            }
            if (a.Region > b.Region) {
                return 1;
            }
            return 0;
        });
        setData(sortedEditData);
        onCreateTime(new Date(Date.now()));
    };

    const changeMonthlyAttribution = (month: Date, target: number, row: PlanRegionConfigTable) => {
        row.Targets.forEach((pair) => {
            if (pair.Month.toDateString() === month.toDateString()) {
                pair.Value = Number((target * 1.0 / 100).toFixed(4));
            }
        });
        console.log(row);
        data.forEach((item, index) => {
            if (item.Region === row.Region) {
                data[index] = row;
            }
        });
        setData(data);
        onData(data);
    };

    const deleteRow = (index: number, event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const newData = data.filter((_, i) => i !== index);
        setData(newData);
    };

    useEffect(() => {
        onData(data);
    }, [submit, data, onData]);

    useEffect(() => {
        setData([]);
    }, [resourceType, selectedComponents]);

    useEffect(() => {
        if (!regionSelectedOption || regionSelectedOption.length === 0 || selectedComponents.length === 0) {
            setTotalConsumption(0);
            return;
        }
        console.log("regionSelectedOption:", regionSelectedOption);
        const requestBody = selectedComponents.concat(regionSelectedOption);
        const today = new Date(Date.now());
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getDate() - 7);
        EfficiencyTrackerService.getConsumption(oneWeekAgo, resourceType, requestBody).then((res) => {
            setTotalConsumption(parseFloat(res.toFixed(2)));
        })
         .catch((error) => {
             console.log("Get Consumption error:", error);
             setTotalConsumption(0);
         });
        setcomsumptionUnit(resourceTypeUnitDictionary[resourceType]);
    }, [regionSelectedOption, resourceType, selectedComponents]);

    const DistinctAndMapToSelectOptions = React.useCallback(function (items: string[]) {
        const distinctItems = items;
        return distinctItems.map((item) => {
            return { value: item, label: item };
        });
    }, []);

    const regionDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Region));
    
    const handleRegionDropdownChange = (newValue: MultiValue<OptionType>) => {
        if (newValue.find((option) => option.value === "all")) {
            const multivalue: Component[] = regionDropDownList.map(option => ({
                Type: ComponentTypeEnum.Region,
                Value: option.value,
            }))
            setRegionSelectedOption(multivalue);
        } else {
            const multivalue: Component[] = newValue.map(option => ({
                Type: ComponentTypeEnum.Region,
                Value: option.value,
            }))
            setRegionSelectedOption(multivalue);
        }
    };

    return (
        <div className={pageStyles.AddRegionTable}>
            <h2>Region Settings</h2>
            <div className={pageStyles.labelInputContainer}>
                <EFFormLabel
                    label={"Region"}
                    required
                    fieldDescription={"If multiple regions are selected, the current value is the weighted average value for reference only."}
                />
                <AsyncPaginate
                    isClearable
                    isSearchable
                    isMulti
                    name="Region"
                    placeholder="Select Region"
                    loadOptions={getLoadOptionsAll(regionDropDownList)}
                    closeMenuOnSelect={false}
                    onChange={handleRegionDropdownChange}
                    styles={customAsyncPaginateStyles}
                />
            </div>
            <div className={pageStyles.labelInputContainer}>
                <EFFormLabel
                    label={"Current Value"}
                    fieldDescription={"Resource Consumption Value for the Selected Components in the Recent Tuesday!"}
                />
                <input
                    type="text"
                    placeholder="0"
                    value={formatNumber(totalConsumption) + comsumptionUnit}
                    readOnly
                    className={`${pageStyles.NotfullWidthInput}`}
                />
            </div>
            <div className={pageStyles.labelInputContainer}>
                <EFFormLabel
                    label={"Reduction Percentage Target(%)"}
                    required
                    fieldDescription={"Optimization Reduction Percentage Target"}
                />
                <input
                    type="text"
                    placeholder="Target(%)"
                    defaultValue={newTarget}
                    onChange={(e) => setNewTarget(parseFloat(e.target.value))}
                    className={`${pageStyles.NotfullWidthInput}`}
                />
                <button onClick={addRow} >Add</button>
            </div>
            <div className={pageStyles.divBlank}></div>
            <table>
                <thead>
                    <tr>
                        <th>Region</th>
                        <th>Current {comsumptionUnit}</th>
                        <th>Total Percentage Target</th>
                        <th>Monthly Percentage Target</th>
                        <th>Operation</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={row.Region + index}>
                            <td>{row.Region}</td>
                            <td>{formatNumber(row.Current) + comsumptionUnit}</td>
                            <td>{row.totalTarget + "%"}</td>
                            <td>
                                <ul>
                                    {row.Targets.map((target, targetIndex) => (
                                        <li key={targetIndex}>
                                            <>
                                                <span className={pageStyles.DateSpan}>{target.Month.toLocaleDateString('en-US', monthOptions)}</span>
                                                <input className={pageStyles.monthlyInput} defaultValue={Number(target.Value * 100).toFixed(2)} onChange={(e => changeMonthlyAttribution(target.Month, Number(e.target.value), row))} /><span>%</span>
                                            </>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            
                            <td>
                                <a
                                    href="#"
                                    onClick={(e) => deleteRow(index, e)}
                                    style={{ color: "#519DDB", fontSize: "14px" }}
                                >
                                    <Icon iconName="Delete" style={{ color: "#519DDB", fontSize: "14px" }} /> Delete
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
};

export default AddRegionTable;
