export const syncChildrenClassName = "syncChildren";

const currentDate = new Date();
const startYearOfFiscalYear = currentDate.getFullYear() - (currentDate.getMonth() < 6 ? 1 : 0);
export const fiscalMonthsList = [
    new Date(startYearOfFiscalYear, 6, 1).valueOf(),
    new Date(startYearOfFiscalYear, 7, 1).valueOf(),
    new Date(startYearOfFiscalYear, 8, 1).valueOf(),
    new Date(startYearOfFiscalYear, 9, 1).valueOf(),
    new Date(startYearOfFiscalYear, 10, 1).valueOf(),
    new Date(startYearOfFiscalYear, 11, 1).valueOf(),
    new Date(startYearOfFiscalYear + 1, 0, 1).valueOf(),
    new Date(startYearOfFiscalYear + 1, 1, 1).valueOf(),
    new Date(startYearOfFiscalYear + 1, 2, 1).valueOf(),
    new Date(startYearOfFiscalYear + 1, 3, 1).valueOf(),
    new Date(startYearOfFiscalYear + 1, 4, 1).valueOf(),
    new Date(startYearOfFiscalYear + 1, 5, 1).valueOf(),
];

export const enum Endpoints {
    GetDailyMetrics = "getDailyMetrics",
    GetSubstrateCostByService = "getSubstrateCostByService",
    GetSubstrateRingCost = "getSubstrateRingCost",
    GetSubstrateDailyCarbonEmission = "getSubstrateDailyCarbonEmission",
    GetSubstrateMonthONMonth = "getSubstrateMonthONMonth",
    GetSubstrateTopFiveComponents = "getSubstrateTopFiveComponents",
    GetSubstrateTopFiveApp = "getSubstrateTopFiveApp",
    GetScenarioTagCost = "getScenarioTagCost",

    GetTransactionItemRWQCost = "getTransactionItemRWQCost",
    GetTransactionAssistantCost = "getTransactionAssistantCost",
    GetTransactionServiceInstanceCost = "getTransactionServiceInstanceCost",
    GetTransactionRequestsProcessedCost = "getTransactionRequestsProcessedCost",
    GetTransactionDsapiRequestsCountCost = "getTransactionDsapiRequestCountCost",
    GetTransactionCFMSubmittedCountCost = "getTransactionCFMSubmittedCountCost",
    GetSsdItemSizeCost = "getSsdItemSizeCost",
    GetSsdLlcSizeGbCost = "getSsdLlcSizeGbCost",
    GetSsdKvCacheSizeGbCost = "getSsdKvCacheSizeGbCost",

    GetAzureSubscriptions = "getAzureSubscriptions",
    GetAzureClusterIds = "getAzureClusterIds",
    GetServiceTreeBusinessOwners = "getServiceTreeBusinessOwners",


    GetAzureDailyMetrics = "getAzureDailyMetrics",
    GetAzureHierarchicalMetrics = "getHierarchicalMetrics",
    GetAzureDailyCpuUtilization = "getAzureDailyCpuUtilization",
    GetAzureRdosCpuBin = "getAzureRdosCpuBin",
    GetAzureRdosRegionList = "getAzureRdosRegionList",

    GetAzureDetailsByService = 'getAzureDetailsByService',
    GetAzureDetailsByRegion = 'getAzureDetailsByRegion',
    GetAzureDetailsBySubscription = 'getAzureDetailsBySubscription',

    GetAzureTotalBudget = "getAzureTotalBudget",
    GetAzureTopNServicesMonthlyCost = "getAzureTopNServicesMonthlyCost",

    GetCosmosDailyMetrics = 'getCosmosDailyMetrics',
    GetCosmosCostByServices = 'getCosmosCostByServices',
    GetCosmosCostByGEM = 'getCosmosCostByGEM',

    GetAzureBudgetDetails = "getAzureBudgetDetails",

    GetAzureFiscalYears = "getAzureFiscalYears",

    GetAnomalies = "getAnomalies",

    GetAzureNonComputeCosts = "GetAzureNonComputeCosts",

    GetFlights = "getFlights",

    GetPCMV2Unitprice = "getPCMV2Unitprice",

    GetCosmicDailyMetricsByWorkload = "getCosmicDailyMetricsByWorkload",
    GetCosmicDailyCost = "getCosmicDailyCost",

    //PCM Platform
    GetSubstratePlatformDailyReport = "GetSubstratePlatformDailyReport",
    GetSubstratePlatformBackendTotalCost = "GetSubstratePlatformBackendTotalCost",
    GetSubstratePlatformBackendPivotCostTable = "GetSubstratePlatformBackendPivotCostTable",
}

export const enum NotificationCategories {
    DataLoss = "DataLoss",
    PcmModelChange = "ModelChange",
    UnitCostChange = "UnitCostChange"
}