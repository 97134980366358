import { INavLinkGroup, Stack, Nav, INavLink, IRenderGroupHeaderProps, INavStyles, calculatePrecision } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./TrackerNavigation.less";
import { Pages } from "../../models/Nav";
import { getTabCache } from "../../utils/pageUtils";
import { useGotoPage } from "../../hooks/useGotoPage";
import { LogComponent, LogTarget } from "../../models/LogModel";
import { trackEventCallback } from "../../utils/AppInsights";
import { useLocation } from "react-router-dom";
import { useIEfficiencyTrackerProject } from "../../hooks/useEfficiencyTrackerProject";

const projectLinks: INavLinkGroup[] = [
    {
        links: [
            {
                name: "My Project",
                icon: "LineChart",
                url: Pages.EfficiencyTracker,
            },
        ],
    },
];
const AddPageLinks: INavLinkGroup[] = [
    {
        links: [
            {
                name: "Add Project",
                key: `${Pages.EfficiencyTracker}/${Pages.EFAddProjectPage}`,
                url: `${Pages.EfficiencyTracker}/${Pages.EFAddProjectPage}`,
            },
        ],
    },
];

const jawLinkStyles: Partial<INavStyles> = {
    root: {
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflowY: "auto",
    },
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        fontWeight: 600,
        fontSize: "16px",
    },
    link: {
        paddingLeft: 16,
    },
};

const linkStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12,
        marginTop: 12,
    },
    linkText: {
        left: "48px",
        top: calculatePrecision((50 % -"20px") / 2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        paddingLeft: 0,
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const _onRenderGroupHeader = (group?: IRenderGroupHeaderProps): JSX.Element | null => {
    if (!group) return null;
    return <div className={styles.groupHeader}>{group.name}</div>;
};

const getActivePage = (path: string): string => {
    if (path.startsWith(`${Pages.EfficiencyTracker}/${Pages.EFSubPage}`)) {
        return `${Pages.EfficiencyTracker}/${Pages.EFSubPage}`;
    } else if (path.startsWith(`${Pages.EfficiencyTracker}/${Pages.EFAddProjectPage}`)) {
        return `${Pages.EfficiencyTracker}/${Pages.EFAddProjectPage}`;
    }
    if (path.startsWith(Pages.EfficiencyTracker)) {
        return Pages.EfficiencyTracker;
    } else {
        return Pages.EfficiencyTracker;
    }
};

const TrackerNavigation: React.FC = () => {
    const location = useLocation();
    const gotoPage = useGotoPage();
    const { project: project, isLoading, isError } = useIEfficiencyTrackerProject();
    const handleClick = useCallback(
        (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
            if (item?.target !== "_blank") {
                ev?.preventDefault();
                if (item?.url) {
                    const tab = getTabCache(item.tabKey || item.url);
                    gotoPage(item.url + (tab ? `/${tab}` : ""));
                }
            }
            trackEventCallback(LogComponent.PivotNavPane, item?.logElement, item?.title || "", LogTarget.Nav);
        },
        [gotoPage]
    );

    const [subPageLinks, setSubPageLinks] = useState<INavLinkGroup[]>([]);

    useEffect(() => {
        if (project) {
            const newSubPageLinks: INavLinkGroup[] = project.map((item) => ({
                links: [
                    {
                        name: `${item.Name}`,
                        key: `${Pages.EfficiencyTracker}/${Pages.EFSubPage}/${item.Id}`,
                        url: `${Pages.EfficiencyTracker}/${Pages.EFSubPage}/${item.Id}`,
                    },
                ],
            }));
            setSubPageLinks(newSubPageLinks);
        }
    }, [isLoading]);

    return (
        <Stack className={styles.navigation} disableShrink>
            <Nav selectedKey={getActivePage(location.pathname)} styles={jawLinkStyles} groups={projectLinks} onLinkClick={handleClick} />
            <Stack.Item shrink={0}>
                <div className={styles.separator} />
            </Stack.Item>
            <Nav
                selectedKey={getActivePage(location.pathname)}
                styles={linkStyles}
                onRenderGroupHeader={_onRenderGroupHeader}
                groups={subPageLinks}
                onLinkClick={handleClick}
            />
            <Stack.Item shrink={0}>
                <div className={styles.separator} />
            </Stack.Item>
            <Nav
                selectedKey={getActivePage(location.pathname)}
                styles={linkStyles}
                onRenderGroupHeader={_onRenderGroupHeader}
                groups={AddPageLinks}
                onLinkClick={handleClick}
            />
        </Stack>
    );
};

export default TrackerNavigation;
