// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gsuI03dWGMStHaqqdwXQ {\n  position: relative;\n  opacity: 0;\n  transition: opacity 0.3s ease;\n  cursor: pointer;\n  width: 1px;\n  height: 1px;\n}\n.ngd732VSac1UyzFlEey8:hover .gsuI03dWGMStHaqqdwXQ {\n  opacity: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateV2/common/table/SubstrateCostTableFeedbackCell.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,6BAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;AACJ;AAEA;EACI,UAAA;AAAJ","sourcesContent":[".feedbackIcon {\n    position: relative;\n    opacity: 0;\n    transition: opacity 0.3s ease;\n    cursor: pointer;\n    width: 1px;\n    height: 1px;\n}\n\n.cardContainer:hover .feedbackIcon {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedbackIcon": "gsuI03dWGMStHaqqdwXQ",
	"cardContainer": "ngd732VSac1UyzFlEey8"
};
export default ___CSS_LOADER_EXPORT___;
