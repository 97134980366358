import { BannerModel, IBannerMetricData } from "../models/BannerModels";
import { IDateRangeFilterData } from "../models/FilterView";
import { getJson } from "../utils/apiServiceBase";

export async function getBannersByTabAndDate({ queryKey }: { queryKey: [IDateRangeFilterData] }): Promise<IBannerMetricData> {
    const [dateRangeFilter] = queryKey;
    const url = "GetBannersByTabAndDate?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD") + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD");

    return getJson<BannerModel[]>(url);
}
