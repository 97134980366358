import * as React from "react";
import styles from "./Page.less";
import Highcharts from "highcharts";
import { useEsOverviewTimeRange } from "../../../hooks/useEsOverviewTimeRange";
import { useDateRange } from "../../../hooks/useDateSelector";
import PageHeader from "../../common/PageHeader";
import { useFlights } from "../../../hooks/useSettings";

Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

interface IProps {
    children: React.ReactNode;
    title: string;
    description: string;
}

const PageWrapper = ({ children, title, description }: IProps) => {

    return (
        <div className={styles.page}>
            <PageHeader
                title={title}
                description={description}
                className={styles.header}
            />
            {children}
        </div>
    );
};

export default PageWrapper;
