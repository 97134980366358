import styles from './MoreVerticals.less';

import * as React from 'react';
import { ContextualMenu, IContextualMenuItem, DirectionalHint, Icon, Modal, MessageBar, PrimaryButton, Spinner, IconButton, MessageBarType } from '@fluentui/react';
import { RefObject, useCallback, useState } from 'react';

import { useBoolean } from '@fluentui/react-hooks';
import { useLocation } from 'react-router-dom';
import { deleteItem } from '../../../../../utils/apiServiceBase';
import { useGotoPage } from '../../../../../hooks/useGotoPage';
import { Pages } from '../../../../../models/Nav';

interface IMoreVerticals {
    ProjectId: number;
    AllowDelete: boolean;
}

const MoreVerticalsBigBets: React.FC<IMoreVerticals> = ({ ProjectId, AllowDelete }) => {
    const gotoPage = useGotoPage();
    const location = useLocation();
    const [menuVisible, setMenuVisible] = React.useState<boolean>(false);
    const [iconPosition, setIconPosition] = React.useState<{ top: number; left: number } | null>(null);

    //Delete button status
    const [showModal, setShowModal] = useState(false);
    const [showModalRequest, setShowModalRequest] = useState(false);
    const [requestState, setRequestState] = useState<string>('');
    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    const handleModalClose = () => {
        setShowModal(false);
        setRequestState('');
        setShowModalRequest(false);
    };

    const handleDeleteProject = () => {
        submitting();
        deleteItem('api/efficiencytracker/bigbets/delete?projectId=' + ProjectId)
            .then(async (response) => {
                if (response.ok) {
                    setRequestState('Submit successfully!');
                } else {
                    const responseData = await response.text();
                    setRequestState('Submit failed:' + responseData);
                }
            })
            .catch(async (response) => {
                const responseData = await response.text();
                setRequestState('Error when Submitting:' + responseData);
            }).finally(() => {
                submitDone();
                setShowModalRequest(true);
            });
    };

    //Icon Click
    const iconRef: RefObject<HTMLButtonElement> = React.createRef();

    const onIconClick = () => {
        if (iconRef.current) {
            const rect = iconRef.current.getBoundingClientRect();
            const top = rect.top;
            const left = rect.left;
            setIconPosition({ top, left });
            setMenuVisible(!menuVisible);
        }
    };

    const onEditClick = useCallback(() => {
        setMenuVisible(false);
        gotoPage(`${location.pathname}/${Pages.EFEditPage}/${ProjectId}`)
    }, [gotoPage, location.pathname, ProjectId]);

    const onDeleteClick = useCallback(() => {
        setShowModal(true);
        setMenuVisible(false);
    }, []);

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit Efficiency Project',
            onClick: onEditClick,
            iconProps: { iconName: 'Edit' },
            className: styles.MenuName,
        },
        {
            key: 'delete',
            text: 'Delete Efficiency Project',
            onClick: onDeleteClick,
            iconProps: { iconName: 'Delete' },
            className: styles.MenuName,
            disabled: !AllowDelete,
        },
    ];

    return (
        <div>
            <button ref={iconRef} onClick={onIconClick} className={styles.MoreVertical}>
                <Icon iconName="MoreVertical" />
            </button>

            {menuVisible && iconPosition && (
                <ContextualMenu
                    items={menuItems}
                    target={iconRef}
                    onDismiss={() => setMenuVisible(false)}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                />
            )}
            <Modal isOpen={showModal} onDismiss={handleModalClose} containerClassName={styles.modalContainerDelete}>
                    <>
                        <div className={styles.modalHeader}>
                            <span >{"Delete this project"}</span>
                            <IconButton
                                className={styles.iconButton}
                                iconProps={{ iconName: "Cancel" }}
                                ariaLabel="Close"
                                onClick={handleModalClose}
                            />
                        </div>
                        {
                            showModalRequest && !isSubmitting && (
                                <MessageBar
                                    messageBarType={requestState === 'Submit successfully!' ? MessageBarType.success : MessageBarType.error}>
                                    {requestState}
                                </MessageBar>
                            )
                        }
                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 0 }}>
                        <PrimaryButton onClick={handleDeleteProject} style={{ margin: '8px' }} disabled={isSubmitting || requestState !== ''}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={handleModalClose} style={{ margin: '8px' }}>Cancel</PrimaryButton>
                            {isSubmitting && <Spinner label="Deleting project..." labelPosition="right" />}
                        </div>
                </>
            </Modal>
        </div>
    );
};

export default MoreVerticalsBigBets;
