import React from 'react';
import styles from './less/TableComponent.less';

interface Props {
    data: { key: string; value: string }[];
}

const TableComponent: React.FC<Props> = ({ data }) => {
    return (
        <div className={styles.tableContainer} >
            <table className={styles.parameterTable}>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className={styles.parameterKey}>{item.key}</td>
                            <td className={`${styles.parameterValue} ${styles.preformatted}`}>
                                <span style={{ color: getArrowColor(item.value) }}>{item.value}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const getArrowColor = (text: string): string => {
    const firstSymbol = text.charAt(0);

    // Check the first symbol and return the corresponding color
    return firstSymbol === '↑' ? 'green' : firstSymbol === '↓' ? 'red' : 'black';
};

export default TableComponent;
