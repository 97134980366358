import { AzureComputeDataSourceNames, CosmosDataSourceNames, DataSourceEnum, INotification, SubstrateDataSourceNames, SubstrateV2DataSourceNames } from "../models/NotificationModels";

import { Endpoints } from "../utils/Constants";
import { IDateRangeFilterData } from "../models/FilterView";
import { getAnomalies } from "../services/notificationCenterService";
import { useDateRange } from "./useDateSelector";
import { useQuery } from "react-query";

function getDataSourceNames(dataSource: DataSourceEnum) {
    switch (dataSource) {
        case DataSourceEnum.Substrate: return SubstrateDataSourceNames;
        case DataSourceEnum.AzureCompute: return AzureComputeDataSourceNames;
        case DataSourceEnum.Cosmos: return CosmosDataSourceNames;
        case DataSourceEnum.SubstrateV2: return SubstrateV2DataSourceNames;
    }

    return [];
}

export function useGetAnomaliesByDataSourceQuery(dataSource: DataSourceEnum, showAll = false) {
    const query = useGetAnomaliesQuery(showAll);
    const dataSourceNames = new Set(getDataSourceNames(dataSource));
    const data = query.data?.filter(item => dataSourceNames.has(item.dataSourceName));

    return {
        ...query,
        data,
    };
}

export function useGetAnomaliesQuery(showAll = false) {
    const dateRangeFilterData = useDateRange();

    return useQuery<
        INotification[],
        Error,
        INotification[],
        [string, IDateRangeFilterData?]>([Endpoints.GetAnomalies + showAll, showAll ? undefined : dateRangeFilterData], getAnomalies);
}
